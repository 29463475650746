import React from 'react';

import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TooltipButton(props) {
  const {
    id,
    tooltipLabel,
    onClick,
    buttonIcon = 'edit',
    buttonColour = 'primary',
    buttonClass = '',
    buttonSize = 'sm',
    placement = 'top',
  } = props;

  return (
    <Button color={buttonColour} size={buttonSize} id={`btn-${id}`} onClick={() => onClick()} className={buttonClass}>
      <FontAwesomeIcon icon={buttonIcon} />
      <UncontrolledTooltip placement={placement} target={`btn-${id}`}>{tooltipLabel}</UncontrolledTooltip>
    </Button>
  );
}
