import React from 'react';

export default function SingleNoteChild(props) {
  const { note } = props;

  return (
    <div className="media btn-reveal-trigger">
      <div className="media-body position-relative">
        <span className="text-800" dangerouslySetInnerHTML={{ __html: note.content }} />
      </div>
    </div>
  );
}
