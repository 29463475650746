import React, { useState } from 'react';
import {
  Row, Col, Label, Input, FormGroup, Form, Button, FormFeedback,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import userService from '../services/userService';
import Required from '../components/Required';
import useInput from '../hooks/useInput';

export default function PasswordForm(props) {
  const { userId } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { value: currentPassword, bind: bindCurrentPassword } = useInput('');
  const { value: newPassword, bind: bindNewPassword } = useInput('');
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput('');

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitted(true);

    if (newPassword !== confirmPassword) {
      toast.error('Passwords must match. Please try again.');
    } else {
      await userService.changePassword(userId, currentPassword, newPassword);
    }
  };

  return (
    <Form id="resetPasswordForm" onSubmit={onSubmit}>
      <Row>
        <Col lg="12">
          <FormGroup className="mb-0">
            <Label>
              Current Password&nbsp;
              <Required />
            </Label>
            <Input type="password" placeholder="Current Password" {...bindCurrentPassword} invalid={isSubmitted && !currentPassword} />
            <FormFeedback>Current Password is required.</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label>
              New Password&nbsp;
              <Required />
            </Label>
            <Input type="password" placeholder="New Password" {...bindNewPassword} invalid={isSubmitted && !newPassword} />
            <FormFeedback>New Password is required.</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup className="mb-0">
            <Label>
              Confirm Password&nbsp;
              <Required />
            </Label>
            <Input type="password" placeholder="Confirm Password" {...bindConfirmPassword} invalid={isSubmitted && !confirmPassword} />
            <FormFeedback>Confirm Password is required.</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <Button className="rounded-capsule mr-1 mb-1 btn-falcon-primary w-100" type="submit">
        <FontAwesomeIcon icon="save" transform="shrink-3" className="fa-align-left mr-2" />
        Change Password
      </Button>
    </Form>
  );
}
