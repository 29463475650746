import React from 'react';
import { Link } from 'react-router-dom';
import { Media, Container, Row } from 'reactstrap';

export default function ListItemGuardian(props) {
  const { guardian, removeGuardian, canEdit } = props;

  const fullName = guardian.firstName + ' ' + guardian.lastName;
  const email = 'mailto:' + guardian.email;

  const removeClicked = async (e) => {
    e.preventDefault();

    if (canEdit) {
      removeGuardian(guardian.id);
    }
  };

  return (
    <Media className="align-items-center mb-3">
      <div className="avatar avatar-55xl">
        <img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={guardian.photo} alt={fullName} />
      </div>
      <div className="media-body ml-2">
        <h6 className="mb-0">
          <Container>
            <Row><Link to={'/users/edit/' + guardian.id} className="fs-0">{fullName}</Link></Row>
            <Row><a className="text-700" href={email}>{guardian.email}</a></Row>
            {canEdit && <Row><a href="#!" onClick={removeClicked} className="btn btn-outline-danger rounded-capsule btn-sm mt-2 py-0">Remove</a></Row>}
          </Container>
        </h6>
      </div>
    </Media>
  );
}
