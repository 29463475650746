import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Notes`;

async function allNotes() {
  return authenticatedRequest('GET', URL);
}

async function classroomNotes(classroomId) {
  return authenticatedRequest('GET', `${URL}/${classroomId}`);
}

async function findNote(id) {
  return authenticatedRequest('GET', `${URL}/${id}`);
}

async function deleteNote(id) {
  return authenticatedRequest('DELETE', `${URL}/${id}`);
}

async function updateNote(note) {
  return authenticatedRequest('PUT', `${URL}/${note.noteId}`, JSON.stringify(note));
}

async function addNote(note) {
  return authenticatedRequest('POST', `${URL}`, JSON.stringify(note));
}

const noteService = {
  allNotes,
  classroomNotes,
  findNote,
  deleteNote,
  updateNote,
  addNote,
};

export default noteService;
