import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { CheckPermission } from '../../../components/CanUser';
import CardHeaderless from '../../../components/CardHeaderless';
import ExtendedTable from '../../../components/ExtendedTable';
import PageHeader from '../../../components/PageHeader';
import Spinner from '../../../components/Spinner';
import { UserContext } from '../../../context/userContext';
import userService from '../../../services/userService';

export default function UserPage() {
  document.title = 'Users | Today\'s Family Fridge';

  const history = useHistory();
  const userState = useContext(UserContext);
  const [pageTitle, setPageTitle] = useState('Users');

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  function resendActivationEmail(id) {
    userService.resendActivation(id);
  }

  function userIconFormatter(cell, row) {
    return (
      <div className="media d-flex align-items-center">
        <div className="avatar avatar-xl mr-2">
          <img className="rounded-circle" src={row.photo} alt="" />
        </div>
        <div className="media-body">
          <h5 className="mb-0 fs--1">{row.fullName}</h5>
        </div>
      </div>
    );
  }

  function emailColumnFormatter(cell, row) {
    return (
      <a href={'mailto:' + row.email} onClick={(e) => { e.stopPropagation(); }}>{row.email}</a>
    );
  }

  function roleFormatter(cell, row) {
    let { role } = row;
    if (role === 'Caregiver') {
      role = 'Caregiver/Provider';
    } else if (role === 'Guardian') {
      role = 'Parent/Guardian';
    }

    return (
      <span>{role}</span>
    );
  }

  function lastAccessedFormatter(cell, row) {
    const canResend = CheckPermission(userState.user.role, 'users:resendActivation');

    if (cell === 'Account Creation Pending' && canResend) {
      return (
        <div>
          <button type="button" className="btn btn-outline-primary fs--1" onClick={(e) => { e.stopPropagation(); resendActivationEmail(row.id); }}>
            {cell}
            &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon="redo-alt" />
          </button>
        </div>
      );
    }
    return (<Moment format="dddd, MMM DD, YYYY" utc local>{row.lastAccessed}</Moment>);
  }

  const tableColumns = [
    {
      text: 'Name',
      dataField: 'fullName',
      formatter: userIconFormatter,
      sort: true,
    },
    {
      text: 'Email Address',
      dataField: 'email',
      formatter: emailColumnFormatter,
      sort: true,
    },
    {
      text: 'Role',
      dataField: 'role',
      formatter: roleFormatter,
      headerStyle: { width: '200px' },
      sort: true,
    },
    {
      text: 'Last Accessed',
      dataField: 'lastAccessed',
      formatter: lastAccessedFormatter,
      headerStyle: { width: '275px' },
      sort: true,
    },
  ];

  const loadUsers = async (showArchived) => {
    setPageTitle(showArchived ? 'Archived Users' : 'Users');

    userService
      .getUsers(showArchived)
      .then((response) => setUsers(response.model))
      .then(() => setIsLoading(false));
  };

  const tableClickEvent = (e, r) => { history.push(`/users/edit/${r.id}`); };
  const handleArchived = () => {
    loadUsers(!isShowingArchived);
    setIsShowingArchived(!isShowingArchived);
  };

  if (!CheckPermission(userState.user.role, 'users:visit') && typeof userState.user.role !== 'undefined') {
    history.push('/error/404');
  }

  useEffect(() => {
    setIsLoading(true);
    loadUsers(false);
  }, []);

  return (
    <main className="main" id="top">
      <PageHeader
        title={pageTitle}
        userRole={userState.user.role}
        addPermission="users:create"
        newButtonText="a User"
        newButtonUrl="/users/edit"
        isShowingArchived={isShowingArchived}
      />

      {isLoading ? <Spinner />
        : (
          <CardHeaderless>
            <ExtendedTable
              isShowingArchived={isShowingArchived}
              archiveClicked={handleArchived}
              archivePermission="users:view-archive"
              userRole={userState.user.role}
              columns={tableColumns}
              data={users}
              tableClickEvent={tableClickEvent}
              keyField="id"
              defaultSort="name"
            />
          </CardHeaderless>
        )}
    </main>
  );
}
