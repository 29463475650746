import React, { useState, useEffect, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import CardHeaderless from '../../../../../components/CardHeaderless';
import AddPhotoForm from '../../../../../forms/AddPhotoForm';

export default function ClassroomPhotos(props) {
  const {
    canEdit, classroomId, addPhoto, classroomChildren, photos,
  } = props;

  const [uploadedPhotos, setUploadedPhotos] = useState(photos);

  // lightbox state
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((e, { i }) => {
    setCurrentImage(i);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => { setUploadedPhotos(photos); }, [photos]);

  return (
    <div>
      <CardHeaderless className="py-3"><h4 className="mb-0">Photos</h4></CardHeaderless>
      {canEdit && (
        <AddPhotoForm
          classroomId={classroomId}
          addPhoto={addPhoto}
          classroomChildren={classroomChildren}
        />
      )}

      {uploadedPhotos && uploadedPhotos.length > 0
        ? (
          <CardHeaderless>
            <Gallery
              photos={uploadedPhotos.map((p) => (
                {
                  src: p.path,
                  width: p.width / p.height,
                  height: 1,
                }
              ))}
              onClick={openLightbox}
            />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={uploadedPhotos.map((p) => ({
                      src: p.path,
                      caption: p.description,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </CardHeaderless>
        ) : (
          <CardHeaderless>
            <h5 className="text-center text-muted">No photos have been added yet! Please check back soon.</h5>
          </CardHeaderless>
        )}
    </div>
  );
}
