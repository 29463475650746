import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import {
  FormGroup, Button, Form, Collapse, Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Formik } from 'formik';
import * as Yup from 'yup';

import useSelect from '../hooks/useSelect';
import userService from '../services/userService';
import FormModal from '../components/FormModal';
import RequiredField from '../components/RequiredField';
import Required from '../components/Required';

export default function AddGuardianFamilyForm(props) {
  const {
    addGuardians, canCreate, addCreatedGuardian, guardians,
  } = props;

  const { value: selectedGuardians, setValue: setGuardians, bind: bindGuardians } = useSelect();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const options = guardians.map((g) => {
    const name = g.firstName + ' ' + g.lastName;
    return { value: g.id, label: name };
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'First Name must not exceed 100 characters.')
      .required('The First Name field is required.'),

    lastName: Yup.string()
      .max(100, 'Last Name must not exceed 100 characters.')
      .required('The Last Name field is required.'),

    emailAddress: Yup.string()
      .email('Please enter a valid Email Address.')
      .required('The Email Address field is required.'),
  });

  const onFormSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    const obj = {
      username: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      role: 'Guardian',
    };

    await userService
      .addUser(obj)
      .then(async (r) => {
        if (r.hasError) {
          toast.error(r.errorMessage);
        } else {
          addCreatedGuardian(r.model);
          resetForm(initialValues);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ids = selectedGuardians.map((g) => g.value);
    addGuardians(ids);

    // Clear the dropdown.
    setGuardians([]);
  };

  return (
    <div>
      <a
        className="mb-3 d-block d-flex align-items-center collapsed addRow"
        href="#guardian-form"
        data-toggle="collapse"
        aria-expanded="false"
        aria-controls="child-form"
      >
        <span className="circle-dashed"><FontAwesomeIcon icon="plus" /></span>
        <h5 className="ml-3 mb-0 text-primary">Add Parent/Guardian</h5>
      </a>
      <Collapse id="guardian-form">
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <Label className="mb-0">
                Choose Parent/Guardian&nbsp;
                <Required />
              </Label>
              {canCreate && (
                <FormModal
                  className="btn btn-link btn-sm pr-0"
                  buttonLabel="Add New"
                  isSubmitting={isSubmitting}
                  formName="userDetailForm"
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={onFormSubmit}
                  >
                    {(p) => (
                      <Form id="userDetailForm" onSubmit={p.handleSubmit}>
                        <RequiredField formKey="firstName" labelName="First Name" inputPlaceholder="First Name" />
                        <RequiredField formKey="lastName" labelName="Last Name" inputPlaceholder="Last Name" />
                        <RequiredField formKey="emailAddress" labelName="Email Address" inputPlaceholder="Email Address" />
                      </Form>
                    )}
                  </Formik>
                </FormModal>
              )}
            </div>
            <Select options={options} {...bindGuardians} className="w-100" size="sm" closeMenuOnSelect={false} isMulti />
          </FormGroup>
          <Button className="btn-falcon-primary mr-2" size="sm" type="submit">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            Add to Family
          </Button>
        </Form>
        <hr className="border-dashed border-bottom-0" />
      </Collapse>
    </div>
  );
}
