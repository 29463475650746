import React, { useState } from 'react';
import {
  Card, CardHeader, CardBody, Collapse,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardCollapse(props) {
  const {
    title,
    footer,
    subtitle,
    children,
    isShowing = false,
    bodyClass = '',
    showPlusButton = false,
  } = props;

  const [isOpen, setIsOpen] = useState(isShowing);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Card className={'mb-3 ' + (isOpen ? '' : 'collapsable')}>
      <CardHeader className={'pl-3 c-pointer ' + (isOpen ? 'border-bottom' : '')} onClick={toggle}>
        <h5 className="lh-1 mb-0">
          {showPlusButton
            ? (
              <span className="d-flex align-items-center">
                <span className="circle-dashed text-primary"><FontAwesomeIcon icon="plus" /></span>
                <h5 className="ml-3 mb-0 text-primary">{title}</h5>
              </span>
            ) : (
              <span>
                {title}
                <br />
                <small className="fs--1">{subtitle}</small>
              </span>
            )}
        </h5>
        <FontAwesomeIcon icon="chevron-up" />
      </CardHeader>
      <Collapse isOpen={isOpen} className={bodyClass}>
        <CardBody className="bg-light">{children}</CardBody>
        {footer && (
        <div className="card-footer py-0 border-top">
          {footer}
        </div>
        )}
      </Collapse>
    </Card>
  );
}
