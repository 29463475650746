import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Form, Label } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import authenticationService from '../services/authenticationService';
import RequiredField from '../components/RequiredField';
import SpinnerButton from '../components/SpinnerButton';

export default function LoginForm(props) {
  const { setError, onAuthorized } = props;

  const initialValues = { username: '', password: '' };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is Required.'),
    password: Yup.string().required('Password is Required.'),
  });

  const onSubmit = async (values) => {
    await authenticationService
      .authenticate(values.username, values.password)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response.text());
      })
      .then((r) => onAuthorized(r))
      .catch((error) => error)
      .then((r) => setError(r));
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(p) => (
        <Form onSubmit={p.handleSubmit}>
          <Row>
            <RequiredField
              type="email"
              formKey="username"
              inputPlaceholder="Email Address"
              showLabel={false}
            />
          </Row>
          <Row>
            <RequiredField
              type="password"
              formKey="password"
              inputPlaceholder="Password"
              showLabel={false}
            />
          </Row>
          <Row className="justify-content-between">
            <div className="col-auto">
              <div className="custom-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="basic-checkbox" />
                <Label className="custom-control-label" htmlFor="basic-checkbox">Remember Me</Label>
              </div>
            </div>
            <div className="col-auto">
              <Link className="fs--1" to="/forgot-password">Forgot Password?</Link>
            </div>
          </Row>
          <SpinnerButton isLoading={p.isSubmitting || p.isValidating} className="btn btn-primary btn-block mt-3" type="submit">Log in</SpinnerButton>
        </Form>
      )}
    </Formik>
  );
}
