import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import {
  Row, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

import { UserContext } from '../../../context/userContext';

import AddChildForm from '../../../forms/AddChildForm';
import AddCaregiverForm from '../../../forms/AddCaregiverForm';
import ClassroomAttendance from './components/Attendance';
import { CanUser, CheckPermission } from '../../../components/CanUser';
import childService from '../../../services/childService';
import userService from '../../../services/userService';
import classroomService from '../../../services/classroomService';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';
import CardDeactivate from '../../../components/CardDeactivate';
import CardCollapse from '../../../components/CardCollapse';
import ClassroomPhotos from './components/Photos';
import ClassroomMenus from './components/Menus';
import ClassroomEvents from './components/Events';
import ClassroomNotes from './components/Notes';
import ClassroomOverview from './components/Overview';
import SingleCaregiver from '../../../components/SingleCaregiver';
import SingleChild from '../../../components/SingleChild';

export default function EditClassroomPage(props) {
  document.title = 'Edit Classroom | Today\'s Family Fridge';

  const { location, match } = props;

  const history = useHistory();
  const userState = useContext(UserContext);
  const params = queryString.parse(location.search);

  const didMount = useRef(false);

  const classroomId = match.params.id;
  const [classroom, setClassroom] = useState({
    photos: [],
    events: [],
    notes: [],
    menus: [],
    pastMenus: [],
  });

  // All children
  const [children, setChildren] = useState([]);
  const [classroomChildren, setClassroomChildren] = useState([]);

  // All caregivers
  const [caregivers, setCaregivers] = useState([]);
  const [classroomCaregivers, setClassroomCaregivers] = useState([]);

  // Children that aren't already part of this classroom
  const [availableChildren, setAvailableChildren] = useState([]);

  // Caregivers that aren't already part of this classroom
  const [availableCaregivers, setAvailableCaregivers] = useState([]);

  const [activeTab, setActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(true);
  const [wasCreated, setWasCreated] = useState();
  const [isNew, setIsNew] = useState(true);
  const [isSubmitting, setSubmitting] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState('');

  const canViewOverview = CheckPermission(userState.user.role, 'classrooms:view-overview');
  const canEditOverview = CheckPermission(userState.user.role, 'classrooms:edit-overview');
  const canViewNotes = CheckPermission(userState.user.role, 'classrooms:view-notes');
  const canEditNotes = CheckPermission(userState.user.role, 'classrooms:edit-notes');
  const canViewEvents = CheckPermission(userState.user.role, 'classrooms:view-events');
  const canEditEvents = CheckPermission(userState.user.role, 'classrooms:edit-events');
  const canViewMenus = CheckPermission(userState.user.role, 'classrooms:view-menus');
  const canEditMenus = CheckPermission(userState.user.role, 'classrooms:edit-menus');
  const canViewPhotos = CheckPermission(userState.user.role, 'classrooms:view-photos');
  const canEditPhotos = CheckPermission(userState.user.role, 'classrooms:edit-photos');

  const canCreateCaregivers = CheckPermission(userState.user.role, 'users:create-caregiver');
  const canEditCaregivers = CheckPermission(userState.user.role, 'classrooms:edit-caregivers');
  const canEditChildren = CheckPermission(userState.user.role, 'classrooms:edit-children');

  let canView = true;
  if (!CheckPermission(userState.user.role, 'classrooms:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  // If the classroom has been updated, we want to pull in the
  // associated lists of children and caregivers.
  useEffect(() => {
    if (didMount.current && canView) {
      setClassroomChildren(classroom.children || []);
      setClassroomCaregivers(classroom.caregivers || []);

      setIsNew(typeof classroom.classroomId === 'undefined');
      setIsLoading(false);
    }
  }, [classroom]);

  // If the children associated with the classroom are updated
  // we want to update the available children to exclude those that
  // are already added.
  useEffect(() => {
    if (didMount.current && children.length > 0) {
      setAvailableChildren(
        children.filter((c) => !classroomChildren.find((child) => child.childId === c.childId)),
      );
    }
  }, [children, classroomChildren]);

  // Same as Children.
  useEffect(() => {
    if (didMount.current && caregivers.length > 0) {
      setAvailableCaregivers(
        caregivers.filter((c) => !classroomCaregivers.find((cc) => cc.id === c.id)),
      );
    }
  }, [caregivers, classroomCaregivers]);

  const loadClassroom = async () => {
    if (typeof classroomId !== 'undefined' && canView) {
      await classroomService
        .find(classroomId)
        .then((r) => {
          setClassroom(r.model);

          // Allow the rest of the page to load.
          didMount.current = true;
        });
    } else {
      setClassroom({
        photos: [],
        events: [],
        notes: [],
        menus: [],
        pastMenus: [],
      });

      setIsLoading(false);
      didMount.current = true;
    }
  };

  // If the classroomId changes, reload from the database.
  useEffect(() => {
    loadClassroom();

    if (typeof params.l !== 'undefined') {
      setSelectedLocationId(params.l);
    } else {
      setSelectedLocationId('');
    }
  }, [classroomId]);

  const loadChildren = async () => {
    await childService
      .availableChildren(false)
      .then((r) => {
        setChildren(r.model);

        if (typeof classroom.children === 'undefined' || classroom.children.length === 0) {
          setAvailableChildren(r.model);
        }
      });
  };

  const loadCaregivers = async () => {
    await userService
      .getCaregivers()
      .then((r) => {
        setCaregivers(r.model);

        if (typeof classroom.caregivers === 'undefined' || classroom.caregivers.length === 0) {
          setAvailableCaregivers(r.model);
        }
      });
  };

  useEffect(() => {
    if (canView) {
      loadChildren();
      loadCaregivers();

      if (canViewOverview) { setActiveTab('1'); } else if (canViewNotes) { setActiveTab('2'); } else if (canViewEvents) { setActiveTab('3'); } else if (canViewMenus) { setActiveTab('4'); } else if (canViewPhotos) { setActiveTab('5'); }
    }
  }, []);

  // If the classroom is currently archived.
  const handleArchiveClick = async () => {
    if (classroom.status === 0) {
      await classroomService
        .restore(classroomId)
        .then(() => setClassroom({ ...classroom, status: 1 }));
    } else {
      await classroomService
        .archive(classroomId)
        .then(() => history.push('/classrooms'));
    }
  };

  // When the user submits a form, add a child to the classroom
  // without reloading from the database.
  const addChild = async (childIds) => {
    const temp = [...classroomChildren];

    for (let i = 0; i < childIds.length; i += 1) {
      const id = childIds[i];
      temp.push(availableChildren.find((c) => c.childId === id));
    }

    setClassroomChildren(temp);
  };

  // Remove a child from the list of children associated with the classroom.
  // This will not reload the entire classroom from the database.
  const removeChild = async (childId) => {
    const temp = [...classroomChildren];
    temp.splice(temp.findIndex((c) => c.childId === childId), 1);

    setClassroomChildren(temp);
  };

  // Add a caregiver to the list of caregivers and remove the same caregiver
  // from the available list. Will not reload the entire classroom.
  const addCaregiver = async (caregiverIds, createdCaregiver) => {
    const temp = [...classroomCaregivers];

    if (typeof createdCaregiver !== 'undefined') {
      temp.push(createdCaregiver);
    } else {
      for (let i = 0; i < caregiverIds.length; i += 1) {
        const id = caregiverIds[i];
        temp.push(availableCaregivers.find((c) => c.id === id));
      }
    }

    setClassroomCaregivers(temp);
  };

  // Remove a caregiver from a classroom and add it to the available list.
  // Will not reload entire classroom.
  const removeCaregiver = async (caregiverId) => {
    const temp = [...classroomCaregivers];
    temp.splice(temp.findIndex((c) => c.id === caregiverId), 1);

    setClassroomCaregivers(temp);
  };

  // On tab change, force the screen to scroll to the top.
  const toggleTab = (tab) => {
    window.scrollTo(0, 0);
    setActiveTab(tab);
  };

  const addNote = (note) => {
    let isAdd = true;
    const temp = classroom.notes;

    // Check if this is an update.
    for (let i = 0; i < temp.length; i += 1) {
      if (temp[i].noteId === note.noteId) {
        temp[i] = note;

        isAdd = false;
        break;
      }
    }

    if (isAdd) {
      temp.unshift(note);
    }

    setClassroom({ ...classroom, notes: temp });
  };

  const removeNote = (id) => {
    const index = classroom.notes.findIndex(x => x.noteId == id);
    if (index > -1) {
      classroom.notes.splice(index, 1);
      setClassroom({...classroom});
    }
  }

  const updateArray = (items, idKey, dateKey, item) => {
    let isAdd = true;

    for (let i = 0; i < items.length; i += 1) {
      if (items[i][idKey] === item[idKey]) {
        // eslint-disable-next-line no-param-reassign
        items[i] = item;

        isAdd = false;
        break;
      }
    }

    if (isAdd) {
      let insertIndex = 0;

      for (let y = 0; y <= items.length - 1; y += 1) {
        const e = items[y];

        if (e[dateKey] <= item[dateKey]) {
          insertIndex += 1;
        }
      }

      items.splice(insertIndex, 0, item);
    }

    return items;
  };

  const addPhoto = (photo) => {
    setClassroom({
      ...classroom,
      photos: updateArray(classroom.photos, 'photoId', 'createdOn', photo),
    });
  };

  const addEvent = (event) => {
    setClassroom({
      ...classroom,
      events: updateArray(classroom.events, 'eventId', 'startDate', event),
    });
  };

  const removeEvent = (eventId) => {
    const temp = classroom.events;

    for (let i = 0; i < temp.length; i += 1) {
      if (temp[i].eventId === eventId) {
        temp.splice(i, 1);
        break;
      }
    }

    setClassroom({ ...classroom, events: temp });
  };

  const addMenu = (menu) => {
    setClassroom({
      ...classroom,
      menus: updateArray(classroom.menus, 'menuId', 'date', menu),
    });
  };

  const removeMenu = (menuId) => {
    const temp = classroom.menus;

    for (let i = 0; i < temp.length; i += 1) {
      if (temp[i].menuId === menuId) {
        temp.splice(i, 1);
        break;
      }
    }

    setClassroom({ ...classroom, menus: temp });
  };

  return (
    <main className="main" id="top">
      <PageHeaderActions
        title={isNew ? 'Create Classroom' : 'Classroom'}
        subTitle={isNew ? '' : classroom.name}
        isSubmitting={isSubmitting}
        userRole={userState.user.role}
        requiredPermission="classrooms:edit"
        formName="editClassroomForm"
        isNew={isNew}
        createAnotherVisible={wasCreated}
        createAnotherSlug="/classrooms/edit"
      />

      {isLoading
        ? <Spinner />
        : (
          <Row>
            <Col lg="8" className="pr-lg-2 mb-3">
              {!isNew && (
              <Nav tabs pills className="my-2">
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-overview"
                  yes={() => (
                    <NavItem>
                      <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => { toggleTab('1'); }}>Overview</NavLink>
                    </NavItem>
                  )}
                />

                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-attendance"
                  yes={() => (
                    <NavItem>
                      <NavLink className={activeTab === '6' ? 'active' : ''} onClick={() => toggleTab('6')}>Attendance</NavLink>
                    </NavItem>
                  )}
                />

                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-notes"
                  yes={() => (
                    <NavItem>
                      <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => { toggleTab('2'); }}>Notes</NavLink>
                    </NavItem>
                  )}
                />

                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-events"
                  yes={() => (
                    <NavItem>
                      <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => { toggleTab('3'); }}>Events</NavLink>
                    </NavItem>
                  )}
                />

                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-menus"
                  yes={() => (
                    <NavItem>
                      <NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => { toggleTab('4'); }}>Menus</NavLink>
                    </NavItem>
                  )}
                />

                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-photos"
                  yes={() => (
                    <NavItem>
                      <NavLink className={activeTab === '5' ? 'active' : ''} onClick={() => { toggleTab('5'); }}>Photos</NavLink>
                    </NavItem>
                  )}
                />
              </Nav>
              )}
              <TabContent activeTab={activeTab}>
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-overview"
                  yes={() => (
                    <TabPane tabId="1">
                      <ClassroomOverview
                        setWasCreated={(c) => setWasCreated(c)}
                        setSubmitting={(s) => setSubmitting(s)}
                        caregivers={classroomCaregivers}
                        classroomChildren={classroomChildren}
                        selectedLocationId={selectedLocationId}
                        canEdit={canEditOverview}
                        canViewNotes={canViewNotes}
                        canViewEvents={canViewEvents}
                        canViewMenus={canViewMenus}
                        canViewPhotos={canViewPhotos}
                        classroom={classroom}
                        setTab={toggleTab}
                        reload={loadClassroom}
                      />
                    </TabPane>
                  )}
                />

                {!isNew && (
                  <CanUser
                    role={userState.user.role}
                    perform="classrooms:view-attendance"
                    yes={() => (
                      <TabPane tabId="6">
                        <ClassroomAttendance classroomId={classroomId} />
                      </TabPane>
                    )}
                  />
                )}

                {!isNew && (
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-notes"
                  yes={() => (
                    <TabPane tabId="2">
                      <ClassroomNotes
                        canEdit={canEditNotes}
                        classroomId={classroomId}
                        notes={classroom.notes}
                        addNote={addNote}
                        removeNote={removeNote}
                      />
                    </TabPane>
                  )}
                />
                )}

                {!isNew && (
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-events"
                  yes={() => (
                    <TabPane tabId="3">
                      <ClassroomEvents
                        canEdit={canEditEvents}
                        classroomId={classroomId}
                        events={classroom.events}
                        addEvent={addEvent}
                        removeEvent={removeEvent}
                      />
                    </TabPane>
                  )}
                />
                )}

                {!isNew && (
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-menus"
                  yes={() => (
                    <TabPane tabId="4">
                      <ClassroomMenus
                        canEdit={canEditMenus}
                        classroomId={classroomId}
                        menus={classroom.menus}
                        pastMenus={classroom.pastMenus}
                        addMenu={addMenu}
                        removeMenu={removeMenu}
                      />
                    </TabPane>
                  )}
                />
                )}

                {!isNew && (
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:view-photos"
                  yes={() => (
                    <TabPane tabId="5">
                      <ClassroomPhotos
                        canEdit={canEditPhotos}
                        classroomId={classroomId}
                        photos={classroom.photos}
                        reload={loadClassroom}
                        classroomChildren={classroomChildren}
                        addPhoto={addPhoto}
                      />
                    </TabPane>
                  )}
                />
                )}
              </TabContent>
            </Col>
            <Col lg="4" className="pl-lg-2 mb-3">
              <div className="sticky-sidebar sticky-top">
                <CardCollapse title="Caregivers/Providers" isShowing>
                  {canEditCaregivers && (
                  <AddCaregiverForm
                    canCreate={canCreateCaregivers}
                    caregivers={availableCaregivers}
                    action={addCaregiver}
                    addCreatedCaregiver={(c) => { addCaregiver([], c); }}
                  />
                  )}

                  {classroomCaregivers.map((c) => (
                    <SingleCaregiver
                      key={c.id}
                      caregiver={c}
                      canEdit={canEditCaregivers}
                      removeCaregiver={removeCaregiver}
                      classroomId={classroomId}
                    />
                  ))}
                </CardCollapse>

                <CardCollapse title="Children" isShowing>
                  {canEditChildren
                  && (
                  <AddChildForm
                    classroomChildren={availableChildren}
                    addChild={addChild}
                  />
                  )}
                  {classroomChildren.map((c) => (
                    <SingleChild
                      key={c.childId}
                      child={c}
                      canEdit={canEditChildren}
                      removeChild={removeChild}
                    />
                  ))}
                </CardCollapse>

                {!isNew && (
                <CanUser
                  role={userState.user.role}
                  perform="classrooms:archive"
                  yes={() => (
                    <CardDeactivate
                      label="Classroom"
                      isArchived={classroom.status === 0}
                      handleArchiveClick={handleArchiveClick}
                    />
                  )}
                />
                )}
              </div>
            </Col>
          </Row>
        )}
    </main>
  );
}
