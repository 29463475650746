import authHeader from './authHeader';
import handleResponse from './handleApiResponse';

// / Make a request to the API with the added auth header.
export async function authenticatedRequest(requestType, endpoint, body = '') {
  const request = {
    method: requestType,
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };

  if (body.length > 0) {
    request.body = body;
  }

  return handleResponse(await fetch(endpoint, request));
}

// use above.
export async function authenticatedRequestBasic(requestType, endpoint, body = '') {
  const request = {
    method: requestType,
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };

  if (body.length > 0) {
    request.body = body;
  }

  return fetch(endpoint, request);
}
