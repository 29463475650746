import React, { useState, useEffect } from 'react';
import {
  Row, Col, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';

import Moment from 'react-moment';
import DatePicker from 'react-flatpickr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import attendanceService from '../../../../../services/attendanceService';
import CardHeaderless from '../../../../../components/CardHeaderless';
import ExtendedModal from '../../../../../components/ExtendedModal';
import ExportAttendanceForm from '../../../../../forms/ExportAttendanceForm';

export default function ClassroomAttendance(props) {
  const { classroomId } = props;

  const [currentClassroomId] = useState(classroomId);
  const [attendance, setAttendance] = useState([]);
  const [date, setDate] = useState();

  const loadAttendance = async () => {
    if (typeof currentClassroomId !== 'undefined') {
      setDate(new Date().toString());
    }
  };

  const loadAttendanceDate = async () => {
    if (typeof date !== 'undefined') {
      await attendanceService
        .getForClassroomByDateAsync(parseInt(currentClassroomId), new Date(date).getTime() / 1000)
        .then((r) => {
          if (!r.hasError) {
            setAttendance(r.model);
          } else {
            // TODO: Handle
          }
        });
    }
  };

  const clearDate = async () => {
    setDate();
    loadAttendance();
  };

  useEffect(() => { loadAttendance(); }, [classroomId]);
  useEffect(() => { loadAttendanceDate(); }, [date]);

  return (
    <div>
      <CardHeaderless className="py-3">
        <Row className="align-items-center">
          <Col xs="12" sm="6" lg="8">
            <h4 className="mb-0">Attendance</h4>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Row>
              <ExtendedModal
                buttonClassName="btn btn-falcon-primary text-600 btn-sm dropdown-toggle dropdown-caret-none mr-2 shadow-none"
                buttonIcon="file-export"
                buttonLabel="Export Attendance"
                confirmButtonText="Export"
                confirmButtonClass="success"
                formName="exportForm"
                iconOnly
              >
                <ExportAttendanceForm classroomId={parseInt(currentClassroomId)} />
              </ExtendedModal>

              <InputGroup style={{ flex: '1' }}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon="calendar-alt" />
                  </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  className="form-control-sm form-control"
                  placeholder="Select Date"
                  value={date}
                  onChange={(d) => setDate(d)}
                  options={{ maxDate: new Date() }}
                />

                {date && (
                <span
                  role="button"
                  style={{
                    position: 'absolute', right: '10px', top: '8px', cursor: 'pointer',
                  }}
                  onClick={clearDate}
                  onKeyDown={clearDate}
                  tabIndex={0}
                >
                  <FontAwesomeIcon icon="times-circle" />
                </span>
                )}
              </InputGroup>
            </Row>
          </Col>
        </Row>
      </CardHeaderless>
      <Row>
        {attendance.map((a) => (
          <Col xs="6" sm="4" md="4" lg="3" key={a.attendanceId}>
            <CardHeaderless className="text-center">
              <div className="h-100">
                <Link to={'/children/edit/' + a.child.childId}>
                  <img className="rounded mb-3 shadow-sm img-fluid" src={a.child.photo} alt="" height="100" />
                </Link>
                <h5 className="mb-1 text-left">
                  <Link to={'/children/edit/' + a.child.childId}>{a.child.fullName}</Link>
                </h5>
                <Row className="text-left">
                  <Col md="12" lg="6">
                    <b className="fs--2 mb-0 text-700 text-nowrap">Time In</b>
                    <p className="fs--2 mb-1 text-700 text-nowrap">
                      <Moment format="MMMM DD" utc local>{a.checkedInAt}</Moment>
                      <br />
                      <Moment format="hh:mm A" utc local>{a.checkedInAt}</Moment>
                    </p>
                  </Col>
                  <Col md="12" lg="6">
                    <b className="fs--2 mb-0 text-700 text-nowrap">Time Out</b>
                    <p className="fs--2 mb-1 text-700 text-nowrap">
                      {a.checkedOutAt !== null ? (
                        <span>
                          <Moment format="MMMM DD" utc local>{a.checkedOutAt}</Moment>
                          <br />
                          <Moment format="hh:mm A" utc local>{a.checkedOutAt}</Moment>
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
            </CardHeaderless>
          </Col>
        ))}
      </Row>
    </div>
  );
}
