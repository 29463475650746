/* eslint-disable max-len */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';

import { useHistory } from 'react-router-dom';
import {
  Row, Col, FormGroup, Label, CustomInput, Button,
} from 'reactstrap';

import AddSiteAdminForm from '../../../forms/AddSiteAdminForm';

import { UserContext } from '../../../context/userContext';
import { CanUser, CheckPermission } from '../../../components/CanUser';

import locationService from '../../../services/locationService';
import organizationService from '../../../services/organizationService';
import userService from '../../../services/userService';
import uploadService from '../../../services/uploadService';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';
import CardStyle from '../../../components/CardStyle';
import CardForm from '../../../components/CardForm';
import EditLocationForm from '../../../forms/EditLocationForm';
import CardCustom from '../../../components/CardCustom';
import ListItemClassroomLarge from '../../../components/ListItemClassroomLarge';
import ExportAttendanceForm from '../../../forms/ExportAttendanceForm';
import CardCollapse from '../../../components/CardCollapse';
import SingleSiteAdmin from '../../../components/SingleSiteAdmin';
import CardDeactivate from '../../../components/CardDeactivate';
import SpinnerButton from '../../../components/SpinnerButton';

export default function EditLocationPage(props) {
  document.title = 'Edit Location | Today\'s Family Fridge';

  const { match } = props;

  const history = useHistory();
  const userState = useContext(UserContext);
  const didMount = useRef(false);

  const { id } = match.params;

  const [location, setLocation] = useState({});
  const [organizations, setOrganizations] = useState([]);

  // All site admins
  const [siteAdmins, setSiteAdmins] = useState([]);
  const [locationSiteAdmins, setLocationSiteAdmins] = useState([]);

  // Site Admins that aren't already part of this location
  const [availableSiteAdmins, setAvailableSiteAdmins] = useState([]);

  const canExport = CheckPermission(userState.user.role, 'classrooms:export-attendance');
  const canEdit = CheckPermission(userState.user.role, 'locations:edit');
  const canArchive = CheckPermission(userState.user.role, 'locations:archive');
  const canEditSiteAdmins = CheckPermission(userState.user.role, 'locations:edit-siteAdmins');
  const canViewClassrooms = CheckPermission(userState.user.role, 'classrooms:visit');
  const canCreateClassrooms = CheckPermission(userState.user.role, 'classrooms:create');
  const canCreateSiteAdmins = CheckPermission(userState.user.role, 'users:create-siteAdmin');

  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLhcc, setIsLhcc] = useState(false);
  const [wasCreated, setWasCreated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let canView = true;
  if (!CheckPermission(userState.user.role, 'locations:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  useEffect(() => {
    if (didMount.current) {
      if (typeof location.siteAdmins !== 'undefined') {
        setLocationSiteAdmins(location.siteAdmins);
      }

      setIsNew(typeof location.locationId === 'undefined');
      setIsLoading(false);
      setIsLhcc(location.isHomeCentre);
    }
  }, [location]);

  useEffect(() => {
    if (didMount.current) {
      setAvailableSiteAdmins(
        siteAdmins.filter((sa) => !locationSiteAdmins.find((lsa) => lsa.id === sa.id)),
      );
    }
  }, [locationSiteAdmins]);

  const loadLocation = async () => {
    if (typeof id !== 'undefined' && canView) {
      await locationService
        .find(id)
        .then((r) => setLocation(r.model))
        .finally(() => { didMount.current = true; });
    } else {
      setIsLoading(false);
      setLocation({});
      setLocationSiteAdmins([]);

      didMount.current = true;
    }
  };

  const loadOrganizations = async () => {
    await organizationService
      .all(false)
      .then((r) => setOrganizations(r.model));
  };

  const loadSiteAdmins = async () => {
    await userService
      .getSiteAdmins()
      .then((r) => {
        setSiteAdmins(r.model);

        if (typeof location.siteAdmins === 'undefined' || location.siteAdmins.length === 0) {
          setAvailableSiteAdmins(r.model);
        }
      });
  };

  // Initialize the page.
  useEffect(() => {
    if (canView) {
      loadOrganizations();
      loadSiteAdmins();
    }
  }, []);

  // If the id changes, load from the database.
  useEffect(() => { loadLocation(); }, [id]);

  const handleArchiveClick = async (e) => {
    e.preventDefault();

    if (location.status === 0) {
      await locationService
        .restore(id)
        .then(() => setLocation({ ...location, status: 1 }));
    } else {
      await locationService
        .archive(id)
        .then(() => { history.push('/locations'); });
    }
  };

  const changePhoto = async (file, photo) => {
    if (file) {
      await uploadService
        .uploadPhoto(file)
        .then((r) => setLocation({ ...location, photo: r.model.path }));
    } else {
      setLocation({ ...location, photo: photo.src });
    }
  };

  // When the user submits a form, add a new site admin to the location
  // without reloading from the database.
  const addSiteAdmin = (siteAdminIds, createdSiteAdmin) => {
    const temp = [...locationSiteAdmins];

    if (typeof createdSiteAdmin !== 'undefined') {
      temp.push(createdSiteAdmin);
    } else {
      for (let i = 0; i < siteAdminIds.length; i += 1) {
        temp.push(availableSiteAdmins.find((asa) => asa.id === siteAdminIds[i]));
      }
    }

    setLocationSiteAdmins(temp);
  };

  // Remove a site admin from a location and add it to the available list.
  // Will not reload entire location.
  const removeSiteAdmin = (siteAdminId) => {
    const temp = [...locationSiteAdmins];
    temp.splice(temp.findIndex((lsa) => lsa.id === siteAdminId), 1);

    setLocationSiteAdmins(temp);
  };

  const [familyFile, setFamilyFile] = useState();
  const [staffFile, setStaffFile] = useState();

  const [familyFileUploading, setFamilyFileUploading] = useState(false);
  const [staffFileUploading, setStaffFileUploading] = useState(false);

  const [isLocationActivating, setIsLocationActivating] = useState(false);

  const handleFamilyImport = async (e) => {
    e.preventDefault();

    setFamilyFileUploading(true);

    await locationService
      .importChildData(id, familyFile)
      .then((r) => {
        setFamilyFileUploading(false);

        if (!r.hasError) {
          history.push('/locations');
        }
      });
  };

  const handleStaffImport = async (e) => {
    e.preventDefault();

    setStaffFileUploading(true);

    await locationService
      .importStaffData(id, staffFile)
      .then((r) => {
        setStaffFileUploading(false);

        if (!r.hasError) {
          history.push('/locations');
        }
      });
  };

  const fileChanged = (e) => {
    const reader = new FileReader();
    const f = e.target.files[0];

    reader.readAsDataURL(f);
    setFamilyFile(f);
  };

  const staffFileChanged = (e) => {
    const reader = new FileReader();
    const f = e.target.files[0];

    reader.readAsDataURL(f);
    setStaffFile(f);
  };

  const handleActivateClicked = async (e) => {
    e.preventDefault();

    setIsLocationActivating(true);

    await locationService
      .activateLocation(id)
      .then(() => {
        setIsLocationActivating(false);
        history.push('/locations');
      });
  };

  return (
    <main className="main" id="top">
      <PageHeaderActions
        title={isNew ? 'New Location' : 'Location'}
        subTitle={isNew ? '' : location.name}
        isSubmitting={isSubmitting}
        userRole={userState.user.role}
        requiredPermission="locations:edit"
        formName="locationDetailForm"
        isNew={isNew}
        createAnotherVisible={wasCreated}
        createAnotherSlug="/locations/edit"
      />

      {isLoading
        ? <Spinner />
        : (
          <div>
            {!isNew
                  && (
                  <CardStyle
                    handleSubmit={changePhoto}
                    currentPath={location.photo}
                    title={location.name}
                    canEdit={canEdit}
                    subTitle={`${location.address} ${location.city}, ${location.postalCode}`}
                  />
                  )}
            <Row>
              <Col lg="8" className="pr-lg-2 mb-3">
                <CardForm type="Location" canEdit={canEdit}>
                  {organizations.length > 0 && (
                    <EditLocationForm
                      location={location}
                      organizations={organizations}
                      siteAdmins={locationSiteAdmins}
                      canEdit={canEdit}
                      setIsLhcc={(i) => setIsLhcc(i)}
                      setWasCreated={(w) => setWasCreated(w)}
                      setSubmitting={(l) => setIsSubmitting(l)}
                    />
                  )}
                </CardForm>

                {!isNew && location.classrooms && canViewClassrooms && (
                  <CardCustom title="Classrooms" addButtonLink={'/classrooms/edit?l=' + location.locationId} addButtonPermission={canCreateClassrooms && !isLhcc} showExport={canExport} exportForm={<ExportAttendanceForm locationId={location.locationId} />}>
                    <Row>
                      {location.classrooms.length > 0
                        ? location.classrooms.map((c) => <ListItemClassroomLarge classroom={c} key={'licl_' + c.classroomId} />)
                        : <h5 className="text-center text-700 w-100">There are no classrooms associated with this location.</h5>}
                    </Row>
                  </CardCustom>
                )}
              </Col>

              <Col lg="4" className="pl-lg-2 mb-3">
                <div className="sticky-top sticky-sidebar">
                  {location.status === 2 && canArchive && (
                  <div className="card mb-3" style={{ backgroundColor: '#fef0e8' }}>
                    <div className="card-body">
                      <h3 className="mb-0">Location Inactive</h3>
                      <p className="mt-2">
                        This Location is currently inactive. Once all staff and family data has been imported and you are happy with the results, click below to activate.
                        <br />
                        <br />
                        <strong>Warning: Clicking activate will send out welcome emails to all those associated to this Location. This includes Caregivers and Parents/Guardians. Please be sure before continuing.</strong>
                      </p>
                      <Button className="btn btn-falcon-danger rounded-pill btn-block" onClick={handleActivateClicked} isLoading={isLocationActivating}>Activate Location</Button>
                    </div>
                  </div>
                  )}

                  <CardCollapse title={isLhcc ? 'Home Visitors' : 'Supervisors'} isShowing>
                    {canEditSiteAdmins && (
                      <AddSiteAdminForm
                        canCreate={canCreateSiteAdmins}
                        isHomeCentre={isLhcc}
                        addSiteAdmin={addSiteAdmin}
                        addCreatedSiteAdmin={(sa) => { addSiteAdmin([], sa); }}
                        updateSiteAdmins={setSiteAdmins}
                        availableSiteAdmins={availableSiteAdmins}
                      />
                    )}
                    {locationSiteAdmins.map((s) => (
                      <SingleSiteAdmin
                        key={'ssa_' + s.id}
                        siteAdmin={s}
                        canRemove={canEditSiteAdmins}
                        removeSiteAdmin={removeSiteAdmin}
                        locationId={location.locationId}
                      />
                    ))}
                  </CardCollapse>
                  <CanUser
                    role={userState.user.role}
                    perform="locations:import-families"
                    yes={() => (
                      <CardCollapse
                        title="Import Families"
                        subtitle="Select a file to upload families into this organization."
                        showFooter
                        footer={<SpinnerButton className="btn btn-danger rounded-pill btn-sm btn-block my-2 w-100 h-100" onClick={handleFamilyImport} isLoading={familyFileUploading}>Upload</SpinnerButton>}
                      >
                        <FormGroup className="mb-0">
                          <Label>
                            <strong>This process will create locations, classrooms, children and users that do not already exist.</strong>
                            &nbsp;
                            Please be sure that you have input the correct data.
                            <br className="d-block mt-1" />
                            Also, any users that have been created via this process will receive a welcome email immediately and will be able to sign in.
                            <br className="d-block mt-1" />
                            <strong className="text-danger">This process cannot be undone</strong>
                            .
                          </Label>
                          <CustomInput type="file" id="familyInput" name="customFile" accept=".xlsx, .xls, .csv" onChange={fileChanged} />
                        </FormGroup>
                      </CardCollapse>
                    )}
                  />
                  <CanUser
                    role={userState.user.role}
                    perform="locations:import-staff"
                    yes={() => (
                      <CardCollapse
                        title="Import Staff"
                        subtitle="Select a file to upload staff into this organization."
                        showFooter
                        footer={<SpinnerButton className="btn btn-danger rounded-pill btn-sm btn-block my-2 w-100 h-100" onClick={handleStaffImport} isLoading={staffFileUploading}>Upload</SpinnerButton>}
                      >
                        <FormGroup className="mb-0">
                          <Label>
                            <strong>This process will create locations, classrooms and users that do not already exist.</strong>
                            &nbsp;
                            Please be sure that you have input the correct data.
                            <br className="d-block mt-1" />
                            Also, any users that have been created via this process will receive a welcome email immediately and will be able to sign in.
                            <br className="d-block mt-1" />
                            <strong className="text-danger">This process cannot be undone</strong>
                            .
                          </Label>
                          <CustomInput type="file" id="staffInput" name="customFile" accept=".xlsx, .xls, .csv" onChange={staffFileChanged} />
                        </FormGroup>
                      </CardCollapse>
                    )}
                  />
                  {!isNew && canArchive && location.status !== 2 && <CardDeactivate label="Location" isArchived={location.status === 0} handleArchiveClick={handleArchiveClick} />}
                </div>
              </Col>
            </Row>
          </div>
        )}
    </main>
  );
}
