import React from 'react';
import Select from 'react-select';

import {
  FormGroup, Label, Button, Form, Collapse,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSelect from '../hooks/useSelect';
import Required from '../components/Required';

export default function AddChildForm(props) {
  const { classroomChildren, addChild } = props;
  const { value: selectedChildren, setValue: setChildren, bind: bindChildren } = useSelect();

  const options = classroomChildren.map((c) => ({ value: c.childId, label: c.fullName }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedChildren) {
      const ids = selectedChildren.map((c) => c.value);
      addChild(ids);

      // Clear the dropdown.
      setChildren([]);
    }
  };

  return (
    <div>
      <a
        className="mb-3 d-block d-flex align-items-center collapsed addRow"
        href="#child-form"
        data-toggle="collapse"
        aria-expanded="false"
        aria-controls="child-form"
      >
        <span className="circle-dashed"><FontAwesomeIcon icon="plus" /></span>
        <h5 className="ml-3 mb-0 text-primary">Add Children</h5>
      </a>
      <Collapse id="child-form">
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-1">
            <Label>
              Choose Children&nbsp;
              <Required />
            </Label>
            <Select options={options} {...bindChildren} className="w-100" size="sm" closeMenuOnSelect={false} isMulti />
          </FormGroup>
          <Button className="btn-falcon-primary" size="sm" type="submit">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            Add to Classroom
          </Button>
        </Form>
        <hr className="border-dashed border-bottom-0" />
      </Collapse>
    </div>
  );
}
