import { useState } from 'react';

const useContent = (initialValue) => {
  const [value = initialValue, setValue] = useState();

  return {
    value,
    setValue,
    bind: {
      value,
      setValue,
      onChange: (e) => setValue(e),
    },
  };
};

export default useContent;
