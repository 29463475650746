import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Required() {
  return (
    <abbr className="text-danger" title="Required">
      <sup><FontAwesomeIcon icon="asterisk" className="fs--4" /></sup>
    </abbr>
  );
}
