import React from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';

export default function ListItemLocation(props) {
  const { location } = props;
  const mappedAddress = 'https://www.google.com/maps/search/?api=1&query=' + location.fullAddress;

  return (
    <Media className="align-items-center">
      <div className="avatar avatar-3xl">
        <img className="rounded-circle" src={location.photo} alt={location.name} />
      </div>
      <div className="media-body ml-2">
        <h6 className="mb-0">
          <Link to={'/locations/edit/' + location.locationId} className="fs-0">{location.name}</Link>
          <br />
          <a className="text-700" href={mappedAddress} target="_blank" rel="noopener noreferrer">{location.fullAddress}</a>
        </h6>
      </div>
    </Media>
  );
}
