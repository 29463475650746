import { authenticatedRequest } from '../helpers/authenticatedRequest';
import { anonymousRequest, anonymousRESTRequest } from '../helpers/anonymousRequest';

const URL = `${process.env.REACT_APP_API_URL}/authentication`;

async function authenticate(username, password) {
  return anonymousRESTRequest('POST', URL,
    JSON.stringify({
      username,
      password,
    }));
}

async function refreshToken() {
  return anonymousRequest('POST', URL + '/refresh-token')
    .then(
      (user) => {
        sessionStorage.setItem('jwt', JSON.stringify(user.token));
        return user;
      },
    //   (error) => {
    //     console.log(error);
    //   },
    );
}

async function revokeToken() {
  return authenticatedRequest('POST', URL + '/revoke-token',
    JSON.stringify({
      token: JSON.parse(sessionStorage.getItem('jwt')),
    }));
}

async function forgotPassword(username) {
  return anonymousRESTRequest('POST', URL + '/forgot-password',
    JSON.stringify({
      email: username,
    }));
}

const authenticationService = {
  authenticate,
  refreshToken,
  revokeToken,
  forgotPassword,
};

export default authenticationService;
