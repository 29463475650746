import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label } from 'reactstrap';
import AdvancedEditor from '../components/AdvancedEditor';
import CardCollapse from '../components/CardCollapse';
import Required from '../components/Required';
import childService from '../services/childService';

export default function AddNoteChildForm(props) {
  const { childId, addNote } = props;

  const [content, setContent] = useState('');
  const [contentHasError, setContentHasError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setContentHasError(!content);

    if (content) {
      await childService
        .addNote(childId, content)
        .then((r) => {
          if (!r.hasError) {
            setContent('');
            addNote(r.model);
          }
        });
    } else {
      toast.error('Please enter some content for your new note.');
    }
  };

  const addFooter = (
    <div className="py-2 text-right">
      <button className="btn btn-primary btn-sm px-4 px-sm-5" type="submit">Post</button>
    </div>
  );

  return (
    <Form id="addNoteFrom" onSubmit={onSubmit}>
      <CardCollapse title="Add a new Note" footer={addFooter} showPlusButton>
        <FormGroup>
          <Label for="title">
            Content&nbsp;
            <Required />
          </Label>
          <AdvancedEditor value={content} onChange={(v) => setContent(v)} placeholder="Enter the content of your note here..." className={contentHasError ? 'hasError' : ''} />
        </FormGroup>
      </CardCollapse>
    </Form>
  );
}
