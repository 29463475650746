import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Communication`;

async function sendCommunication(communication) {
  return authenticatedRequest('POST', URL, JSON.stringify(communication));
}

async function getCommunications() {
  return authenticatedRequest('GET', URL + '/');
}

async function readCommunication(id) {
  return authenticatedRequest('PUT', URL + '/Read/' + id);
}


const communicationService = { sendCommunication, getCommunications, readCommunication };

export default communicationService;
