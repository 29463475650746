import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckPermission } from '../../../components/CanUser';
import CardHeaderless from '../../../components/CardHeaderless';
import ExtendedTable from '../../../components/ExtendedTable';
import PageHeader from '../../../components/PageHeader';
import Spinner from '../../../components/Spinner';
import { UserContext } from '../../../context/userContext';
import classroomService from '../../../services/classroomService';

export default function ClassroomPage() {
  document.title = 'Classrooms | Today\'s Family Fridge';

  const history = useHistory();
  const userState = useContext(UserContext);

  const [pageTitle, setPageTitle] = useState('Classrooms');

  const [classrooms, setClassrooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  const tableClickEvent = (e, r) => { history.push('/classrooms/edit/' + r.classroomId); };
  const tableColumns = [
    { text: 'Name', dataField: 'name', sort: true },
    {
      text: 'Location',
      dataField: 'locationName',
      headerStyle: { width: '200px' },
      sort: true,
    },
    { text: 'Address', dataField: 'fullAddress', sort: true },
    { text: 'Caregivers/Providers', dataField: 'caregivers', sort: true },
    {
      text: 'Children',
      dataField: 'numberOfChildren',
      headerStyle: { width: '150px' },
      sort: true,
    },
  ];

  let canView = true;
  if (!CheckPermission(userState.user.role, 'classrooms:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  const loadClassrooms = async () => {
    if (canView) {
      setPageTitle(isShowingArchived ? 'Archived Classrooms' : 'Classrooms');

      await classroomService
        .all(isShowingArchived)
        .then((r) => setClassrooms(r.model))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => { loadClassrooms(); }, [isShowingArchived]);

  return (
    <main className="main" id="top">
      <PageHeader
        title={pageTitle}
        userRole={userState.user.role}
        addPermission="classrooms:create"
        newButtonText="a Classroom"
        newButtonUrl="/classrooms/edit"
        isShowingArchived={isShowingArchived}
      />

      {isLoading
        ? <Spinner />
        : (
          <CardHeaderless>
            <ExtendedTable
              isShowingArchived={isShowingArchived}
              archiveClicked={() => setIsShowingArchived(!isShowingArchived)}
              archivePermission="classrooms:view-archive"
              userRole={userState.user.role}
              columns={tableColumns}
              data={classrooms}
              tableClickEvent={tableClickEvent}
              keyField="classroomId"
              defaultSort="name"
            />
          </CardHeaderless>
        )}
    </main>
  );
}
