import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import SpinnerButton from './SpinnerButton';

export default function FormModal(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    children, buttonLabel = 'Add New', formName = '', isSubmitting = false,
  } = props;

  return (
    <>
      <Button onClick={toggle} size="sm" color="link" className="pr-0">{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>{buttonLabel}</ModalHeader>
        <ModalBody className="bg-light">
          {children}
        </ModalBody>
        <ModalFooter>
          <SpinnerButton type="submit" isLoading={isSubmitting} className="btn btn-primary" form={formName}>Create</SpinnerButton>
          <Button className="btn btn-danger" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
