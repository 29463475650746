import React from 'react';
import Select from 'react-select';
import { Label, FormGroup, Col } from 'reactstrap';
import { useFormikContext, useField } from 'formik';
import Required from './Required';

export default function RequiredSelect(props) {
  const {
    options,
    name,
    labelName,
    colSize = '12',
    isRequired = true,
    isDisabled = false,
  } = props;

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <Col lg={colSize}>
      <FormGroup>
        <Label for={name}>
          {labelName}
            &nbsp;
          {isRequired && !isDisabled && <Required />}
        </Label>
        <Select
          className={meta.error && meta.touched ? 'tf-select is-invalid' : 'tf-select'}
          options={options}
          {...field}
          {...props}
          isDisabled={isDisabled}
          onBlur={() => setFieldTouched(name, true)}
          onChange={(s) => setFieldValue(name, s)}
        />

        {meta.touched && meta.error ? (
          <span className="custom-validation-message">{meta.error.value}</span>
        ) : null}
      </FormGroup>
    </Col>
  );
}
