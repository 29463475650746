import React, { useState, useEffect } from 'react';
import {
  Row, Col, Label, FormGroup, Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

import moment from 'moment';
import Moment from 'react-moment';
import menuService from '../services/menuService';
import ExtendedModal from '../components/ExtendedModal';
import CardCustom from '../components/CardCustom';
import Required from '../components/Required';
import AdvancedEditor from '../components/AdvancedEditor';

export default function EditMenuForm(props) {
  const { menu, canEdit, removeMenu } = props;

  const defaultDate = new Date();
  defaultDate.setHours(0, 0, 0, 0);

  const [dateHasError, setDateHasError] = useState(false);
  const [morningSnackHasError, setMorningSnackHasError] = useState(false);
  const [lunchHasError, setLunchHasError] = useState(false);
  const [afternoonSnackHasError, setAfternoonSnackHasError] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [date, setDate] = useState(moment.utc(menu.date).toDate());
  const [morningSnack, setMorningSnack] = useState(menu.morningSnack);
  const [lunch, setLunch] = useState(menu.lunch);
  const [afternoonSnack, setAfternoonSnack] = useState(menu.afternoonSnack);

  useEffect(() => {
    setDate(moment.utc(menu.date).toDate());
    setMorningSnack(menu.morningSnack);
    setLunch(menu.lunch);
    setAfternoonSnack(menu.afternoonSnack);
  }, [isEditing]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (canEdit) {
      setDateHasError(!date);
      setMorningSnackHasError(!morningSnack);
      setLunchHasError(!lunch);
      setAfternoonSnackHasError(!afternoonSnack);

      if (morningSnack && lunch && afternoonSnack) {
        menu.date = moment.utc(date).format();
        menu.morningSnack = morningSnack;
        menu.lunch = lunch;
        menu.afternoonSnack = afternoonSnack;

        await menuService
          .updateMenu(menu)
          .then((r) => {
            if (!r.hasError) {
              setIsEditing(false);
            }
          });
      } else {
        if (!morningSnack) {
          toast.error('Please enter a morning snack for your new menu.');
        }

        if (!lunch) {
          toast.error('Please enter a lunch for your new menu.');
        }

        if (!afternoonSnack) {
          toast.error('Please enter a afternoon snack for your new menu.');
        }
      }
    }
  };

  const onDelete = async (e) => {
    e.preventDefault();

    if (canEdit) {
      await menuService
        .deleteMenu(menu.menuId)
        .then((r) => {
          if (!r.hasError) {
            removeMenu(menu.menuId);
          }
        });
    }
  };

  const header = (
    <div className="media">
      <div className="calendar">
        <span className="calendar-month">
          <Moment format="MMM" utc local>
            {date}
          </Moment>
        </span>
        <span className="calendar-day">
          <Moment format="DD" utc local>
            {date}
          </Moment>
        </span>
      </div>
      <div className="media-body position-relative pl-3">
        <h6 className="fs-0 mb-0">
          <Moment format="MMMM DD, YYYY" utc local>
            {date}
          </Moment>
        </h6>
        <p className="mb-0 fs--1">
          Created by
          {menu.createdBy}
        </p>
      </div>
    </div>
  );

  const footer = (
    <div className="py-2 text-right">
      {isEditing
        ? (
          <div>
            <Button color="secondary" size="sm" className="px-4 px-sm-3 mr-2" onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button color="primary" size="sm" className="px-4 px-sm-3" onClick={onSubmit}>Save</Button>
          </div>
        )
        : (
          <div>
            <ExtendedModal
              buttonLabel="Archive"
              buttonIcon=""
              buttonClass="danger"
              buttonSizeClass="sm"
              confirmButtonText="Confirm"
              confirmButtonClass="danger"
              onConfirm={onDelete}
            >
              <h6 className="m-0 fs-0">Are you sure that you wish to archive this menu?</h6>
            </ExtendedModal>
            <Button color="primary" size="sm" className="px-4 px-sm-3 ml-2" onClick={() => setIsEditing(true)}>Edit</Button>
          </div>
        )}
    </div>
  );

  return (
    <CardCustom header={header} footer={footer} showFooter={canEdit}>
      {!isEditing
        ? (
          <Row>
            <Col lg="4">
              <h6>Morning Snack</h6>
              <span dangerouslySetInnerHTML={{ __html: morningSnack }} />
            </Col>
            <Col lg="4">
              <h6>Lunch</h6>
              <span dangerouslySetInnerHTML={{ __html: lunch }} />
            </Col>
            <Col lg="4">
              <h6>Afternoon Snack</h6>
              <span dangerouslySetInnerHTML={{ __html: afternoonSnack }} />
            </Col>
          </Row>
        )
        : (
          <div>
            <FormGroup>
              <Label for="date">
                Menu Date&nbsp;
                <Required />
              </Label>
              <Flatpickr
                className={'form-control w-100 ' + (dateHasError ? 'hasError' : '')}
                options={{
                  minDate: defaultDate,
                  dateFormat: 'F J, Y',
                }}
                value={date}
                onChange={(d) => setDate(d[0])}
              />
            </FormGroup>
            <FormGroup>
              <Label for="title">
                Morning Snack&nbsp;
                <Required />
              </Label>
              <AdvancedEditor value={morningSnack} onChange={(v) => setMorningSnack(v)} placeholder="Enter what the morning snack will be..." className={morningSnackHasError ? 'hasError' : ''} height={150} />
            </FormGroup>
            <FormGroup>
              <Label for="title">
                Lunch&nbsp;
                <Required />
              </Label>
              <AdvancedEditor value={lunch} onChange={(v) => setLunch(v)} placeholder="Enter what lunch will be..." className={lunchHasError ? 'hasError' : ''} height={150} />
            </FormGroup>
            <FormGroup>
              <Label for="title">
                Afternoon Snack&nbsp;
                <Required />
              </Label>
              <AdvancedEditor value={afternoonSnack} onChange={(v) => setAfternoonSnack(v)} placeholder="Enter what the afternoon snack will be..." className={afternoonSnackHasError ? 'hasError' : ''} height={150} />
            </FormGroup>
          </div>
        )}
    </CardCustom>
  );
}
