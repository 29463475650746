import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Child`;

async function all(showArchived) {
  console.log('ENDPOINT ALL CALLED');
  let endpoint = URL;

  if (showArchived) {
    endpoint += '/Archived';
  }

  return authenticatedRequest('GET', endpoint);
}

async function availableChildren(showArchived) {
  if (showArchived) {
    return authenticatedRequest('GET', URL + '/ArchivedChildren');
  }

  return authenticatedRequest('GET', URL + '/AvailableChildren');
}

async function create(child) {
  return authenticatedRequest('POST', URL, JSON.stringify(child));
}

async function archive(id) {
  return authenticatedRequest('DELETE', URL + '/' + id);
}

async function restore(id) {
  return authenticatedRequest('POST', URL + '/Restore' + id);
}

async function update(child) {
  return authenticatedRequest('PUT', URL + '/' + child.childId, JSON.stringify(child));
}

async function find(id) {
  return authenticatedRequest('GET', URL + '/' + id);
}

async function findForClassroom(id) {
  return authenticatedRequest('GET', URL + '/Classroom/' + id);
}

async function findForFamily(id) {
  return authenticatedRequest('GET', URL + '/Family/' + id);
}

async function findGuardiansForChild(id) {
  return authenticatedRequest('GET', URL + '/Guardians/' + id);
}

async function addNote(id, content) {
  return authenticatedRequest('POST', URL + '/' + id + '/AddNote',
    JSON.stringify({
      childId: id,
      content,
    }));
}

async function updateChildClassroomRelationships(id, classroomIds) {
  return authenticatedRequest('POST', URL + '/ChildClassrooms', JSON.stringify({
    childId: parseInt(id),
    classroomIds,
  }));
}

const childService = {
  availableChildren,
  all,
  create,
  archive,
  restore,
  update,
  find,
  findForClassroom,
  findForFamily,
  findGuardiansForChild,
  addNote,
  updateChildClassroomRelationships,
};

export default childService;
