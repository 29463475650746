import React from 'react';
import { Link } from 'react-router-dom';

import { Media, Row, Container } from 'reactstrap';

export default function SingleCaregiver(props) {
  const { caregiver, removeCaregiver, canEdit } = props;
  const fullName = caregiver.firstName + ' ' + caregiver.lastName;
  const handleClick = async (e) => {
    e.preventDefault();

    if (canEdit) {
      removeCaregiver(caregiver.id);
    }
  };

  return (
    <Media className="align-items-center mb-3">
      <div className="avatar avatar-55xl">
        <img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={caregiver.photo} alt={fullName} />
      </div>
      <div className="media-body ml-2">
        <h6 className="mb-0">
          <Container>
            <Row><Link to={'/users/edit/' + caregiver.id} className="fs-0">{fullName}</Link></Row>
            <Row><span className="text-700">{caregiver.email}</span></Row>
            {canEdit && <Row><a href="#!" onClick={handleClick} className="btn btn-outline-danger rounded-capsule btn-sm mt-2 py-0">Remove</a></Row>}
          </Container>
        </h6>
      </div>
    </Media>
  );
}
