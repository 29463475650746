import authenticatedUpload from '../helpers/authenticatedUpload';

const URL = `${process.env.REACT_APP_API_URL}/Upload`;

async function uploadPhoto(photo) {
  return authenticatedUpload(URL, photo);
}

const uploadService = { uploadPhoto };

export default uploadService;
