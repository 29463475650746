import React, {
  useState, useEffect, useContext, useRef, useCallback
} from 'react';
import Moment from 'react-moment';
import { useHistory, Link } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Row, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

import { UserContext } from '../../../context/userContext';
import { CanUser, CheckPermission } from '../../../components/CanUser';

import childService from '../../../services/childService';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';
import CardForm from '../../../components/CardForm';
import EditChildForm from '../../../forms/EditChildForm';
import CardCustom from '../../../components/CardCustom';
import SingleNoteChild from '../../../components/SingleNoteChild';
import CardHeaderless from '../../../components/CardHeaderless';
import AddNoteChildForm from '../../../forms/AddNoteChildForm';
import ListItemSmall from '../../../components/ListItemSmall';
import CardDeactivate from '../../../components/CardDeactivate';
import ExtendedModal from '../../../components/ExtendedModal';
import photoService from '../../../services/photoService';

export default function EditChildPage(props) {
  document.title = 'Edit Child | Today\'s Family Fridge';

  const { location, match } = props;
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  const history = useHistory();
  const userState = useContext(UserContext);
  const didMount = useRef(false);

  const params = queryString.parse(location.search);

  const childId = match.params.id;
  const [child, setChild] = useState({});

  const [activeTab, setActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wasCreated, setWasCreated] = useState(false);

  const [isNew, setIsNew] = useState(true);

  let canView = true;
  if (!CheckPermission(userState.user.role, 'children:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  const canEdit = CheckPermission(userState.user.role, 'children:edit');
  const canCreate = CheckPermission(userState.user.role, 'children:create');

  const loadChild = async () => {
    if (canView) {
      if (typeof childId !== 'undefined') {
        await childService
          .find(childId)
          .then((r) => {
            setChild(r.model);
            setIsNew(false);
            setIsLoading(false);
          })
          .finally(() => { didMount.current = true; });

        await photoService
          .photosForChild(childId)
          .then((r) => {
            setUploadedPhotos(r.model);
          });
      } else {
        setChild({});
        setIsNew(true);
        setIsLoading(false);

        didMount.current = true;
      }
    }
  };

  useEffect(() => { loadChild(); }, [childId]);

  const handleArchiveClick = async (e) => {
    e.preventDefault();

    if (child.status === 0) {
      await childService
        .restore(child.childId)
        .then(() => setChild({ ...child, status: 1 }));
    } else {
      await childService
        .archive(childId)
        .then(() => { history.push('/children'); });
    }
  };

  const toggleTab = (tab) => {
    window.scrollTo(0, 0);
    setActiveTab(tab);
  };

  const addNote = (n) => {
    const temp = child.childNotes;
    temp.unshift(n);

    setChild({ ...child, childNotes: temp });
  };

  const deleteImage = async (image) => {
    const images = uploadedPhotos.filter(i => i.photoId !== image.photoId)
    
    await photoService
          .deletePhoto(image.photoId)
          .then((r) => {
            setUploadedPhotos(images)
          });
  }

  const noteHeader = (n) => (
    <div>
      <h5 className="lh-1 mb-0"><Moment format="MMMM DD, YYYY hh:mm A" utc local>{n.createdOn}</Moment></h5>
      <p className="mb-0 fs--1">
        Posted by
        {n.createdBy}
      </p>
    </div>
  );

  return (
    <main className="main" id="top">
      <PageHeaderActions
        title={isNew ? 'Create Child' : 'Child'}
        subTitle={isNew ? '' : child.firstName + ' ' + child.lastName}
        isSubmitting={isSubmitting}
        userRole={userState.user.role}
        requiredPermission="children:edit"
        formName="editChildForm"
        isNew={isNew}
        createAnotherVisible={wasCreated}
        createAnotherSlug="/children/edit"
      />

      {isLoading
        ? <Spinner />
        : (
          <Row>
            <Col lg={isNew ? '12' : '8'} className="pr-lg-2 mb-3">
              {!isNew && (
              <Nav tabs pills className="my-2">
                <NavItem>
                  <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => { toggleTab('1'); }}>Overview</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => { toggleTab('2'); }}>Notes</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => { toggleTab('3'); }}>Photos</NavLink>
                </NavItem>
              </Nav>
              )}

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CardForm type="Child" canEdit={canEdit} cardClassName="my-3">
                    <EditChildForm
                      canEdit={canEdit}
                      canCreate={canCreate}
                      family={params.f}
                      child={child}
                      isNew={isNew}
                      setWasCreated={(s) => setWasCreated(s)}
                      setSubmitting={(l) => setIsSubmitting(l)}
                    />
                  </CardForm>

                  {!isNew && (
                  <CardCustom title="Latest Note" footer={(<span className="btn btn-link btn-block" onClick={() => { toggleTab('2'); }} role="button" onKeyDown={() => { toggleTab('2'); }} tabIndex={0}>All Notes</span>)}>
                    {child.childNotes && child.childNotes.length > 0
                      ? (
                        <div>
                          {noteHeader(child.childNotes[0])}
                          <div className="mt-2">
                            <SingleNoteChild note={child.childNotes[0]} />
                          </div>
                        </div>
                      )
                      : <h5 className="text-center text-muted my-2">There haven&#39;t been any notes added.</h5>}
                  </CardCustom>
                  )}
                </TabPane>

                {!isNew && (
                <TabPane tabId="2">
                  <CardHeaderless className="py-3"><h4 className="mb-0">Notes</h4></CardHeaderless>
                  <AddNoteChildForm childId={child.childId} addNote={addNote} />

                  {(child.childNotes !== null && child.childNotes.length > 0)
                    ? child.childNotes.map((n) => <CardCustom key={'cc_cn_' + n.noteId} header={noteHeader(n)}><SingleNoteChild note={n} /></CardCustom>)
                    : (
                      <CardHeaderless>
                        <h5 className="text-center text-muted">No notes have been added yet!</h5>
                      </CardHeaderless>
                    )}
                </TabPane>
                )}
                

                {!isNew && (
                <TabPane tabId="3">
                  <CardHeaderless className="py-3"><h4 className="mb-0">Photos</h4></CardHeaderless>
                  {uploadedPhotos && uploadedPhotos.length > 0
                    ? (
                      <CardHeaderless>
                        <Row>
                        {uploadedPhotos.map((p) => (
                          <Col xs="auto">
                            <div style={{position: 'relative'}}>
                              <img className='img-fluid py-3' src={p.path} style={{maxHeight: '200px'}}></img>
                              <span style={{position: 'absolute', top: '20px', left: '5px'}}>
                                  <ExtendedModal
                                  buttonLabel=""
                                  buttonIcon="trash"
                                  buttonClass="danger"
                                  buttonSizeClass="sm"
                                  confirmButtonText="Confirm"
                                  confirmButtonClass="danger"
                                  onConfirm={() => deleteImage(p)}
                                >
                                  <h6 className="m-0 fs-0">Are you sure that you wish to delete this photo?</h6>
                                  <img className='img-fluid py-3' src={p.path} style={{maxHeight: '200px'}}></img>
                                </ExtendedModal>
                                </span>
                            </div>
                          </Col>
                          ))}
                        </Row>
                      </CardHeaderless>
                    ) : (
                      <CardHeaderless>
                        <h5 className="text-center text-muted">No photos have been added yet! Please check back soon.</h5>
                      </CardHeaderless>
                    )}
                </TabPane>
                )}
              </TabContent>
            </Col>

            {!isNew && (
            <Col lg="4" className="pl-lg-2 mb-3">
              <div className="sticky-top sticky-sidebar">
                <CardCustom title="Families">
                  {child.families && child.families.map((f) => (
                    <ListItemSmall
                      key={'family_' + f.familyId}
                      linkTo={'/families/edit/' + f.familyId}
                      title={f.name}
                    >
                      {
                          f.guardians
                            .map((g) => <Link key={g.id} to={'/users/edit/' + g.id} className="text-700">{g.fullName}</Link>)
                            .reduce((prev, curr) => [prev, ', ', curr], '')
                    }
                    </ListItemSmall>
                  ))}
                </CardCustom>
                <CardCustom title="Classrooms">
                  {child.classrooms && child.classrooms.map((c) => (
                    <ListItemSmall
                      linkTo={'/classrooms/edit/' + c.classroomId}
                      imgSrc={c.photo}
                      title={c.name}
                    >
                      <Link to="/locations/edit/" className="text-700">{c.locationName}</Link>
                    </ListItemSmall>
                  ))}
                </CardCustom>
                <CanUser
                  role={userState.user.role}
                  perform="children:archive"
                  yes={() => (
                    <CardDeactivate
                      label="Child"
                      isArchived={child.status === 0}
                      handleArchiveClick={handleArchiveClick}
                    />
                  )}
                />
              </div>
            </Col>
            )}
          </Row>
        )}
    </main>
  );
}
