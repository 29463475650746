import React, { useState } from 'react';
import {
  Card, CardBody, FormGroup, Label, CustomInput, ButtonGroup,
} from 'reactstrap';

import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css';
import ExtendedModal from './ExtendedModal';

const imageList = [
  '/Uploads/presets/preset-1.jpg',
  '/Uploads/presets/preset-2.jpg',
  '/Uploads/presets/preset-3.jpg',
  '/Uploads/presets/preset-4.jpg',
  '/Uploads/presets/preset-5.jpg',
  '/Uploads/presets/preset-6.jpg',
  '/Uploads/presets/preset-7.jpg',
  '/Uploads/presets/preset-8.jpg',
  '/Uploads/presets/preset-9.jpg',
  '/Uploads/presets/preset-10.jpg',
  '/Uploads/presets/preset-11.jpg',
  '/Uploads/presets/preset-12.jpg',
  '/Uploads/presets/preset-13.jpg',
  '/Uploads/presets/preset-14.jpg',
  '/Uploads/presets/preset-15.jpg',
  '/Uploads/presets/preset-16.jpg',
  '/Uploads/presets/preset-17.jpg',
  '/Uploads/presets/preset-18.jpg',
  '/Uploads/presets/preset-19.jpg',
  '/Uploads/presets/preset-20.jpg',
  '/Uploads/presets/preset-21.jpg',
  '/Uploads/presets/preset-22.jpg',
  '/Uploads/presets/preset-23.jpg',
  '/Uploads/presets/preset-24.jpg',
  '/Uploads/presets/families.png'];

export default function CardStyle(props) {
  const { currentPath, handleSubmit, canEdit } = props;

  const [file, setFile] = useState();
  const [previewImage, setPreviewImage] = useState();

  const [image, setImage] = useState();

  const handleUpload = () => {
    handleSubmit(file, image);

    setFile(null);
    setImage(null);
  };

  const fileChanged = (e) => {
    const reader = new FileReader();
    const f = e.target.files[0];

    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };

    reader.readAsDataURL(f);

    setFile(f);
  };

  return (
    <Card className="mb-3">
      <CardBody
        className="banner-image"
        style={{
          backgroundImage: `url("${currentPath}")`,
          height: '300px',
          backgroundPosition: 'center',
          backgroundRepeat: 'round',
          backgroundSize: 'contain',
        }}
      >
        {canEdit && (
        <div className="banner-image-modals">
          <ButtonGroup>
            <ExtendedModal
              buttonLabel="Upload"
              buttonIcon="file-upload"
              confirmButtonText="Upload"
              modalSize="md"
              buttonSizeClass="sm"
              onConfirm={handleUpload}
            >
              <FormGroup>
                <Label for="photoUpload">Custom Image</Label>
                <CustomInput
                  type="file"
                  id="photoUpload"
                  name="photoFile"
                  onChange={fileChanged}
                />
              </FormGroup>
              {previewImage && <img src={previewImage} className="w-100" alt="preview" />}
            </ExtendedModal>

            <ExtendedModal
              buttonLabel="Pick a Preset"
              buttonIcon="image"
              buttonClass="info"
              modalSize="lg"
              buttonSizeClass="sm"
              onConfirm={handleUpload}
            >
              <FormGroup>
                <Label for="fileBrowser">Preset</Label>
                <ImagePicker
                  images={imageList.map((img, i) => ({ src: img, value: i }))}
                  onPick={(img) => { setImage(img); }}
                />
              </FormGroup>
            </ExtendedModal>
          </ButtonGroup>
        </div>
        )}
      </CardBody>
    </Card>
  );
}
