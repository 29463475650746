import React, { useState } from 'react';
import CardHeaderless from '../../../../../components/CardHeaderless';
import AddMenuForm from '../../../../../forms/AddMenuForm';
import EditMenuForm from '../../../../../forms/EditMenuForm';

export default function ClassroomMenus(props) {
  const {
    canEdit,
    classroomId,
    addMenu,
    removeMenu,
    menus,
    pastMenus,
  } = props;

  const [classroomCurrentMenus] = useState(menus);
  const [classroomPastMenus] = useState(pastMenus);

  return (
    <div>
      <CardHeaderless className="py-3"><h4 className="mb-0">Menus</h4></CardHeaderless>
      {canEdit && (
        <AddMenuForm
          classroomId={classroomId}
          addMenu={addMenu}
          pastMenus={classroomPastMenus}
        />
      )}

      {classroomCurrentMenus.length > 0
        ? classroomCurrentMenus.map((m) => (
          <EditMenuForm
            key={m.menuId}
            menu={m}
            canEdit={canEdit}
            removeMenu={removeMenu}
          />
        ))
        : (
          <CardHeaderless>
            <h5 className="text-center text-muted">No menus have been added yet! Please check back soon.</h5>
          </CardHeaderless>
        )}
    </div>
  );
}
