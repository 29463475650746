import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';
import classroomService from '../services/classroomService';
import RequiredField from '../components/RequiredField';
import RequiredSelect from '../components/RequiredSelect';

export default function EditClassroomForm(props) {
  const history = useHistory();

  const {
    classroom,
    locations,
    canEdit,
    classroomChildren,
    caregivers,
    setSubmitting,
    setWasCreated,
    selectedLocationId = '',
  } = props;

  const locationOptions = [];
  for (let i = 0; i < locations.length; i += 1) {
    locationOptions.push({
      value: locations[i].locationId,
      label: locations[i].name,
    });
  }

  let initialValues = {
    name: typeof classroom.classroomId !== 'undefined' ? classroom.name : '',
    location: locationOptions.find((l) => l.value === classroom.locationId),
  };

  if (selectedLocationId !== '') {
    initialValues = {
      name: '',
      location: locationOptions.find((l) => l.value === parseInt(selectedLocationId)),
    };
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, 'Name must not exceed 100 characters.')
      .required('The Name field is Required.'),

    location: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required('The Location field is required.'),
    }),
  });

  const onSubmit = async (values) => {
    setSubmitting(true);

    const obj = {
      name: values.name,
      locationId: values.location.value,
      photo: classroom.photo,
    };

    if (typeof classroom.classroomId === 'undefined') {
      await classroomService
        .create(obj)
        .then(async (r) => {
          const userIds = caregivers.map((c) => c.id);
          await classroomService
            .updateCaregiverClassroomRelationships(r.model.classroomId, userIds);

          const childIds = classroomChildren.map((c) => c.childId);
          await classroomService
            .updateClassroomChildrenRelationships(r.model.classroomId, childIds);

          history.replace('/classrooms/edit/' + r.model.classroomId);
          setWasCreated(true);
        })
        .finally(() => setSubmitting(false));
    } else {
      obj.classroomId = classroom.classroomId;

      await classroomService
        .update(obj)
        .then(async (r) => {
          const userIds = caregivers.map((c) => c.id);
          await classroomService
            .updateCaregiverClassroomRelationships(classroom.classroomId, userIds);

          const childIds = classroomChildren.map((c) => c.childId);
          await classroomService
            .updateClassroomChildrenRelationships(classroom.classroomId, childIds);

          if (!r.hasError) {
            history.push('/classrooms');
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(p) => (
        <Form id="editClassroomForm" onSubmit={p.handleSubmit}>
          <Row>
            <RequiredField colSize="6" labelName="Name" formKey="name" inputPlaceholder="Classroom Name" isDisabled={!canEdit} />
            <RequiredSelect colSize="6" labelName="Location" name="location" options={locationOptions} isDisabled={!canEdit} />
          </Row>
        </Form>
      )}
    </Formik>
  );
}
