import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';
import locationService from '../services/locationService';
import RequiredField from '../components/RequiredField';
import RequiredSelect from '../components/RequiredSelect';

export default function EditLocationForm(props) {
  const {
    location, organizations, siteAdmins, canEdit, setSubmitting, setWasCreated, setIsLhcc,
  } = props;

  const history = useHistory();
  const [initialValues, setInitialValues] = useState({ });

  const organizationOptions = [];
  for (let i = 0; i < organizations.length; i += 1) {
    organizationOptions.push({
      value: organizations[i].organizationId,
      label: organizations[i].name,
    });
  }

  const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  const telephoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const provinceOptions = [
    { value: 0, label: 'Ontario' },
    { value: 1, label: 'Québec' },
    { value: 2, label: 'Nova Scotia' },
    { value: 3, label: 'New Brunswick' },
    { value: 4, label: 'Manitoba' },
    { value: 5, label: 'British Columbia' },
    { value: 6, label: 'Prince Edward Island' },
    { value: 7, label: 'Saskatchewan' },
    { value: 8, label: 'Alberta' },
    { value: 9, label: 'Newfoundland & Labrador' },
    { value: 10, label: 'Northwest Territories' },
    { value: 11, label: 'Yukon' },
    { value: 12, label: 'Nunavut' },
  ];

  const typeOptions = [
    { value: 0, label: 'Licensed Home Child Care Premise' },
    { value: 1, label: 'Center Based' },
  ];

  const validationSchema = Yup.lazy((values) => {
    const obj = {
      name: Yup.string()
        .max(100, 'Name must not exceed 100 characters.')
        .required('The Name field is required.'),

      organization: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required('The Organization field is required.'),
      }),

      premiseType: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required('Please select a premise type.'),
      }),

      address: Yup.string()
        .max(300, 'Address must not exceed 300 characters.')
        .required('The Address field is required.'),

      city: Yup.string()
        .max(100, 'City must not exceed 100 characters.')
        .required('The City field is required.'),

      postalCode: Yup.string()
        .matches(postalCodeRegex, 'Please enter a valid postal code. (Ex. A1A 1A1)')
        .required('The Postal Code field is required.'),

      province: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required('The Province field is required.'),
      }),
    };

    // Fields are only reuqired for Center based locations
    if (typeof values.premiseType !== 'undefined' && values.premiseType.value === 1) {
      obj.supervisorName = Yup.string()
        .max(200, 'Supervisor\'s Name must not exceed 200 characters.')
        .required('The Supervisor\'s Name field is required');

      obj.emailAddress = Yup.string()
        .email('Please enter a valid Email Address.')
        .required('The Email Address field is required.');

      obj.phoneNumber = Yup.string()
        .matches(telephoneRegex, 'Please enter a valid Phone Number.')
        .required('The Phone Number field is required.');
    }

    return Yup.object().shape(obj);
  });

  const onSubmit = async (values) => {
    setSubmitting(true);

    const obj = {
      name: values.name,
      address: values.address,
      city: values.city,
      postalCode: values.postalCode,
      supervisorName: values.supervisorName,
      email: values.emailAddress,
      phoneNumber: values.phoneNumber,
      phoneNumberExtension: values.phoneNumberExtension,
      organizationId: values.organization.value,
      province: values.province.value,
      photo: location.photo,
      isHomeCentre: values.premiseType.value === 0,
    };

    if (typeof location.locationId === 'undefined') {
      await locationService
        .create(obj)
        .then(async (r) => {
          history.replace('/locations/edit/' + r.model.locationId);
          setWasCreated(true);

          const userIds = siteAdmins.map((sa) => sa.id);
          await locationService.updateLocationSiteAdminRelationships(r.model.locationId, userIds);
        })
        .finally(() => setSubmitting(false));
    } else {
      obj.locationId = location.locationId;

      await locationService
        .update(obj)
        .then(async (r) => {
          const userIds = siteAdmins.map((sa) => sa.id);
          await locationService.updateLocationSiteAdminRelationships(location.locationId, userIds);

          if (!r.hasError) {
            history.push('/locations');
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  useEffect(() => {
    let initialPremise = null;
    let initialOrganization = null;
    let initialProvince = null;

    if (typeof location.locationId !== 'undefined') {
      initialPremise = location.isHomeCentre ? typeOptions[0] : typeOptions[1];

      const initialOrgs = organizationOptions.filter((o) => o.value === location.organizationId);
      if (initialOrgs.length > 0) {
        initialOrganization = initialOrgs[0];
      }

      const initialProvs = provinceOptions.filter((p) => p.value === location.province);
      if (initialProvs.length > 0) {
        initialProvince = initialProvs[0];
      }
    }

    setInitialValues({
      name: location.name || '',
      organization: initialOrganization,
      premiseType: initialPremise,
      address: location.address || '',
      city: location.city || '',
      postalCode: location.postalCode || '',
      province: initialProvince,
      supervisorName: location.supervisorName || '',
      emailAddress: location.email || '',
      phoneNumber: location.phoneNumber || '',
      phoneNumberExtension: location.phoneNumberExtension || '',
    });
  }, [location]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(p) => {
        setIsLhcc(typeof props.values !== 'undefined' && props.values.premiseType.value === 0);

        return (
          <Form id="locationDetailForm" onSubmit={p.handleSubmit}>
            <Row>
              <RequiredField colSize="4" formKey="name" labelName="Name" inputPlaceholder="Location Name" isDisabled={!canEdit} />
              <RequiredSelect colSize="4" labelName="Organization" name="organization" options={organizationOptions} isDisabled={!canEdit} />
              <RequiredSelect colSize="4" labelName="Premise Type" name="premiseType" options={typeOptions} isDisabled={!canEdit || typeof location.locationId !== 'undefined'} />
            </Row>
            <Row>
              <RequiredField formKey="address" labelName="Address" inputPlaceholder="Address" isDisabled={!canEdit} />
            </Row>
            <Row>
              <RequiredField colSize="4" formKey="city" labelName="City" inputPlaceholder="City" isDisabled={!canEdit} />
              <RequiredField
                isDisabled={!canEdit}
                colSize="4"
                formKey="postalCode"
                labelName="Postal Code"
                inputPlaceholder="Postal Code (A1A1A1)"
                inputMask="a9a9a9"
              />
              <RequiredSelect colSize="4" labelName="Province" name="province" options={provinceOptions} isDisabled={!canEdit} />
            </Row>
            {p.values.premiseType && p.values.premiseType.value === 1 && (
              <>
                <Row>
                  <RequiredField
                    formKey="supervisorName"
                    labelName={p.values.isHomeCentre ? "Home Visitor's Name" : "Supervisor's Name"}
                    inputPlaceholder={p.values.isHomeCentre ? "Home Visitor's Name" : "Supervisor's Name"}
                    isDisabled={!canEdit}
                  />
                </Row>
                <Row>
                  <RequiredField colSize="6" type="email" formKey="emailAddress" labelName="Email Address" inputPlaceholder="Email Address" isDisabled={!canEdit} />
                  <RequiredField colSize="4" type="tel" formKey="phoneNumber" labelName="Phone Number" inputPlaceholder="Phone Number" inputMask="(999) 999 9999" isDisabled={!canEdit} />
                  <RequiredField colSize="2" formKey="phoneNumberExtension" labelName="Ext." inputPlaceholder="Ext." isDisabled={!canEdit} isRequired={false} />
                </Row>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
