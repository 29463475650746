import React, { useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import useSelect from '../hooks/useSelect';
import childService from '../services/childService';
import familyService from '../services/familyService';
import Required from '../components/Required';
import ListItemGuardian from '../components/ListItemGuardian';
import classroomService from '../services/classroomService';
import userService from '../services/userService';

export default function ClassroomToFamilyForm(props) {
  const {
    setSelectedClassroomId, setSelectedChildId, setSelectedFamilyId,
    classroomError, familiesError, childError,
  } = props;

  const [classrooms, setClassrooms] = useState([]);
  const [children, setChildren] = useState([]);
  const [families, setFamilies] = useState([]);

  const [usersToSendTo, setUsersToSendTo] = useState([]);

  const {
    value: selectedClassroom,
    bind: bindSelectedClassroom,
    setValue: setSelectedClassroom,
  } = useSelect();

  const {
    value: selectedChild,
    bind: bindSelectedChild,
    setValue: setSelectedChild,
  } = useSelect();

  const {
    value: selectedFamily,
    bind: bindSelectedFamily,
    setValue: setSelectedFamily,
  } = useSelect();

  useEffect(() => {
    classroomService
      .all()
      .then((r) => {
        if (!r.hasError) {
          const mappedClassrooms = r.model.map((c) => ({ value: c.classroomId, label: c.name }));
          setClassrooms(mappedClassrooms);

          if (r.model.length === 1) {
            setSelectedClassroom(mappedClassrooms[0]);
          }
        }
      });
  }, []);

  useEffect(() => {
    if (selectedClassroom) {
      setSelectedClassroomId(selectedClassroom.value);

      setSelectedChild(undefined);
      setSelectedFamily(undefined);
      setUsersToSendTo(undefined);

      childService
        .findForClassroom(selectedClassroom.value).then((r) => {
          const mappedChildren = r.model.map((c) => ({
            value: c.childId,
            label: c.fullName,
          }));
          setChildren(mappedChildren);

          if (r.model.length === 1) {
            setSelectedChild(mappedChildren[0]);
          }
        });
    } else {
      setSelectedClassroomId(undefined);
    }
  }, [selectedClassroom]);

  useEffect(() => {
    if (selectedChild) {
      setSelectedFamily(undefined);
      setSelectedChildId(selectedChild.value);

      familyService
        .findFamiliesForChild(selectedChild.value).then((r) => {
          const mappedFamilies = r.model.map((c) => ({
            value: c.familyId,
            label: c.name,
          }));
          setFamilies(mappedFamilies);

          if (r.model.length === 1) {
            setSelectedFamily(mappedFamilies[0]);
          }
        });
    } else {
      setSelectedChildId(undefined);
    }
  }, [selectedChild]);

  useEffect(() => {
    if (selectedFamily) {
      setSelectedFamilyId(selectedFamily.value);

      userService
        .getGuardiansForFamily(selectedFamily.value).then((r) => {
          setUsersToSendTo(r.model);
        });
    } else {
      setSelectedFamilyId(undefined);
      setUsersToSendTo(undefined);
    }
  }, [selectedFamily]);

  return (
    <>
      <FormGroup>
        <Label>
          Classroom&nbsp;
          <Required />
        </Label>
        <Select
          className={classroomError ? 'tf-select hasError' : 'tf-select'}
          {...bindSelectedClassroom}
          options={classrooms}
        />
      </FormGroup>
      {children.length > 0 && (
        <FormGroup>
          <Label>
            In regards to&nbsp;
            <Required />
          </Label>
          <Select
            className={childError ? 'tf-select hasError' : 'tf-select'}
            {...bindSelectedChild}
            options={children}
          />
        </FormGroup>
      )}
      {families.length > 1 && (
        <FormGroup>
          <Label>
            Family&nbsp;
            <Required />
          </Label>
          <Select
            className={familiesError ? 'tf-select hasError' : 'tf-select'}
            {...bindSelectedFamily}
            options={families}
          />
        </FormGroup>
      )}

      {usersToSendTo && usersToSendTo.length > 0 && (
        <div className="bg-light">
          <Label className="mb-3">
            The following Parent/Guardians will be able to view and respond to your message:
          </Label>
          {usersToSendTo.map((g) => (
            <ListItemGuardian canEdit={false} key={g.id} guardian={g} />
          ))}
        </div>
      )}
    </>
  );
}
