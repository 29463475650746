import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import userService from '../../../services/userService';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';
import CardForm from '../../../components/CardForm';
import EditUserForm from '../../../forms/EditUserForm';
import CardCustom from '../../../components/CardCustom';
import ListItemClassroomLarge from '../../../components/ListItemClassroomLarge';
import PasswordForm from '../../../forms/PasswordForm';

export default function ProfilePage() {
  document.title = 'Edit Profile | Today\'s Family Fridge';

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState();

  const loadUser = async () => {
    setIsLoading(true);
    setIsSubmitting(false);

    await userService
      .getCurrentUser()
      .then((response) => { setUser(response.model); });
  };

  useEffect(() => { loadUser(); }, []);
  useEffect(() => { setIsLoading(false); }, [user]);

  return (
    <main className="main" id="top">
      {(!user || isLoading)
        ? <Spinner />
        : (
          <>
            <PageHeaderActions
              title="My Profile"
              isSubmitting={isSubmitting}
              userRole={user.role}
              requiredPermission="users:profile-edit"
              formName="userDetailForm"
              isNew={false}
            />
            <div>
              <Row>
                <Col lg="9">
                  <CardForm title="User">
                    <EditUserForm
                      user={user}
                      canEdit
                      canChangeRole={false}
                      setIsSubmitting={(s) => setIsSubmitting(s)}
                    />
                  </CardForm>
                  {user.classrooms && (
                  <CardCustom title="Classrooms">
                    {user.classrooms.map((classroom) => (
                      <ListItemClassroomLarge classroom={classroom} />
                    ))}
                  </CardCustom>
                  )}
                </Col>
                <Col lg="3">
                  <div className="sticky-top sticky-sidebar">
                    <CardCustom title="Password">
                      <PasswordForm userId={user.id} />
                    </CardCustom>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
    </main>
  );
}
