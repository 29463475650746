import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CanUser } from './CanUser';
import SpinnerButton from './SpinnerButton';

export default function PageHeaderActions(props) {
  const {
    title,
    children,
    isNew = false,
    isSubmitting,
    formName,
    userRole,
    requiredPermission,
    subTitle = '',
  } = props;

  const history = useHistory();

  return (
    <Card className="card-gradient mb-3">
      <CardBody className="d-flex flex-row align-items-center justify-content-between tf-site-header">
        {subTitle.length === 0
          ? <h1 className="h3 mb-0 text-white">{title}</h1>
          : (
            <h1 className="h3 mb-0 text-white text-truncate" style={{ lineHeight: '1.4rem' }}>
              {title}
              <br />
              <small className="fs-1">{subTitle}</small>
            </h1>
          )}

        <div className="d-flex flex-row ml-2 nowrap" style={{ whiteSpace: 'nowrap' }}>
          {children}
          {isNew && (
          <Button onClick={() => history.goBack()} className="btn btn-sm btn-falcon-danger rounded-capsule align-items-center d-inline-flex">
            <FontAwesomeIcon icon="minus" className="mr-2" />
            Cancel
          </Button>
          )}
          <CanUser
            role={userRole}
            perform={requiredPermission}
            yes={() => (
              <SpinnerButton
                isLoading={isSubmitting}
                className="btn btn-sm btn-falcon-primary rounded-capsule ml-2 align-items-center d-inline-flex"
                form={formName}
              >
                <FontAwesomeIcon icon="save" className="mr-2" />
                {isNew ? 'Create' : 'Save'}
              </SpinnerButton>
            )}
          />
        </div>
      </CardBody>
    </Card>
  );
}
