import React from 'react';
import { Field } from 'formik';
import { CustomInput, Col } from 'reactstrap';

export default function RequiredCheck({
  id, name, label, isDisabled, colSize = '12',
}) {
  return (
    <Col xs={colSize}>
      <Field
        name={name}
        render={({ field }) => (
          <CustomInput
            disabled={isDisabled}
            type="checkbox"
            id={id}
            label={label}
            checked={field.value}
            {...field}
          />
        )}
      />
    </Col>
  );
}
