import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../context/userContext';

export default function SearchNav() {
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <nav className="navbar navbar-light navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit navbar-expand">
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <a className="navbar-brand text-left ml-3">
            <div className="d-flex align-items-center py-3" />
          </a>
          <div className="collapse navbar-collapse" id="navbarNavDropdown1">
            <ul className="navbar-nav align-items-center ml-auto">
              <li className="nav-item dropdown">
                <a className="nav-link pr-0" id="navbarDropdownUser" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className="avatar avatar-xl">
                    <img className="rounded-circle" src={user.photo} alt="" />
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="navbarDropdownUser">
                  <div className="bg-white rounded-soft py-2">
                    <NavLink to="/profile" className="dropdown-item">Profile</NavLink>
                    <NavLink to="/Logout" className="dropdown-item">Logout</NavLink>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      )}
    </UserContext.Consumer>
  );
}
