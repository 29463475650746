import { authenticatedRequest } from '../helpers/authenticatedRequest';
import authenticatedUpload from '../helpers/authenticatedUpload';

const URL = `${process.env.REACT_APP_API_URL}/Locations`;

async function all(showArchived) {
  let endpoint = URL;

  if (showArchived) {
    endpoint += '/Archived';
  }

  return authenticatedRequest('GET', endpoint);
}

async function find(id) {
  return authenticatedRequest('GET', `${URL}/${id}`);
}

async function archive(id) {
  return authenticatedRequest('DELETE', `${URL}/${id}`);
}

async function restore(id) {
  return authenticatedRequest('POST', URL + '/Restore/' + id);
}

async function create(location) {
  return authenticatedRequest('POST', URL, JSON.stringify(location));
}

async function update(location) {
  return authenticatedRequest('PUT', `${URL}/${location.locationId}`, JSON.stringify(location));
}

async function updateLocationSiteAdminRelationships(id, userIds) {
  return authenticatedRequest('POST', URL + '/LocationSiteAdmins', JSON.stringify({
    locationId: parseInt(id),
    userIds,
  }));
}

async function importChildData(id, file) {
  return authenticatedUpload(URL + '/Import/' + id, file);
}

async function importStaffData(id, file) {
  return authenticatedUpload(URL + '/Import/Staff/' + id, file);
}

async function activateLocation(id) {
  return authenticatedRequest('POST', URL + '/Activate/' + id);
}

const locationService = {
  all,
  find,
  archive,
  restore,
  create,
  update,
  updateLocationSiteAdminRelationships,
  importChildData,
  importStaffData,
  activateLocation,
};

export default locationService;
