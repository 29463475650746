import { anonymousRequest } from '../helpers/anonymousRequest';
import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/User`;

async function getUsers(showArchived) {
  let endpoint = URL;

  if (showArchived) {
    endpoint += '/Archived';
  }

  return authenticatedRequest('GET', endpoint);
}

async function getMessagingUsers() {
  return authenticatedRequest('GET', URL + '/AvailableToMessage');
}

async function findUser(id) {
  return authenticatedRequest('GET', URL + '/' + id);
}

async function deleteUser(id) {
  return authenticatedRequest('DELETE', URL + '/Archive/' + id);
}

async function restoreUser(id) {
  return authenticatedRequest('POST', URL + '/Restore/' + id);
}

async function updateUser(user) {
  return authenticatedRequest('PUT', URL + '/' + user.id, JSON.stringify(user));
}

async function addUser(user) {
  return authenticatedRequest('POST', URL, JSON.stringify(user));
}

async function getGuardiansForFamily(familyId) {
  return authenticatedRequest('GET', URL + '/Family/' + familyId);
}

async function getSiteAdmins() {
  return authenticatedRequest('GET', URL + '/SiteAdmins');
}

async function getCaregivers() {
  return authenticatedRequest('GET', URL + '/Caregivers');
}

async function getCaregiversForClassroom(classroomId) {
  return authenticatedRequest('GET', URL + '/Caregivers/' + classroomId);
}

async function getGuardians() {
  return authenticatedRequest('GET', URL + '/Guardians');
}

async function getCurrentUser() {
  return authenticatedRequest('GET', URL + '/CurrentUser');
}

async function changePassword(id, currentPassword, newPassword) {
  return authenticatedRequest('POST', URL + '/ChangePassword', JSON.stringify({
    id,
    currentPassword,
    newPassword,
  }));
}

async function setPassword(token, email, password) {
  return anonymousRequest('POST', URL + '/SetPassword', JSON.stringify({
    token,
    email,
    password,
  }));
}

async function resetPassword(token, email, password) {
  return anonymousRequest('POST', URL + '/ResetPassword', JSON.stringify({
    token,
    email,
    password,
  }));
}

async function resendActivation(id) {
  return authenticatedRequest('POST', URL + '/Resend/' + id);
}

const userService = {
  getUsers,
  findUser,
  deleteUser,
  restoreUser,
  updateUser,
  addUser,
  getGuardiansForFamily,
  getSiteAdmins,
  getCaregivers,
  getCaregiversForClassroom,
  getGuardians,
  getCurrentUser,
  changePassword,
  setPassword,
  resetPassword,
  getMessagingUsers,
  resendActivation,
};

export default userService;
