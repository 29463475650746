import React, { useState, useContext } from 'react';
import { Row, Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { UserContext } from '../context/userContext';
import { CheckPermission } from '../components/CanUser';
import userService from '../services/userService';
import uploadService from '../services/uploadService';
import Avatar from '../components/Avatar';
import RequiredField from '../components/RequiredField';
import RequiredSelect from '../components/RequiredSelect';

export default function EditUserForm(props) {
  const {
    user, canEdit, setIsSubmitting, setUser, canChangeRole = true,
  } = props;

  const userContext = useContext(UserContext);
  const [photo = user.photo || '', setPhoto] = useState();

  // Roles are only allowed to create accounts at their level or lower.
  function loadRoleOptions() {
    const options = [];

    if (CheckPermission(userContext.user.role, 'users:create-admin')) {
      options.push({ value: 'Admin', label: 'Admin' });
    }

    if (CheckPermission(userContext.user.role, 'users:create-siteAdmin')) {
      options.push({ value: 'Site Admin', label: 'Supervisor' });
    }

    if (CheckPermission(userContext.user.role, 'users:create-caregiver')) {
      options.push({ value: 'Caregiver', label: 'Caregiver/Provider' });
    }

    if (CheckPermission(userContext.user.role, 'users:create-guardian')) {
      options.push({ value: 'Guardian', label: 'Parent/Guardian' });
    }

    return options;
  }

  function loadPronounOptions() {
    return [
      { value: 'he-him', label: 'He/Him' },
      { value: 'she-her', label: 'She/Her' },
      { value: 'they-them', label: 'They/Them' },
    ];
  }

  const roleOptions = loadRoleOptions();
  const pronounOptions = loadPronounOptions();

  const initialValues = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    emailAddress: user.email || '',
    pronouns: pronounOptions.filter((p) => p.value === user.pronouns)[0],
    role: roleOptions.filter((r) => r.value === user.role)[0],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'First Name must not exceed 100 characters.')
      .required('The First Name field is required.'),

    lastName: Yup.string()
      .max(100, 'Last Name must not exceed 100 characters.')
      .required('The Last Name field is required.'),

    emailAddress: Yup.string()
      .email('Please enter a valid Email Address.')
      .required('The Email Address field is required.'),
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);

    const obj = {
      username: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      photo,
      notes: user.notes,
      role: values.role.value,
    };

    if (typeof values.pronouns !== 'undefined') {
      obj.pronouns = values.pronouns.value;
    }

    if (typeof user.id === 'undefined') {
      await userService
        .addUser(obj)
        .then(async (r) => setUser(r.model))
        .finally(() => setIsSubmitting(false));
    } else {
      obj.id = user.id;

      await userService
        .updateUser(obj)
        .then((r) => {
          if (r.model.id === userContext.user.id) {
            userContext.setUser(r.model);
          }

          setUser(r.model);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  const changePhoto = async (file, p) => {
    let fileName = p ? p.src : '';

    if (file) {
      const response = await uploadService.uploadPhoto(file);
      fileName = response.model.path;
    }

    setPhoto(fileName);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(p) => (
        <Form id="userDetailForm" onSubmit={p.handleSubmit}>
          {typeof user.id !== 'undefined' && <Avatar photo={`${photo}`} changeProfilePhoto={changePhoto} />}
          <Row>
            <RequiredField colSize="6" formKey="firstName" labelName="First Name" inputPlaceholder="First Name" isDisabled={!canEdit} />
            <RequiredField colSize="6" formKey="lastName" labelName="Last Name" inputPlaceholder="Last Name" isDisabled={!canEdit} />
          </Row>
          <Row>
            <RequiredField colSize="6" formKey="emailAddress" labelName="Email Address" inputPlaceholder="Email Address" isDisabled={!canEdit} />
            <RequiredSelect colSize="3" labelName="Pronouns" name="pronouns" options={pronounOptions} isDisabled={!canEdit} isRequired={false} />
            {canChangeRole
            && <RequiredSelect colSize="3" labelName="Role" name="role" options={roleOptions} isDisabled={!canEdit} isRequired />}
          </Row>
        </Form>
      )}
    </Formik>
  );
}
