import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

// Layouts
import { library } from '@fortawesome/fontawesome-svg-core';

// Import individual icons and add them to the library.
// Reduces overall size of FontAwesome throughout the entire site
// By only importing what we need.
// https://www.npmjs.com/package/@fortawesome/react-fontawesome
import {
  faHome, faBuilding, faImage, faBell, faCheckCircle, faCamera, faChevronUp,
  faGraduationCap, faFileUpload, faSearch, faUserCheck, faBan, faCheck,
  faChild, faUsers, faEdit, faSitemap, faQuestionCircle, faAllergies, faFileExport,
  faUser, faEnvelope, faSave, faTrash, faArchive, faMinus, faTimesCircle,
  faCommentDots, faPlus, faAsterisk, faChevronRight, faChevronLeft, faUserPlus, faCalendarAlt,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';

import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import LoginLayoutRoute from './layout/LoginLayout';
import DashboardLayoutRoute from './layout/DashboardLayout';

import { UserContextProvider } from './context/userContext';

import DashboardPage from './pages/Dashboard';
import { EditUserPage, ProfilePage, UserPage } from './pages/User';
import { EditOrganizationPage, OrganizationPage } from './pages/Organization';
import { EditLocationPage, LocationPage } from './pages/Location';
import { ClassroomPage, EditClassroomPage } from './pages/Classroom';
import { ChildrenPage, EditChildPage } from './pages/Children';
import { EditFamilyPage, FamilyPage } from './pages/Family';
import NewCommunicationPage from './pages/Communication/New';
import NotFoundPage from './pages/Error/404';
import LoginPage from './pages/Authentication/LoginPage';
import ResetPasswordPage from './pages/Authentication/ResetPasswordPage';
import ForgotPasswordPage from './pages/Authentication/ForgotPasswordPage';
import VerifyPage from './pages/Authentication/VerifyPage';
import LogoutPage from './pages/Authentication/LogoutPage';
import PasswordSetPage from './pages/Authentication/PasswordSetPage';
import ErrorPage from './pages/Error/500';
import ConversationsPage from './pages/Conversations/Conversations';
import NotificationsPage from './pages/Notification/Index';

library.add(
  faHome, faBuilding, faImage, faBell, faUserPlus, faTimesCircle, faBan, faCheck,
  faGraduationCap, faFileUpload, faSearch, faQuestionCircle, faCalendarAlt, faChevronUp,
  faChild, faUsers, faEdit, faSitemap, faUserCheck, faCamera, faAllergies, faFileExport,
  faUser, faEnvelope, faSave, faTrash, faArchive, faMinus, faCheckCircle,
  faCommentDots, faPlus, faAsterisk, faChevronRight, faChevronLeft, faRedoAlt,
);

ReactGA.initialize('UA-172510774-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  return (
    <Router>
      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      <UserContextProvider>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <LoginLayoutRoute path="/login" exact component={LoginPage} />
          <LoginLayoutRoute path="/reset-password" exact component={ResetPasswordPage} />
          <LoginLayoutRoute path="/forgot-password" exact component={ForgotPasswordPage} />
          <LoginLayoutRoute path="/verify" exact component={VerifyPage} />
          <LoginLayoutRoute path="/logout" exact component={LogoutPage} />
          <LoginLayoutRoute path="/password-set" exact component={PasswordSetPage} />

          { /* Private routes, must have a jwt that is able to be authenticated. */}
          <DashboardLayoutRoute path="/dashboard" exact component={DashboardPage} />
          <DashboardLayoutRoute path="/profile" exact component={ProfilePage} />

          <DashboardLayoutRoute path="/organizations/edit/:id?" exact component={EditOrganizationPage} />
          <DashboardLayoutRoute path="/organizations" exact component={OrganizationPage} />

          <DashboardLayoutRoute path="/locations/edit/:id?" exact component={EditLocationPage} />
          <DashboardLayoutRoute path="/locations" exact component={LocationPage} />

          <DashboardLayoutRoute path="/classrooms/edit/:id?" exact component={EditClassroomPage} />
          <DashboardLayoutRoute path="/classrooms" exact component={ClassroomPage} />

          <DashboardLayoutRoute path="/children/edit/:id?" exact component={EditChildPage} />
          <DashboardLayoutRoute path="/children" exact component={ChildrenPage} />

          <DashboardLayoutRoute path="/families/edit/:id?" exact component={EditFamilyPage} />
          <DashboardLayoutRoute path="/families" exact component={FamilyPage} />

          <DashboardLayoutRoute path="/users/edit/:id?" exact component={EditUserPage} />
          <DashboardLayoutRoute path="/users" exact component={UserPage} />

          <DashboardLayoutRoute path="/sendcommunication" exact component={NewCommunicationPage} />
          <DashboardLayoutRoute path="/conversations" exact component={ConversationsPage} />
          <DashboardLayoutRoute path="/communication" exact component={NotificationsPage} />

          {/* Default Error routes, don't need to be logged in but
          these will catch anything that doesn't match above */}

          <LoginLayoutRoute path="/error" exact component={ErrorPage} />
          <LoginLayoutRoute path="*" component={NotFoundPage} />
        </Switch>
      </UserContextProvider>
    </Router>
  );
}
