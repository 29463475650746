import React, { useState, useEffect, useCallback } from 'react';

import { Row, Col } from 'reactstrap';

import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import locationService from '../../../../../services/locationService';
import uploadService from '../../../../../services/uploadService';
import CardCustom from '../../../../../components/CardCustom';
import CardStyle from '../../../../../components/CardStyle';
import CardForm from '../../../../../components/CardForm';
import SingleNote from '../../../../../components/SingleNote';
import SingleEvent from '../../../../../components/SingleEvent';
import SingleMenu from '../../../../../components/SingleMenu';
import EditClassroomForm from '../../../../../forms/EditClassroomForm';

export default function ClassroomOverview(props) {
  const {
    classroom, canEdit, canViewNotes, canViewEvents, canViewMenus, canViewPhotos,
    classroomChildren, caregivers, selectedLocationId,
  } = props;

  const [locations, setLocations] = useState([]);
  const [currentClassroom, setClassroom] = useState({});

  const [events, setEvents] = useState([]);
  const [notes, setNotes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [photos, setPhotos] = useState([]);

  const [isNew, setIsNew] = useState(true);

  // lightbox state
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((e, { i }) => {
    setCurrentImage(i);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => { setClassroom(classroom); }, [classroom]);

  useEffect(() => {
    setEvents((currentClassroom.events || []).slice(0, 2));
    setNotes((currentClassroom.notes || []).slice(0, 4));
    setMenus((currentClassroom.menus || []).slice(0, 2));
    setPhotos((currentClassroom.photos || []).slice(0, 5));

    setIsNew(typeof currentClassroom.classroomId === 'undefined');
  }, [currentClassroom]);

  const loadLocations = async () => {
    await locationService
      .all()
      .then((r) => setLocations(r.model));
  };

  useEffect(() => { loadLocations(); }, []);

  const changePhoto = async (file, photo) => {
    if (file) {
      await uploadService
        .uploadPhoto(file)
        .then((response) => { setClassroom({ ...currentClassroom, photo: response.model.path }); });
    } else {
      setClassroom({ ...currentClassroom, photo: photo.src });
    }
  };

  return (
    <main className="main" id="top">
      {!isNew
      && (
        <CardStyle
          handleSubmit={changePhoto}
          currentPath={currentClassroom.photo}
          title={currentClassroom.name}
          canEdit={canEdit}
        />
      )}

      <CardForm type="Classroom" canEdit={canEdit}>
        <EditClassroomForm
          classroomChildren={classroomChildren}
          caregivers={caregivers}
          selectedLocationId={selectedLocationId}
          canEdit={canEdit}
          classroom={currentClassroom}
          locations={locations}
          setWasCreated={(w) => props.setWasCreated(w)}
          setSubmitting={(s) => props.setSubmitting(s)}
        />
      </CardForm>

      {!isNew && (
      <div>
        {canViewNotes && (
        <CardCustom title="Latest Notes" footer={(<span className="btn btn-link btn-block" onClick={() => { props.setTab('2'); }} role="button" onKeyDown={() => { props.setTab('2'); }} tabIndex={0}>All Notes</span>)}>
          {notes.length > 0
            ? notes.map((note) => <SingleNote note={note} key={'note_' + note.noteId} />)
            : <h5 className="text-center text-muted my-2">There haven&#39;t been any notes added. Please check back soon!</h5>}
        </CardCustom>
        )}

        {canViewEvents && (
        <CardCustom title="Upcoming Events" footer={(<span className="btn btn-link btn-block" onClick={() => { props.setTab('3'); }} role="button" onKeyDown={() => { props.setTab('3'); }} tabIndex={0}>All Events</span>)}>
          {events.length > 0
            ? events.map((event) => <SingleEvent event={event} key={'event_' + event.eventId} />)
            : <h5 className="text-center text-muted my-2">There are no upcoming events. Please check back soon!</h5>}
        </CardCustom>
        )}

        {canViewMenus && (
        <CardCustom title="Upcoming Menus" footer={(<span className="btn btn-link btn-block" onClick={() => { props.setTab('4'); }} role="button" onKeyDown={() => { props.setTab('4'); }} tabIndex={0}>All Menus</span>)}>
          {menus.length > 0
            ? (
              <Row>
                {menus.map((menu) => (
                  <Col sm="6" xs="12" key={'menu_' + menu.menuId}>
                    <SingleMenu menu={menu} />
                  </Col>
                ))}
              </Row>
            )
            : <h5 className="text-center text-muted my-2">The next menu hasn&#39;t been set yet. Please check back soon!</h5>}
        </CardCustom>
        )}

        {canViewPhotos && (
        <CardCustom title="Latest Photos" footer={(<span className="btn btn-link btn-block" onClick={() => { props.setTab('5'); }} role="button" onKeyDown={() => { props.setTab('5'); }} tabIndex={0}>All Photos</span>)}>
          {photos.length > 0 && (
          <div>
            <Gallery
              photos={photos.map((p) => (
                {
                  src: p.path,
                  width: p.width / p.height,
                  height: 1,
                }
              ))}
              onClick={openLightbox}
            />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map((p) => ({
                      src: p.path,
                      caption: p.description,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
          )}

          {photos.length === 0 && (
          <Row>
            <Col>
              <h5 className="text-center text-muted mb-4">There haven&#39;t been any recent photos added. Please check back soon!</h5>
            </Col>
          </Row>
          )}
        </CardCustom>
        )}
      </div>
      )}
    </main>
  );
}
