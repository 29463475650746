import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Classroom`;

async function all(showArchived) {
  let endpoint = URL;

  if (showArchived) {
    endpoint += '/Archived';
  }

  return authenticatedRequest('GET', endpoint);
}

async function archive(id) {
  return authenticatedRequest('DELETE', URL + '/' + id);
}

async function restore(id) {
  return authenticatedRequest('POST', URL + '/Restore/' + id);
}

async function update(c) {
  return authenticatedRequest('PUT', URL + '/' + c.classroomId, JSON.stringify(c));
}

async function create(c) {
  return authenticatedRequest('POST', URL, JSON.stringify(c));
}

async function find(id) {
  return authenticatedRequest('GET', URL + '/' + id);
}

async function findByLocation(ids) {
  return authenticatedRequest('POST', URL + '/Locations', JSON.stringify(ids));
}

async function findForChild(childId) {
  return authenticatedRequest('GET', URL + '/Child/' + childId);
}

async function updateCaregiverClassroomRelationships(id, userIds) {
  return authenticatedRequest('POST', URL + '/ClassroomCaregivers', JSON.stringify({
    classroomId: parseInt(id),
    userIds,
  }));
}

async function updateClassroomChildrenRelationships(id, childIds) {
  return authenticatedRequest('POST', URL + '/ClassroomChildren', JSON.stringify({
    classroomId: parseInt(id),
    childIds,
  }));
}

const classroomService = {
  all,
  archive,
  restore,
  update,
  create,
  find,
  findByLocation,
  findForChild,
  updateCaregiverClassroomRelationships,
  updateClassroomChildrenRelationships,
};

export default classroomService;
