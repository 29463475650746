import handleResponse from './handleApiResponse';

// Make an anonymous request to the API. Generally this is used
// when logging in/retriving non-password protected data.
export async function anonymousRequest(requestType, endpoint, body = '') {
  const request = {
    method: requestType,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body.length > 0) {
    request.body = body;
  }

  return handleResponse(await fetch(endpoint, request), false);
}

export async function anonymousRESTRequest(requestType, endpoint, body = '') {
  const request = {
    method: requestType,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body.length > 0) {
    request.body = body;
  }

  return fetch(endpoint, request);
}
