import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import {
  FormGroup, Button, Form, Collapse,
} from 'reactstrap';
import * as Yup from 'yup';
import useSelect from '../hooks/useSelect';
import userService from '../services/userService';
import Required from '../components/Required';
import FormModal from '../components/FormModal';
import RequiredField from '../components/RequiredField';

export default function AddSiteAdminForm(props) {
  const {
    availableSiteAdmins,
    addCreatedSiteAdmin,
    addSiteAdmin,
    updateSiteAdmins,
    isHomeCentre,
    canCreate,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    value: siteAdmins,
    bind: bindSiteAdmins,
  } = useSelect([]);

  const options = availableSiteAdmins.map((sa) => {
    const name = sa.firstName + ' ' + sa.lastName;
    return { value: sa.id, label: name };
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'First Name must not exceed 100 characters.')
      .required('The First Name field is required.'),

    lastName: Yup.string()
      .max(100, 'Last Name must not exceed 100 characters.')
      .required('The Last Name field is required.'),

    emailAddress: Yup.string()
      .email('Please enter a valid Email Address.')
      .required('The Email Address field is required.'),
  });

  const onFormSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    const obj = {
      username: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      role: 'Site Admin',
    };

    await userService
      .addUser(obj)
      .then(async (r) => {
        if (r.hasError) {
          toast.error(r.errorMessage);
        } else {
          addCreatedSiteAdmin(r.model);
          resetForm(initialValues);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ids = siteAdmins.map((sa) => sa.value);
    addSiteAdmin(ids);
    updateSiteAdmins([]);
  };

  return (
    <div>
      <a
        className="d-block d-flex align-items-center collapsed addRow"
        id="addSiteAdminButton"
        href="#caregiver-form"
        data-toggle="collapse"
        aria-expanded="false"
        aria-controls="child-form"
      >
        <span className="circle-dashed">
          <FontAwesomeIcon icon="plus" />
        </span>
        <h5 className="ml-3 mb-0 text-primary">
          {isHomeCentre ? 'Add Home Visitor' : 'Add Supervisor'}
        </h5>
      </a>
      <Collapse id="caregiver-form">
        <em className="text-muted mt-3 d-block">
          {isHomeCentre ? 'Home Visitors ' : 'Supervisors '}
          have the
          ability to create and add new families to this location.
        </em>
        <Form onSubmit={handleSubmit} className="mt-2">
          <FormGroup className="mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <label className="mb-0" htmlFor="organizer">
                Choose
                {isHomeCentre ? ' Home Visitors ' : ' Supervisors '}
                <Required />
              </label>
              {canCreate && (
                <FormModal
                  className="btn btn-link btn-sm pr-0"
                  buttonLabel="Add New"
                  isSubmitting={isSubmitting}
                  formName="userDetailForm"
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={onFormSubmit}
                  >
                    {(p) => (
                      <Form id="userDetailForm" onSubmit={p.handleSubmit}>
                        <RequiredField
                          formKey="firstName"
                          labelName="First Name"
                          inputPlaceholder="First Name"
                        />
                        <RequiredField
                          formKey="lastName"
                          labelName="Last Name"
                          inputPlaceholder="Last Name"
                        />
                        <RequiredField
                          formKey="emailAddress"
                          labelName="Email Address"
                          inputPlaceholder="Email Address"
                        />
                      </Form>
                    )}
                  </Formik>
                </FormModal>
              )}
            </div>
            <Select
              options={options}
              {...bindSiteAdmins}
              className="w-100"
              size="sm"
              isMulti
              closeMenuOnSelect={false}
            />
          </FormGroup>
          <Button className="btn-falcon-primary mr-2" size="sm" type="submit">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            Add to Location
          </Button>
        </Form>
        <hr className="border-dashed border-bottom-0" />
      </Collapse>
    </div>
  );
}
