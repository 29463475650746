import React, {
  useState, useEffect, useContext, useRef,
} from 'react';

import queryString from 'query-string';

import { useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { UserContext } from '../../../context/userContext';
import userService from '../../../services/userService';
import { CanUser, CheckPermission } from '../../../components/CanUser';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';
import CardForm from '../../../components/CardForm';
import EditUserForm from '../../../forms/EditUserForm';
import CardCollapse from '../../../components/CardCollapse';
import AdvancedEditor from '../../../components/AdvancedEditor';
import CardCustom from '../../../components/CardCustom';
import ListItemLocation from '../../../components/ListItemLocation';
import ListItemSmall from '../../../components/ListItemSmall';
import CardDeactivate from '../../../components/CardDeactivate';

export default function EditUserPage(props) {
  document.title = 'Edit User | Today\'s Family Fridge';

  const { location, match } = props;
  const { id } = match.params;

  const history = useHistory();
  const userState = useContext(UserContext);
  const params = queryString.parse(location.search);
  const didMount = useRef(false);

  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [editUser, setEditUser] = useState({});

  const handleDeactivateClick = async (e) => {
    e.preventDefault();

    if (editUser.isEnabled) {
      await userService.deleteUser(id);
      history.push('/users');
    } else {
      await userService.restoreUser(id)
        .then((r) => {
          if (!r.hasError) {
            setEditUser({ ...editUser, isEnabled: true });
          }
        });
    }
  };

  let canView = true;
  if (!CheckPermission(userState.user.role, 'users:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  const canEdit = CheckPermission(userState.user.role, 'users:edit');

  useEffect(() => {
    if (didMount.current) {
      if (typeof editUser.id === 'undefined') {
        if (typeof params.f !== 'undefined') {
          setEditUser({ ...editUser, role: 'Guardian' });
        } else if (typeof params.c !== 'undefined') {
          setEditUser({ ...editUser, role: 'Caregiver' });
        } else if (typeof params.l !== 'undefined') {
          setEditUser({ ...editUser, role: 'Site Admin' });
        }
      }

      setIsNew(typeof editUser.id === 'undefined');
      setIsLoading(false);
    }
  }, [editUser]);

  const loadUser = async () => {
    setIsLoading(true);

    if (canView) {
      if (typeof id === 'undefined') {
        didMount.current = true;

        setIsLoading(false);
        setEditUser({});
      } else {
        await userService
          .findUser(id)
          .then((r) => setEditUser(r.model))
          .finally(() => { didMount.current = true; });
      }
    }
  };

  useEffect(() => { loadUser(); }, [id]);

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <main className="main" id="top">
          <PageHeaderActions
            title={isNew ? 'Create User' : 'Edit User'}
            subTitle={isNew ? '' : editUser.firstName + ' ' + editUser.lastName}
            isSubmitting={isSubmitting}
            userRole={user.role}
            requiredPermission="users:edit"
            formName="userDetailForm"
            isNew={isNew}
          />

          {isLoading
            ? <Spinner />
            : (
              <div>
                <Row>
                  <Col lg={isNew ? '12' : '8'} className={isNew ? '' : 'pr-lg-2 mb-3'}>
                    <CardForm title="User" canEdit={canEdit}>
                      <EditUserForm
                        setIsSubmitting={(s) => setIsSubmitting(s)}
                        canEdit={canEdit}
                        user={editUser}
                      />
                    </CardForm>

                    {!isNew && (
                      <CardCollapse title="Notes" stringId="notes" subtitle="These notes are visible to everyone" isShowing bodyClass="notes-body">
                        <AdvancedEditor
                          value={editUser.notes}
                          onChange={(e) => { editUser.notes = e; }}
                        />
                      </CardCollapse>
                    )}
                  </Col>

                  {!isNew
                      && (
                      <Col lg="4" className="pl-lg-2 mb-3">
                        <div className="sticky-top sticky-sidebar">
                          {editUser.locations && editUser.locations.length > 0 && (
                            <CardCustom title="Locations">
                              {editUser.locations.map((l) => <ListItemLocation location={l} />)}
                            </CardCustom>
                          )}

                          {editUser.classrooms && editUser.classrooms.length > 0 && (
                            <CardCustom title="Classrooms">
                              {editUser.classrooms.map((classroom) => (
                                <ListItemSmall
                                  linkTo={'/classrooms/edit/' + classroom.classroomId}
                                  imgSrc={classroom.photo}
                                  title={classroom.name}
                                >
                                  <Link to="/locations/edit/" className="text-700">{classroom.locationName}</Link>
                                </ListItemSmall>
                              ))}
                            </CardCustom>
                          )}

                          {editUser.families && editUser.families.length > 0 && (
                            <CardCustom title="Families">
                              {editUser.families.map((f) => (
                                <ListItemSmall
                                  key={'family_' + f.familyId}
                                  linkTo={'/families/edit/' + f.familyId}
                                  title={f.name}
                                />
                              ))}
                            </CardCustom>
                          )}

                          <CanUser
                            role={user.role}
                            perform="users:archive"
                            yes={() => <CardDeactivate label="User" handleArchiveClick={handleDeactivateClick} isArchived={!editUser.isEnabled} />}
                          />
                        </div>
                      </Col>
                      )}
                </Row>
              </div>
            )}
        </main>
      )}
    </UserContext.Consumer>
  );
}
