import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from './authHeader';

export default function handleResponse(response, showToast = true) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (showToast && data.errorMessage && data.hasError) {
        toast.error(data.errorMessage);
      }

      const token = authHeader();
      if (response.status === 401 && Object.keys(token).length > 0) {
        localStorage.removeItem('jwt');
        window.location.reload(true);
      }

      return Promise.reject((data && data.errorMessage));
    }

    if (showToast) {
      if (data.hasError && data.errorMessage) {
        toast.error(data.errorMessage);
      } else if (!data.hasError && data.message) {
        toast.success(data.message);
      }
    }

    return data;
  });
}
