import { authenticatedRequest, authenticatedRequestBasic } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/export`;


async function exportAsync() {
  return authenticatedRequestBasic('GET', URL + '/');
}

const exportService = {
  exportAsync,
};

export default exportService;
