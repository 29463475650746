export default function authHeader() {
  const json = sessionStorage.getItem('jwt');
  if (json !== 'undefined') {
    const token = JSON.parse(json);
    if (token.length > 0) {
      return { Authorization: `Bearer ${token}` };
    }
  }

  return {};
}
