import React from 'react';

import { Col, Row, ButtonGroup } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import { CanUser } from './CanUser';
import TooltipButton from './TooltipButton';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const { SearchBar } = Search;

export default function ExtendedTable(props) {
  const { rowStyle, data, tableClickEvent } = props;
  const rowEvents = { onClick: tableClickEvent };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing&nbsp;
      {from}
      &nbsp;to&nbsp;
      {to}
      &nbsp;of&nbsp;
      {size}
      &nbsp;Results
    </span>
  );

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <ButtonGroup size="sm">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-outline-secondary'}`}
          >
            {option.text}
          </button>
        );
      })}
    </ButtonGroup>
  );

  const options = {
    custom: true,
    pageStartIndex: 1,
    hidePageListOnlyOnePage: true,
    prePageText: 'Back',
    nextPageText: 'Next',
    showTotal: true,
    alwaysShowAllBtns: false,
    paginationTotalRenderer: customTotal,
    sizePerPage: 15,
    sizePerPageRenderer,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: 'All', value: data.length },
    ],
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <ToolkitProvider
            columns={props.columns}
            keyField={props.keyField}
            data={props.data}
            search
            bootstrap4
          >
            {(toolkitprops) => (
              <div>
                <Row>
                  <Col xs="12" lg="6" className="hover-show d-none d-sm-block">
                    <SizePerPageDropdownStandalone {...paginationProps} />

                    {typeof props.archiveClicked !== 'undefined' && (
                    <CanUser
                      role={props.userRole}
                      perform={props.archivePermission}
                      yes={() => (
                        <TooltipButton
                          id="archiveButton"
                          tooltipLabel={props.isShowingArchived ? 'Hide Archived' : 'Show Archived'}
                          onClick={props.archiveClicked}
                          buttonIcon="archive"
                          buttonColour={props.isShowingArchived ? 'danger' : 'secondary'}
                          buttonClass="ml-1"
                          isShowing
                          placement="right"
                        />
                      )}
                    />
                    )}
                  </Col>
                  <Col xs="12" lg="6" className="text-right">
                    <SearchBar {...toolkitprops.searchProps} className="form-control w-100" />
                  </Col>
                </Row>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  striped
                  hover
                  bordered={false}
                  keyField={props.keyField}
                  data={props.data}
                  columns={props.columns}
                  search
                  rowEvents={rowEvents}
                  rowStyle={rowStyle}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />

                <Row>
                  <Col lg="6">
                    <PaginationTotalStandalone {...paginationProps} dataSize={props.data.length} />
                  </Col>
                  <Col lg="6" className="text-right">
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </div>
            )}
          </ToolkitProvider>
        </div>
      )}
    </PaginationProvider>
  );
}
