import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import CardHeaderless from '../../components/CardHeaderless';
import ResetPasswordForm from '../../forms/ResetPasswordForm';

// Images
import Logo from '../../resources/images/tff-logo.png';

export default function ResetPasswordPage(props) {
  document.title = 'Reset Password | Today\'s Family Fridge';

  const history = useHistory();

  const { location } = props;
  const params = queryString.parse(location.search);

  if (typeof params.e === 'undefined' || typeof params.t === 'undefined') {
    history.push('/');
  }

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6">
          <Col sm="10" md="8" lg="6" xl="5">
            <a className="d-flex flex-center mb-4" href="/">
              <img className="mr-2" src={Logo} alt="" width="300" />
            </a>

            <CardHeaderless className="p-4 p-sm-5">
              <Row className="text-left justify-content-between align-items-center mb-2">
                <div className="col-auto">
                  <h4 className="mb-0">Reset Password</h4>
                </div>
              </Row>
              <ResetPasswordForm email={params.e} token={params.t} />
              <div className="text-center">
                <Link className="fs--1 text-600" to="/login">
                  Back to Login
                  <span className="d-inline-block ml-1">&rarr;</span>
                </Link>
              </div>
            </CardHeaderless>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
