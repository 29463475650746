import React, { useState } from 'react';
import CardHeaderless from '../../../../../components/CardHeaderless';
import AddNoteForm from '../../../../../forms/AddNoteForm';
import EditNoteForm from '../../../../../forms/EditNoteForm';

export default function ClassroomNotes(props) {
  const {
    canEdit, classroomId, addNote, removeNote, notes,
  } = props;

  const [classroomNotes] = useState(notes);

  return (
    <div>
      <CardHeaderless className="py-3"><h4 className="mb-0">Notes</h4></CardHeaderless>
      {canEdit && <AddNoteForm classroomId={classroomId} addNote={addNote} />}

      {classroomNotes.length > 0
        ? classroomNotes.map((n) => <EditNoteForm note={n} key={n.noteId} canEdit={canEdit} removeNote={removeNote} />)
        : (
          <CardHeaderless>
            <h5 className="text-center text-muted">No notes have been added yet! Please check back soon.</h5>
          </CardHeaderless>
        )}
    </div>
  );
}
