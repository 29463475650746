import authHeader from './authHeader';
import handleResponse from './handleApiResponse';

// / Make a request to the API with the added auth header.
export default async function authenticatedUpload(endpoint, file) {
  const data = new FormData();
  data.append('file', file);

  const request = {
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    body: data,
  };

  return handleResponse(await fetch(endpoint, request));
}
