import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card, CardBody, CardFooter, Button, Form, Input, FormGroup, Label, Row, Col, Spinner
} from 'reactstrap';

import Select from 'react-select';

import { toast } from 'react-toastify';
import { UserContext } from '../../../context/userContext';
import { CheckPermission } from '../../../components/CanUser';

import useSelect from '../../../hooks/useSelect';
import useInput from '../../../hooks/useInput';
import useContent from '../../../hooks/useContent';
import useCheck from '../../../hooks/useCheck';
import communicationService from '../../../services/communicationService';
import classroomService from '../../../services/classroomService';
import locationService from '../../../services/locationService';
import PageHeader from '../../../components/PageHeader';
import Required from '../../../components/Required';
import AdvancedEditor from '../../../components/AdvancedEditor';

export default function NewCommunicationPage() {
  document.title = 'Communications | Today\'s Family Fridge';

  const roleOptions = [
    { value: 'site-admin', label: 'Site Admins' },
    { value: 'caregiver', label: 'Caregivers' },
    { value: 'guardian', label: 'Parent/Guardians' },
  ];

  const history = useHistory();
  const userState = useContext(UserContext);

  const [locations, setLocations] = useState([]);
  const [classrooms, setClassrooms] = useState([]);

  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableClassrooms, setAvailableClassrooms] = useState([]);

  const { value: subject, bind: bindSubject } = useInput('');
  const [subjectHasError, setSubjectHasError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
 

  const { value: content, bind: bindContent } = useContent('');
  const [contentHasError, setContentHasError] = useState(false);
  const { checked: isCritical, bind: bindIsCritical } = useCheck(false);

  const {
    value: selectedLocations,
    bind: bindSelectedLocations,
    setValue: setSelectedLocations,
  } = useSelect();

  const {
    value: selectedClassrooms,
    bind: bindSelectedClassrooms,
    setValue: setSelectedClassrooms,
  } = useSelect();

  const {
    value: selectedRoles,
    bind: bindSelectedRoles,
    setValue: setSelectedRoles,
  } = useSelect();

  if (!CheckPermission(userState.user.role, 'communication:visit') && typeof userState.user.role !== 'undefined') {
    history.push('/error/404');
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubjectHasError(!subject);
    setContentHasError(!content);


    if (selectedLocations.length > 0
            && selectedClassrooms.length > 0
            && selectedRoles.length > 0
            && subject
            && content) {
      const locationIds = selectedLocations.map((l) => l.value);
      const classroomIds = selectedClassrooms.map((c) => c.value);
      const roles = selectedRoles.map((r) => r.value);

      try {
        setIsSubmitting(true);
        await communicationService
          .sendCommunication({
            isCritical,
            locationIds,
            classroomIds,
            roles,
            subject,
            content,
          });
      } finally {
        setIsSubmitting(false);
      }

        
    } else {
      if (!subject) {
        toast.error('Please enter a subject for this communication.');
      }

      if (!content) {
        toast.error('Please enter some content for this communication');
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedLocations.length > 0) {
        const locationIds = selectedLocations.map((c) => c.value);

        await classroomService
          .findByLocation(locationIds)
          .then((response) => { setClassrooms(response.model); });
      }
    }
    fetchData();
  }, [selectedLocations]);

  useEffect(() => {
    const locationOptions = [];
    for (let i = 0; i < locations.length; i += 1) {
      locationOptions.push({
        value: locations[i].locationId,
        label: locations[i].name,
      });
    }

    setAvailableLocations(locationOptions);
  }, [locations]);

  useEffect(() => {
    if (selectedLocations.length === 0) {
      setSelectedClassrooms([]);
      setClassrooms([]);

      setSelectedRoles([]);
    }
  }, [selectedLocations]);

  useEffect(() => {
    const classroomOptions = [];
    for (let i = 0; i < classrooms.length; i += 1) {
      const c = classrooms[i];

      classroomOptions.push({
        value: c.classroomId,
        label: c.name + ' (' + c.locationName + ')',
      });
    }

    setAvailableClassrooms(classroomOptions);
  }, [classrooms]);

  const loadLocations = async () => {
    await locationService
      .all(false)
      .then((response) => { setLocations(response.model); });
  };

  useEffect(() => { loadLocations(); }, []);

  return (
    <main className="main" id="top">
      <PageHeader title="New Communication" />
      <Card className="my-3">
        <CardBody className="py-2">
          <Label>Select the group of users who should receive this communication.</Label>
          <Row>
            <Col lg="4">
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <Label for="organizationSelect" className="mb-0">
                    Locations&nbsp;
                    <Required />
                  </Label>
                  <Button size="sm" color="link" className="pr-0" onClick={() => setSelectedLocations(availableLocations)}>Select All</Button>
                </div>
                <Select options={availableLocations} {...bindSelectedLocations} isMulti />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <Label for="organizationSelect" className="mb-0">
                    Classrooms&nbsp;
                    <Required />
                  </Label>
                  <Button size="sm" color="link" className="pr-0" onClick={() => setSelectedClassrooms(availableClassrooms)}>Select All</Button>
                </div>
                <Select
                  options={availableClassrooms}
                  {...bindSelectedClassrooms}
                  isMulti
                  isDisabled={availableClassrooms.length === 0}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <Label for="organizationSelect" className="mb-0">
                    Roles&nbsp;
                    <Required />
                  </Label>
                  <Button size="sm" color="link" className="pr-0" onClick={() => setSelectedRoles(roleOptions)}>Select All</Button>
                </div>
                <Select options={roleOptions} {...bindSelectedRoles} isMulti />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody className="p-0 notes-body">
          <Form id="newMessageForm" onSubmit={onSubmit}>
            <Input className={'px-card ' + (subjectHasError ? '' : 'border-0 outline-none')} placeholder="Subject" {...bindSubject} invalid={subjectHasError} />
            <AdvancedEditor {...bindContent} height={500} className={contentHasError ? 'hasError' : ''} placeholder="Enter some content for this communication..." />
          </Form>
        </CardBody>
        <CardFooter className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Button size="sm" color="primary" className="px-5 mr-2" type="submit" form="newMessageForm" disabled={isSubmitting} >
              
              {isSubmitting && (
                <Spinner size="sm"/> 
              )}

              Send</Button>
            <div className="custom-control custom-switch">
              <input className="custom-control-input" id="criticalSwitch" type="checkbox" {...bindIsCritical} />
              <Label className="custom-control-label" htmlFor="criticalSwitch">
                Send Communication as 
                <b>Critical</b>
              </Label>
            </div>
          </div>
        </CardFooter>
      </Card>
    </main>
  );
}
