import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckPermission } from '../../../components/CanUser';
import CardHeaderless from '../../../components/CardHeaderless';
import ExtendedTable from '../../../components/ExtendedTable';
import PageHeader from '../../../components/PageHeader';
import Spinner from '../../../components/Spinner';

import { UserContext } from '../../../context/userContext';
import locationService from '../../../services/locationService';

export default function LocationPage() {
  document.title = 'Locations | Today\'s Family Fridge';

  const history = useHistory();
  const [pageTitle, setPageTitle] = useState('Locations');

  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  const userState = useContext(UserContext);

  const tableClickEvent = (e, r) => { history.push('/locations/edit/' + r.locationId); };
  const tableColumns = [
    { text: 'Name', dataField: 'name', sort: true },
    { text: 'Address', dataField: 'fullAddress', sort: true },
    { text: 'Classrooms', dataField: 'classroomCount', sort: true },
    { text: 'Organization', dataField: 'organizationName', sort: true },
  ];

  let canView = true;
  if (!CheckPermission(userState.user.role, 'locations:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  const loadLocations = async () => {
    if (canView) {
      setPageTitle(isShowingArchived ? 'Archived Locations' : 'Locations');

      await locationService
        .all(isShowingArchived)
        .then((r) => setLocations(r.model))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => { loadLocations(); }, [isShowingArchived]);

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <main className="main" id="top">
          <PageHeader
            title={pageTitle}
            userRole={user.role}
            addPermission="locations:create"
            newButtonText="a Location"
            newButtonUrl="/locations/edit"
            isShowingArchived={isShowingArchived}
          />

          {isLoading
            ? <Spinner />
            : (
              <CardHeaderless>
                <ExtendedTable
                  tableClickEvent={tableClickEvent}
                  archiveClicked={() => setIsShowingArchived(!isShowingArchived)}
                  archivePermission="locations:view-archive"
                  isShowingArchived={isShowingArchived}
                  data={locations}
                  userRole={user.role}
                  columns={tableColumns}
                  keyField="locationId"
                  defaultSort="name"
                />
              </CardHeaderless>
            )}
        </main>
      )}
    </UserContext.Consumer>
  );
}
