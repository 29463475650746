import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';

import RequiredField from '../components/RequiredField';
import SpinnerButton from '../components/SpinnerButton';
import userService from '../services/userService';

export default function VerifyPasswordForm(props) {
  const history = useHistory();
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('The New Password field is Required.')
      .min(6, 'Your password must be atleast 6 characters in length.')
      .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter.')
      .matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter.')
      .matches(/(?=.*[0-9])/, 'Password must contain at least one digit.')
      .matches(/(?=.*[~!@#$%^&*()])/, 'Password must contain at least one special character.'),

    confirmPassword: Yup.string()
      .required('The Verify Password field is required.')
      .oneOf([Yup.ref('password'), null], 'Passwords must match.'),
  });

  const onSubmit = async (values) => {
    await userService
      .setPassword(props.token, props.email, values.password)
      .then(() => history.push('/password-set'));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(p) => (
        <Form onSubmit={p.handleSubmit}>
          <Row><RequiredField type="password" formKey="password" inputPlaceholder="New Password" showLabel={false} /></Row>
          <Row><RequiredField type="password" formKey="confirmPassword" inputPlaceholder="Confirm Password" showLabel={false} /></Row>

          <SpinnerButton
            isLoading={p.isSubmitting || p.isValidating}
            className="btn btn-primary btn-block mt-3"
            type="submit"
          >
            Set Password
          </SpinnerButton>
        </Form>
      )}
    </Formik>
  );
}
