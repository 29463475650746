import React, { useState, useEffect } from 'react';
import {
  Row, Col, Label, FormGroup, Input, Button,
} from 'reactstrap';

import moment from 'moment';
import Moment from 'react-moment';
import { toast } from 'react-toastify';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

import eventService from '../services/eventService';
import ExtendedModal from '../components/ExtendedModal';
import CardCustom from '../components/CardCustom';
import Required from '../components/Required';
import AdvancedEditor from '../components/AdvancedEditor';
import useInput from '../hooks/useInput';

export default function EditEventForm(props) {
  const { event, canEdit, removeEvent } = props;

  const defaultDate = new Date();
  defaultDate.setHours(0, 0, 0, 0);

  const [titleHasError, setTitleHasError] = useState(false);
  const [descriptionHasError, setDescriptionHasError] = useState(false);
  const [startDateHasError, setStartDateHasError] = useState(false);
  const [endDateHasError, setEndDateHasError] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const { value: title, setValue: setTitle, bind: bindTitle } = useInput(event.title);
  const [startDate, setStartDate] = useState(moment.utc(event.startDate).toDate());
  const [endDate, setEndDate] = useState(moment.utc(event.endDate).toDate());
  const [description, setDescription] = useState(event.description);

  useEffect(() => {
    setTitle(event.title);
    setStartDate(moment.utc(event.startDate).toDate());
    setEndDate(moment.utc(event.endDate).toDate());
    setDescription(event.description);
  }, [isEditing]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (canEdit) {
      setTitleHasError(!title);
      setDescriptionHasError(!description);
      setStartDateHasError(!startDate);
      setEndDateHasError(!endDate);

      if (title && description) {
        const start = moment.utc(startDate).format();
        const end = moment.utc(endDate).format();

        event.title = title;
        event.description = description;
        event.startDate = start;
        event.endDate = end;

        await eventService
          .updateEvent(event)
          .then((r) => {
            if (!r.hasError) {
              setIsEditing(false);
            }
          });
      } else {
        if (!title) {
          toast.error('Please enter a title for your new event.');
        }

        if (!description) {
          toast.error('Please enter a description for your new event.');
        }
      }
    }
  };

  const onDelete = async (e) => {
    e.preventDefault();

    if (canEdit) {
      await eventService
        .deleteEvent(event.eventId)
        .then((r) => {
          if (!r.hasError) {
            removeEvent(event.eventId);
          }
        });
    }
  };

  const header = (
    <div className="media">
      <div className="calendar">
        <span className="calendar-month">
          <Moment format="MMM" utc local>
            {startDate}
          </Moment>
        </span>
        <span className="calendar-day">
          <Moment format="DD" utc local>
            {startDate}
          </Moment>
        </span>
      </div>
      <div className="media-body position-relative pl-3">
        <h6 className="fs-0 mb-0">{title}</h6>
        <p className="mb-0 fs--1">
          Created by
          {event.createdBy}
        </p>
        <p className="text-700 mb-0 fs--1">
          <Moment format="YYYY/MM/DD hh:mm A" utc local>
            {startDate}
          </Moment>
&nbsp;&mdash;&nbsp;
          <Moment format="YYYY/MM/DD hh:mm A" local utc>
            {endDate}
          </Moment>
        </p>
      </div>
    </div>
  );

  const footer = (
    <div className="py-2 text-right">
      {isEditing
        ? (
          <div>
            <Button size="sm" color="secondary" className="px-4 px-sm-3 mr-2" onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button size="sm" color="primary" className="px-4 px-sm-3" onClick={onSubmit}>Save</Button>
          </div>
        )
        : (
          <div>
            <ExtendedModal
              buttonLabel="Archive"
              buttonIcon=""
              buttonClass="danger"
              buttonSizeClass="sm"
              confirmButtonText="Confirm"
              confirmButtonClass="danger"
              onConfirm={onDelete}
            >
              <h6 className="m-0 fs-0">Are you sure that you wish to archive this event?</h6>
            </ExtendedModal>
            <Button color="primary" size="sm" className="px-4 px-sm-3 ml-2" onClick={() => setIsEditing(true)}>Edit</Button>
          </div>
        )}
    </div>
  );

  return (
    <CardCustom header={header} footer={footer} showFooter={canEdit}>
      {!isEditing
        ? (
          <div className="media btn-reveal-trigger">
            <div className="media-body position-relative">
              <span className="text-800" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        )
        : (
          <div>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="title">
                    Title&nbsp;
                    <Required />
                  </Label>
                  <Input name="title" type="text" {...bindTitle} invalid={titleHasError} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="startDate">
                    Start Date&nbsp;
                    <Required />
                  </Label>
                  <Flatpickr
                    className={'form-control w-100 ' + (startDateHasError ? 'hasError' : '')}
                    options={{
                      minDate: defaultDate,
                      dateFormat: 'F J, Y G:i K',
                      static: true,
                    }}
                    data-enable-time
                    value={startDate}
                    onChange={(date) => {
                      const d = date[0];
                      setStartDate(d);

                      // Set the end date to this date if it's less.
                      const end = moment.utc(endDate).toDate();
                      if (!end || end < d) {
                        setEndDate(d);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="endDate">
                    End Date&nbsp;
                    <Required />
                  </Label>
                  <Flatpickr
                    className={'form-control w-100 ' + (endDateHasError ? 'hasError' : '')}
                    options={{
                      minDate: startDate,
                      dateFormat: 'F J, Y G:i K',
                      static: true,
                    }}
                    data-enable-time
                    value={endDate}
                    onChange={(date) => setEndDate(date[0])}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="title">
                    Description&nbsp;
                    <Required />
                  </Label>
                  <AdvancedEditor value={description} onChange={(v) => setDescription(v)} placeholder="Describe what this event is about..." className={descriptionHasError ? 'hasError' : ''} />
                </FormGroup>
              </Col>
            </Row>
          </div>
        )}
    </CardCustom>
  );
}
