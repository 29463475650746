import React, { useState } from 'react';
import Select from 'react-select';
import {
  Form, FormGroup, Label, CustomInput,
} from 'reactstrap';
import AdvancedEditor from '../components/AdvancedEditor';
import CardCollapse from '../components/CardCollapse';
import Required from '../components/Required';
import photoService from '../services/photoService';
import uploadService from '../services/uploadService';

export default function AddPhotoForm(props) {
  const { classroomId, addPhoto, classroomChildren } = props;

  const [fileHasError, setFileHasError] = useState(false);
  const [file, setFile] = useState();

  const [previewImage, setPreviewImage] = useState();

  const [contentHasError, setContentHasError] = useState(false);
  const [content, setContent] = useState('');

  const [childHasError, setChildHasError] = useState();
  const [child, setChild] = useState();

  const childOptions = [];
  for (let i = 0; i < classroomChildren.length; i += 1) {
    const c = classroomChildren[i];

    if (c.hasPhotoPermission) {
      childOptions.push({
        value: c.childId,
        label: c.fullName,
      });
    }
  }

  const fileChanged = (e) => {
    const reader = new FileReader();
    const f = e.target.files[0];

    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };

    reader.readAsDataURL(f);

    setFile(f);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setFileHasError(!file);
    setChildHasError(!child);
    setContentHasError(!content);

    if (file && child && content) {
      await uploadService
        .uploadPhoto(file)
        .then(async (response) => {
          await photoService.createPhoto({
            path: response.model.path,
            classroomId: parseInt(classroomId),
            childId: child.value,
            width: response.model.width,
            height: response.model.height,
            description: content,
          }).then((r) => addPhoto(r.model));
        })
        .finally(() => {
          setFile(null);
          setChild(null);
          setPreviewImage(null);
          setContent('');
        });
    }
  };

  const addFooter = (
    <div className="py-2 text-right">
      <button className="btn btn-primary btn-sm px-4 px-sm-5" type="submit">Upload</button>
    </div>
  );

  return (
    <Form id="addPhotoForm" onSubmit={onSubmit}>
      <CardCollapse title="Add a new Photo" footer={addFooter} showPlusButton>
        <FormGroup>
          <Label for="photo">
            Photo&nbsp;
            <Required />
          </Label>
          <CustomInput
            invalid={fileHasError}
            type="file"
            id="photoUpload"
            name="photoFile"
            onChange={fileChanged}
          />
          <small className="text-muted"><em>It is recommended that images be no larger than 1920x1080.</em></small>
          {previewImage && <img src={previewImage} className="w-100 mt-2" alt="preview" />}
        </FormGroup>
        <FormGroup>
          <Label>
            Child&nbsp;
            <Required />
          </Label>
          <Select className={childHasError ? 'tf-select hasError' : 'tf-select'} options={childOptions} value={child} onChange={(v) => setChild(v)} />
          <small className="text-muted">
            <em>
              Please note, a parent/guardian
              <b>MUST</b>
              &nbsp;
              have indicated that they wish to have photos of their child posted.
              If you do not see a childs name, the parent/guardian has not given this permission.
            </em>
          </small>
        </FormGroup>
        <FormGroup>
          <Label for="title">
            Description&nbsp;
            <Required />
          </Label>
          <AdvancedEditor value={content} onChange={(v) => setContent(v)} placeholder="Enter a description of this photo here..." className={contentHasError ? 'hasError' : ''} />
        </FormGroup>
      </CardCollapse>
    </Form>
  );
}
