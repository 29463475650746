import React from 'react';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';

import authenticationService from '../services/authenticationService';
import RequiredField from '../components/RequiredField';
import SpinnerButton from '../components/SpinnerButton';

export default function ForgotPasswordForm(props) {
  const { setSuccess } = props;

  const initialValues = { email: '' };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('The Email Address field is Required.'),
  });

  const onSubmit = async (values) => {
    await authenticationService
      .forgotPassword(values.email)
      .then((response) => { setSuccess(response.ok); });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(p) => (
        <Form onSubmit={p.handleSubmit}>
          <Row>
            <RequiredField
              formKey="email"
              inputPlaceholder="Email Address"
              showLabel={false}
            />
          </Row>

          <SpinnerButton
            isLoading={p.isSubmitting || p.isValidating}
            className="btn btn-primary btn-block mt-3"
            type="submit"
          >
            Send reset link
          </SpinnerButton>
        </Form>
      )}
    </Formik>
  );
}
