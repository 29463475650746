import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import {
  FormGroup, Button, Form, Collapse, Label,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Formik } from 'formik';
import * as Yup from 'yup';
import useSelect from '../hooks/useSelect';
import userService from '../services/userService';
import Required from '../components/Required';
import FormModal from '../components/FormModal';
import RequiredField from '../components/RequiredField';

export default function AddCaregiverForm(props) {
  const {
    action,
    canCreate,
    addCreatedCaregiver,
    caregivers,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { value: selectedCaregivers, bind: bindCaregivers, setValue: setCaregivers } = useSelect();

  const options = caregivers.map((c) => {
    const name = c.firstName + ' ' + c.lastName;
    return { value: c.id, label: name };
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'First Name must not exceed 100 characters.')
      .required('The First Name field is required.'),

    lastName: Yup.string()
      .max(100, 'Last Name must not exceed 100 characters.')
      .required('The Last Name field is required.'),

    emailAddress: Yup.string()
      .email('Please enter a valid Email Address.')
      .required('The Email Address field is required.'),
  });

  const onFormSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    const obj = {
      username: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailAddress,
      role: 'Caregiver',
    };

    await userService
      .addUser(obj)
      .then(async (r) => {
        if (r.hasError) {
          toast.error(r.errorMessage);
        } else {
          addCreatedCaregiver(r.model);
          resetForm(initialValues);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCaregivers) {
      const ids = selectedCaregivers.map((c) => c.value);
      action(ids);

      // Clear the dropdown.
      setCaregivers([]);
    }
  };

  return (
    <div>
      <a
        className="mb-3 d-block d-flex align-items-center collapsed addRow"
        href="#caregiver-form"
        data-toggle="collapse"
        aria-expanded="false"
        aria-controls="child-form"
      >
        <span className="circle-dashed"><FontAwesomeIcon icon="plus" /></span>
        <h5 className="ml-3 mb-0 text-primary">Add Caregiver/Provider</h5>
      </a>
      <Collapse id="caregiver-form">
        <em className="text-muted">Caregivers/Providers have the ability to manage classroom details including notes, events, menus and uploading photos.</em>
        <Form onSubmit={handleSubmit} className="mt-2">
          <FormGroup className="mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <Label className="mb-0">
                Choose Caregivers/Providers&nbsp;
                <Required />
              </Label>
              {canCreate && (
                <FormModal
                  className="btn btn-link btn-sm pr-0"
                  buttonLabel="Add New"
                  isSubmitting={isSubmitting}
                  formName="userDetailForm"
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={onFormSubmit}
                  >
                    {(p) => (
                      <Form id="userDetailForm" onSubmit={p.handleSubmit}>
                        <RequiredField formKey="firstName" labelName="First Name" inputPlaceholder="First Name" />
                        <RequiredField formKey="lastName" labelName="Last Name" inputPlaceholder="Last Name" />
                        <RequiredField formKey="emailAddress" labelName="Email Address" inputPlaceholder="Email Address" />
                      </Form>
                    )}
                  </Formik>
                </FormModal>
              )}
            </div>
            <Select options={options} {...bindCaregivers} className="w-100" size="sm" isMulti closeMenuOnSelect={false} />
          </FormGroup>
          <Button className="btn-falcon-primary mr-2" size="sm" type="submit">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            Add to Classroom
          </Button>
        </Form>
        <hr className="border-dashed border-bottom-0" />
      </Collapse>
    </div>
  );
}
