import React from 'react';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';
import attendanceService from '../services/attendanceService';
import RequiredField from '../components/RequiredField';

export default function ExportAttendanceForm(props) {
  const { organizationId, locationId, classroomId } = props;

  const initialValues = { startDate: '', endDate: '' };
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required('Start Date is Required.'),
    endDate: Yup.string().required('End Date is Required.'),
  });

  const onSubmit = async (values) => {
    const obj = {
      startDate: values.startDate,
      endDate: values.endDate,
      organizationId,
      locationId,
      classroomId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    await attendanceService
      .exportAsync(obj)
      .then((r) => r.blob())
      .then((blob) => {
        const b = new Blob([blob], { type: 'application/vnd.openxmlformats' });
        const anchor = document.createElement('a');

        anchor.download = values.startDate + '-' + values.endDate + '.xlsx';
        anchor.href = (window.webkitURL || window.URL).createObjectURL(b);
        anchor.dataset.downloadurl = ['application/vnd.openxmlformats', anchor.download, anchor.href].join(':');
        anchor.click();
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(p) => (
        <Form onSubmit={p.handleSubmit} id="exportForm">
          <Row>
            <RequiredField
              type="date"
              formKey="startDate"
              inputPlaceholder="Select Start Date"
              labelName="Start Date"
              showLabel
              isRequired
              isDate
            />
          </Row>
          <Row>
            <RequiredField
              type="date"
              formKey="endDate"
              inputPlaceholder="Select End Date"
              labelName="End Date"
              showLabel
              isRequired
              isDate
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
}
