import React, {
  useState, useEffect, useRef, useContext,
} from 'react';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { CanUser, CheckPermission } from '../../../components/CanUser';
import { UserContext } from '../../../context/userContext';

import CardCollapse from '../../../components/CardCollapse';
import CardCustom from '../../../components/CardCustom';
import CardDeactivate from '../../../components/CardDeactivate';
import CardForm from '../../../components/CardForm';
import ListItemChildLarge from '../../../components/ListItemChildLarge';
import ListItemFamilyLarge from '../../../components/ListItemFamilyLarge';
import ListItemLocation from '../../../components/ListItemLocation';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';
import EditOrganizationForm from '../../../forms/EditOrganizationForm';
import ExportAttendanceForm from '../../../forms/ExportAttendanceForm';
import organizationService from '../../../services/organizationService';

export default function EditOrganizationPage(props) {
  document.title = "Edit Organization | Today's Family Fridge";

  const { match } = props;
  const { id } = match.params;

  const history = useHistory();
  const didMount = useRef(false);

  const userState = useContext(UserContext);
  const canEdit = CheckPermission(
    userState.user.role,
    'organizations:edit',
    '',
  );
  const canArchive = CheckPermission(
    userState.user.role,
    'organizations:archive',
    '',
  );
  const canExport = CheckPermission(
    userState.user.role,
    'classrooms:export-attendance',
  );

  let canView = true;
  if (
    !CheckPermission(userState.user.role, 'organizations:visit')
    && typeof userState.user.role !== 'undefined'
  ) {
    canView = false;
    history.push('/error/404');
  }

  const [organization, setOrganization] = useState({});

  const [isNew, setIsNew] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadOrganization = async () => {
    if (typeof id !== 'undefined' && canView) {
      await organizationService
        .find(id)
        .then((r) => setOrganization(r.model))
        .finally(() => {
          didMount.current = true;
        });
    } else {
      setOrganization({});
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOrganization();
  }, [id]);
  useEffect(() => {
    if (didMount.current) {
      setIsLoading(false);
      setIsNew(typeof organization.organizationId === 'undefined');
    }
  }, [organization]);

  const handleArchiveClick = async (e) => {
    e.preventDefault();

    if (organization.status === 0) {
      await organizationService
        .restore(id)
        .then(() => setOrganization({ ...organization, status: 1 }));
    } else {
      await organizationService
        .archive(id)
        .then(() => history.push('/organizations'));
    }
  };

  if (canView) {
    return (
      <main className="main" id="top">
        <PageHeaderActions
          isSubmitting={isSubmitting}
          userRole={userState.user.role}
          requiredPermission="organizations:edit"
          formName="organizationDetailsForm"
          title={isNew ? 'New Organization' : 'Organization'}
          subTitle={isNew ? '' : organization.name}
          isNew={isNew}
        />

        {isLoading ? (
          <Spinner />
        ) : (
          <Row>
            <Col
              lg={isNew || !canArchive ? '12' : '8'}
              className="pr-lg-2 mb-3"
            >
              <CardForm type="Organization" canEdit={canEdit}>
                <EditOrganizationForm
                  organization={organization}
                  setSubmitting={(l) => setIsSubmitting(l)}
                />
              </CardForm>

              {!isNew && organization.families && (
                <CanUser
                  role={userState.user.role}
                  perform="families:visit"
                  yes={() => (
                    <CardCollapse title="Families" isShowing={false}>
                      <Row>
                        {organization.families.length > 0 ? (
                          organization.families.map((family) => (
                            <ListItemFamilyLarge
                              family={family}
                              key={family.familyId}
                            />
                          ))
                        ) : (
                          <h5 className="text-700 w-100 text-center mb-0">
                            There are no families associated with this
                            organization.
                          </h5>
                        )}
                      </Row>
                    </CardCollapse>
                  )}
                />
              )}

              {!isNew && organization.children && (
                <CanUser
                  role={userState.user.role}
                  perform="children:visit"
                  yes={() => (
                    <CardCollapse title="Children" isShowing={false}>
                      <Row className="text-center fs--1" noGutters>
                        {organization.children.length > 0 ? (
                          organization.children.map((child) => (
                            <ListItemChildLarge
                              child={child}
                              key={child.childId}
                            />
                          ))
                        ) : (
                          <h5 className="text-700 w-100 mb-0">
                            There are no children associated with this
                            organization.
                          </h5>
                        )}
                      </Row>
                    </CardCollapse>
                  )}
                />
              )}
            </Col>

            {!isNew && (
              <Col lg="4" className="pl-lg-2 mb-3">
                <div className="sticky-top sticky-sidebar">
                  {organization.locations && (
                    <CanUser
                      role={userState.user.role}
                      perform="locations:visit"
                      yes={() => (
                        <CardCustom
                          title="Locations"
                          addButtonLink="/locations/edit"
                          addButtonPermission
                          showExport={canExport}
                          exportForm={(
                            <ExportAttendanceForm
                              organizationId={organization.organizationId}
                            />
                          )}
                        >
                          {organization.locations.length > 0 ? (
                            organization.locations.map((location) => (
                              <ListItemLocation
                                location={location}
                                key={'location_' + location.locationId}
                              />
                            ))
                          ) : (
                            <h6 className="text-700 w-100 text-center mb-0">
                              There are no locations associated with this
                              organization.
                            </h6>
                          )}
                        </CardCustom>
                      )}
                    />
                  )}
                  {canArchive && (
                    <CardDeactivate
                      label="Organization"
                      isArchived={organization.status === 0}
                      handleArchiveClick={handleArchiveClick}
                    />
                  )}
                </div>
              </Col>
            )}
          </Row>
        )}
      </main>
    );
  }
  return <div />;
}
