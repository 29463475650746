import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Photo`;

async function createPhoto(photo) {
  return authenticatedRequest('POST', URL, JSON.stringify(photo));
}

async function deletePhoto(id) {
  return authenticatedRequest('DELETE', URL + '/' + id);
}

async function photosForChild(id) {
  return authenticatedRequest('GET', URL + '/Child/' + id);
}

async function photosForClassroom(id) {
  return authenticatedRequest('GET', URL + '/Classroom' + id);
}

async function getPhoto(id) {
  return authenticatedRequest('GET', URL + '/' + id);
}

async function updatePhoto(id, photo) {
  return authenticatedRequest('PUT', URL + '/' + id, JSON.stringify(photo));
}

const photoService = {
  createPhoto,
  deletePhoto,
  photosForChild,
  photosForClassroom,
  getPhoto,
  updatePhoto,
};

export default photoService;
