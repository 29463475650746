import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ExtendedModal(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    buttonLabel,
    children,
    buttonIcon = 'plus',
    buttonClass = 'primary',
    buttonSizeClass,
    buttonId,
    buttonClassName = '',
    confirmButtonText = 'Save',
    confirmButtonClass = 'primary',
    modalSize = 'md',
    onConfirm,
    iconOnly = false,
    hasError = false,
    formName = '',
  } = props;

  const confirmClicked = (e) => {
    onConfirm(e);

    // Prevent closing if there's an error.
    // Handle error in passed onConfirm function.
    if (!hasError) {
      document.querySelector('body').classList.remove('modal-open');

      toggle();
    }
  };

  return (
    <>
      {iconOnly && (
        <Button
          id={buttonId}
          color={buttonClass}
          onClick={toggle}
          className={buttonClassName}
          size={buttonSizeClass}
        >
            {buttonIcon !== '' && <FontAwesomeIcon icon={buttonIcon} transform="shrink-3" />}
        </Button>
      )}

      {!iconOnly && (
        <Button
          id={buttonId}
          onClick={toggle}
          color={buttonClass}
          className={buttonClassName}
          size={buttonSizeClass}
        >
            {buttonIcon !== '' && <FontAwesomeIcon icon={buttonIcon} transform="shrink-3" className="fa-align-left mr-2" />}
            {buttonLabel}
        </Button>
      )}

      <Modal isOpen={modal} toggle={toggle} size={modalSize}>
        <ModalHeader toggle={toggle}>{buttonLabel}</ModalHeader>
        <ModalBody className="bg-light">
          {children}
        </ModalBody>
        <ModalFooter className="py-2">
          <Button color="secondary" size="sm" onClick={toggle}>Cancel</Button>
          &nbsp;
          {formName
            ? <Button color={confirmButtonClass} type="submit" form={formName} size="sm">{confirmButtonText}</Button>
            : <Button color={confirmButtonClass} size="sm" onClick={confirmClicked}>{confirmButtonText}</Button>}
        </ModalFooter>
      </Modal>
    </>
  );
}
