import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../context/userContext';
import dashboardService from '../../services/dashboardService';
import CardHeaderless from '../../components/CardHeaderless';
import ExtendedTable from '../../components/ExtendedTable';
import exportService from '../../services/exportService';
import { CanUser } from '../../components/CanUser';

export default function DashboardPage() {
  const userState = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  document.title = 'Dashboard | Today\'s Family Fridge';
  const toggle = async () => {
    setIsLoading(true);
    await exportService
      .exportAsync()
      .then((r) => r.blob())
      .then((blob) => {
        const b = new Blob([blob], { type: 'application/vnd.openxmlformats' });
        const anchor = document.createElement('a');

        anchor.download = `export_${(new Date().toJSON().slice(0,10))}.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(b);
        anchor.dataset.downloadurl = ['application/vnd.openxmlformats', anchor.download, anchor.href].join(':');
        anchor.click();
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const history = useHistory();
  const [dashboard, setDashboard] = useState({
    latestLocations: [],
  });

  const tableColumns = [
    { text: 'Name', dataField: 'name', sort: true },
    { text: 'Address', dataField: 'fullAddress', sort: true },
    { text: 'Classrooms', dataField: 'classroomCount', sort: true },
    { text: 'Organization', dataField: 'organizationName', sort: true },
    { text: 'Date Created', dataField: 'createdOn', sort: true },
  ];

  const tableClickEvent = (_e, r) => { history.push('/locations/edit/' + r.locationId); };
  const loadDashboard = async () => {
    await dashboardService
      .loadDashboard()
      .then((response) => { setDashboard(response.model); });
  };

  useEffect(() => { loadDashboard(); }, []);

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <main className="main" id="top">
          <CardHeaderless className="rounded-soft bg-gradient d-none d-sm-block">
            <h2 className="mb-0 text-white">
              Welcome,
              &nbsp;
              {user.firstName}
            </h2>
          </CardHeaderless>
          <Row>
            <Col lg="4">
              <CardHeaderless>
                <h6 className="text-dark">Messages Sent</h6>
                <div className="display-4 fs-4 mb-0 font-weight-normal text-sans-serif text-warning">{dashboard.messagesSent}</div>
              </CardHeaderless>
            </Col>
            <Col lg="4">
              <CardHeaderless>
                <h6 className="text-dark">Photos Uploaded</h6>
                <div className="display-4 fs-4 mb-0 font-weight-normal text-sans-serif text-info">{dashboard.photosUploaded}</div>
              </CardHeaderless>
            </Col>
            <Col lg="4">
              <CardHeaderless>
                <h6 className="text-dark">Events Created</h6>
                <div className="display-4 fs-4 mb-0 font-weight-normal text-sans-serif">{dashboard.eventsCreated}</div>
              </CardHeaderless>
            </Col>
          </Row>
          <CanUser
            role={userState.user.role}
            perform="dashboard:download"
            yes={() => (
              <Button onClick={toggle} disabled={isLoading}>Download app data</Button>
            )}
          />
          <CardHeaderless>
            <h4 className="text-dark">Recently Added Locations</h4>
            <ExtendedTable
              columns={tableColumns}
              data={dashboard.latestLocations}
              tableClickEvent={tableClickEvent}
              keyField="locationId"
              defaultSort="name"
            />
          </CardHeaderless>
        </main>
      )}
    </UserContext.Consumer>
  );
}
