import React from 'react';
import Moment from 'react-moment';

export default function SingleEvent(props) {
  const { event } = props;

  return (
    <div className="media btn-reveal-trigger mb-3">
      <div className="calendar">
        <span className="calendar-month">
          <Moment format="MMM" utc local>
            {event.startDate}
          </Moment>
        </span>
        <span className="calendar-day">
          <Moment format="DD" utc local>
            {event.startDate}
          </Moment>
        </span>
      </div>
      <div className="media-body position-relative pl-3">
        <h6 className="fs-0 mb-0">{event.title}</h6>
        <p className="mb-0 fs--1">
          Created by
          {event.createdBy}
        </p>
        <p className="text-700 mb-2 fs--1">
          <Moment format="YYYY/MM/DD hh:mm A" utc local>
            {event.startDate}
          </Moment>
          &nbsp;&mdash;&nbsp;
          <Moment format="YYYY/MM/DD hh:mm A" local utc>
            {event.endDate}
          </Moment>
        </p>
        <span className="text-800" dangerouslySetInnerHTML={{ __html: event.description }} />
      </div>
    </div>
  );
}
