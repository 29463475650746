import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import NavMenu from './Nav/NavMenu';
import SearchNav from './Nav/SearchNav';

export function DashboardLayout(props) {
  const { children } = props;

  return (
    <Container fluid>
      <NavMenu />
      <div className="content">
        <SearchNav />
        {children}
      </div>
    </Container>
  );
}

const DashboardLayoutRoute = ({ component, ...rest }) => {
  const Component = component;

  return (
    <Route
      {...rest}
      render={(props) => (sessionStorage.getItem('jwt') ? (
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout>
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      ))}
    />
  );
};

export default DashboardLayoutRoute;
