import React from 'react';
import { Link } from 'react-router-dom';
import { Media, Row, Container } from 'reactstrap';

export default function SingleChild(props) {
  const { child, removeChild, canEdit } = props;

  const removeClicked = async (e) => {
    e.preventDefault();

    if (canEdit) {
      removeChild(child.childId);
    }
  };

  return (
    <Media className="align-items-center mb-3">
      <div className="avatar avatar-55xl">
        <img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={child.photo} alt={child.fullName} />
      </div>
      <div className="media-body ml-2">
        <h6 className="mb-0">
          <Container>
            <Row><Link to={'/children/edit/' + child.childId} className="fs-0">{child.fullName}</Link></Row>
            <Row><span className="text-700">{child.familyName}</span></Row>
            {canEdit && <Row><a href="#!" onClick={removeClicked} className="btn btn-outline-danger rounded-capsule btn-sm mt-2 py-0">Remove</a></Row>}
          </Container>
        </h6>
      </div>
    </Media>
  );
}
