import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Alert,
} from 'reactstrap';

import CardHeaderless from '../../components/CardHeaderless';
import ForgotPasswordForm from '../../forms/ForgotPasswordForm';

// Images
import Logo from '../../resources/images/tff-logo.png';

export default function ForgotPasswordPage() {
  document.title = 'Forgot Password | Today\'s Family Fridge';

  const [success, setSuccess] = useState(false);

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6 text-center">
          <Col sm="10" md="8" lg="6" xl="5">
            <img className="mb-4" src={Logo} alt="" width="300" />
            {success && (
              <Alert color="success" className="text-left">
                <h5 className="alert-heading font-weight-semi-bold">Success</h5>
                If an account with the entered email was found we
                &nbsp;
                will send you further instructions on how to reset your password.
              </Alert>
            )}
            <CardHeaderless className="p-4 p-sm-5">
              <h4 className="mb-1">Forgot your password?</h4>
              <p>
                Enter your email and we&#39;ll send
                <br className="d-xxl-none" />
                you a reset link.
              </p>
              <ForgotPasswordForm setSuccess={setSuccess} />
              <div className="text-center">
                <Link className="fs--1 text-600" to="/login">
                  Back to Login
                  <span className="d-inline-block ml-1">&rarr;</span>
                </Link>
              </div>
            </CardHeaderless>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
