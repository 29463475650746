import { useState } from 'react';

const useSelect = (initialValue) => {
  const [value = initialValue || '', setValue] = useState();

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event) => {
        setValue(event);
      },
    },
  };
};

export default useSelect;
