import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Family`;

async function getFamilies(showArchived) {
  let endpoint = URL;

  if (showArchived) {
    endpoint += '/Archived';
  }

  return authenticatedRequest('GET', endpoint);
}

async function findFamily(id) {
  return authenticatedRequest('GET', `${URL}/${id}`);
}

async function findFamiliesForChild(childId) {
  return authenticatedRequest('GET', URL + '/Child/' + childId);
}

async function updateFamily(family) {
  return authenticatedRequest('PUT', `${URL}/${family.familyId}`, JSON.stringify(family));
}

async function deleteFamily(id) {
  return authenticatedRequest('DELETE', `${URL}/${id}`);
}

async function restoreFamily(id) {
  return authenticatedRequest('POST', URL + '/Restore/' + id);
}

async function addFamily(family) {
  return authenticatedRequest('POST', URL, JSON.stringify(family));
}

async function updateChildFamilyRelationships(id, familyIds) {
  return authenticatedRequest('POST', URL + '/ChildFamilies', JSON.stringify({
    childId: parseInt(id),
    familyIds,
  }));
}

async function updateFamilyChildrenRelationships(id, childIds) {
  return authenticatedRequest('POST', URL + '/FamilyChildren', JSON.stringify({
    familyId: parseInt(id),
    childIds,
  }));
}

async function updateFamilyGuardianRelationships(id, guardianIds) {
  return authenticatedRequest('POST', URL + '/FamilyGuardians', JSON.stringify({
    familyId: parseInt(id),
    guardianIds,
  }));
}

const familyService = {
  getFamilies,
  findFamily,
  findFamiliesForChild,
  updateFamily,
  updateChildFamilyRelationships,
  updateFamilyChildrenRelationships,
  updateFamilyGuardianRelationships,
  deleteFamily,
  restoreFamily,
  addFamily,
};

export default familyService;
