import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';
import familyService from '../services/familyService';
import RequiredField from '../components/RequiredField';

export default function EditFamilyForm(props) {
  const {
    family,
    canEdit,
    guardians,
    familyChildren,
    setWasCreated,
    setIsSubmitting,
  } = props;

  const initialValues = {
    name: typeof family.familyId !== 'undefined' ? family.name : '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, 'Name must not exceed 100 characters.')
      .required('The Name field is Required.'),
  });

  const history = useHistory();
  const onSubmit = async (values) => {
    setIsSubmitting(true);

    if (typeof family.familyId === 'undefined') {
      await familyService
        .addFamily({
          name: values.name,
          notes: family.notes,
          adminNotes: family.adminNotes,
        })
        .then(async (r) => {
          const userIds = guardians.map((c) => c.id);
          await familyService.updateFamilyGuardianRelationships(r.model.familyId, userIds);

          const childIds = familyChildren.map((c) => c.childId);
          await familyService.updateFamilyChildrenRelationships(r.model.familyId, childIds);

          history.replace('/families/edit/' + r.model.familyId);
          setWasCreated(true);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      family.name = values.name;

      await familyService
        .updateFamily(family)
        .then(async (r) => {
          const userIds = guardians.map((c) => c.id);
          await familyService.updateFamilyGuardianRelationships(family.familyId, userIds);

          const childIds = familyChildren.map((c) => c.childId);
          await familyService.updateFamilyChildrenRelationships(family.familyId, childIds);

          if (!r.hasError) {
            history.push('/families');
          }
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(p) => (
        <Form id="familyDetailForm" onSubmit={p.handleSubmit}>
          <Row>
            <RequiredField labelName="Name" formKey="name" inputPlaceholder="Family Name" isDisabled={!canEdit} />
          </Row>
        </Form>
      )}
    </Formik>
  );
}
