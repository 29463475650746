import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  FormGroup, Label, Input, Row, Col, Button,
} from 'reactstrap';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

import moment from 'moment';

import eventService from '../services/eventService';
import CardCollapse from '../components/CardCollapse';
import Required from '../components/Required';
import AdvancedEditor from '../components/AdvancedEditor';
import useInput from '../hooks/useInput';

export default function AddEventForm(props) {
  const { classroomId, addEvent } = props;

  const defaultDate = new Date();
  defaultDate.setHours(0, 0, 0, 0);

  const [titleHasError, setTitleHasError] = useState(false);
  const [descriptionHasError, setDescriptionHasError] = useState(false);
  const [startDateHasError, setStartDateHasError] = useState(false);
  const [endDateHasError, setEndDateHasError] = useState(false);

  const { value: title, setValue: setTitle, bind: bindTitle } = useInput();
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);
  const [description, setDescription] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();

    setTitleHasError(!title);
    setDescriptionHasError(!description);
    setStartDateHasError(!startDate);
    setEndDateHasError(!endDate);

    if (title && description) {
      const start = moment.utc(startDate).format();
      const end = moment.utc(endDate).format();

      await eventService
        .addEvent(
          {
            classroomId: parseInt(classroomId),
            startDate: start,
            endDate: end,
            title,
            description,
          },
        )
        .then((r) => {
          setTitle('');
          setDescription('');
          setStartDate(defaultDate);
          setEndDate(defaultDate);

          addEvent(r.model);
        });
    } else {
      if (!title) {
        toast.error('Please enter a title for your new event.');
      }

      if (!description) {
        toast.error('Please enter a description for your new event.');
      }
    }
  };

  const addFooter = (
    <div className="py-2 text-right">
      <Button color="primary" size="sm" className="px-4 px-sm-5" onClick={onSubmit}>Create</Button>
    </div>
  );

  return (
    <CardCollapse bodyClass="p-0" title="Add a new Event" footer={addFooter} showPlusButton>
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label for="title">
              Title&nbsp;
              <Required />
            </Label>
            <Input name="title" type="text" {...bindTitle} invalid={titleHasError} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label for="startDate">
              Start Date&nbsp;
              <Required />
            </Label>
            <Flatpickr
              className={'form-control w-100 ' + (startDateHasError ? 'hasError' : '')}
              options={{
                minDate: defaultDate,
                dateFormat: 'F J, Y G:i K',
                static: true,
              }}
              data-enable-time
              value={startDate}
              onChange={(date) => {
                const d = date[0];
                setStartDate(d);

                // Set the end date to this date if it's less.
                const end = moment.utc(endDate).toDate();
                if (!end || end < d) {
                  setEndDate(d);
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label for="endDate">
              End Date&nbsp;
              <Required />
            </Label>
            <Flatpickr
              className={'form-control w-100 ' + (endDateHasError ? 'hasError' : '')}
              options={{
                minDate: startDate,
                dateFormat: 'F J, Y G:i K',
                static: true,
              }}
              data-enable-time
              value={endDate}
              onChange={(date) => setEndDate(date[0])}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label for="title">
              Description&nbsp;
              <Required />
            </Label>
            <AdvancedEditor value={description} onChange={(v) => setDescription(v)} placeholder="Describe what this event is about..." className={descriptionHasError ? 'hasError' : ''} />
          </FormGroup>
        </Col>
      </Row>
    </CardCollapse>
  );
}
