import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Button, FormGroup, Label } from 'reactstrap';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';

import 'flatpickr/dist/flatpickr.min.css';
import menuService from '../services/menuService';
import CardCollapse from '../components/CardCollapse';
import Required from '../components/Required';
import AdvancedEditor from '../components/AdvancedEditor';

export default function AddMenuForm(props) {
  const { classroomId, addMenu, pastMenus } = props;

  const defaultDate = new Date();
  defaultDate.setHours(0, 0, 0, 0);

  const [cloneOption, setCloneOption] = useState();
  const [dateHasError, setDateHasError] = useState(false);
  const [morningSnackHasError, setMorningSnackHasError] = useState(false);
  const [lunchHasError, setLunchHasError] = useState(false);
  const [afternoonSnackHasError, setAfternoonSnackHasError] = useState(false);

  const [date, setDate] = useState(defaultDate);
  const [morningSnack, setMorningSnack] = useState('');
  const [lunch, setLunch] = useState('');
  const [afternoonSnack, setAfternoonSnack] = useState('');

  const pastMenuOptions = pastMenus.map((m) => ({ value: m.menuId, label: moment.utc(m.date).format('dddd, MMMM DD, YYYY') }));

  useEffect(() => {
    if (typeof cloneOption !== 'undefined' && cloneOption) {
      const menuToClone = pastMenus.find((m) => m.menuId === cloneOption.value);

      setMorningSnack(menuToClone.morningSnack);
      setLunch(menuToClone.lunch);
      setAfternoonSnack(menuToClone.afternoonSnack);
    }
  }, [cloneOption]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setDateHasError(!date);
    setMorningSnackHasError(!morningSnack);
    setLunchHasError(!lunch);
    setAfternoonSnackHasError(!afternoonSnack);

    if (morningSnack && lunch && afternoonSnack) {
      await menuService
        .addMenu({
          classroomId: parseInt(classroomId),
          date: moment.utc(date).format(),
          morningSnack,
          lunch,
          afternoonSnack,
        })
        .then((r) => {
          if (!r.hasError) {
            setCloneOption(null);
            setDate(defaultDate);
            setMorningSnack('');
            setLunch('');
            setAfternoonSnack('');

            addMenu(r.model);
          }
        });
    } else {
      if (!morningSnack) {
        toast.error('Please enter a morning snack for your new menu.');
      }

      if (!lunch) {
        toast.error('Please enter a lunch for your new menu.');
      }

      if (!afternoonSnack) {
        toast.error('Please enter a afternoon snack for your new menu.');
      }
    }
  };

  const addFooter = (
    <div className="py-2 text-right">
      <Button color="primary" size="sm" className="px-4 px-sm-5" onClick={onSubmit}>Create</Button>
    </div>
  );

  return (
    <CardCollapse bodyClass="p-0" title="Add a new Menu" footer={addFooter} showPlusButton>
      <FormGroup>
        <Label for="date">
          Menu Date&nbsp;
          <Required />
        </Label>
        <Flatpickr
          className={'form-control w-100 ' + (dateHasError ? 'hasError' : '')}
          options={{
            minDate: defaultDate,
            dateFormat: 'F J, Y',
          }}
          value={date}
          onChange={(d) => setDate(d[0])}
        />
      </FormGroup>
      <FormGroup>
        <Label for="date">
          Clone Previous Menu
          <small><em>(optional)</em></small>
        </Label>
        <Select className="tf-select" options={pastMenuOptions} value={cloneOption} onChange={(v) => setCloneOption(v)} />
      </FormGroup>
      <FormGroup>
        <Label for="title">
          Morning Snack&nbsp;
          <Required />
        </Label>
        <AdvancedEditor value={morningSnack} onChange={(v) => setMorningSnack(v)} placeholder="Enter what the morning snack will be..." className={morningSnackHasError ? 'hasError' : ''} height={150} />
      </FormGroup>
      <FormGroup>
        <Label for="title">
          Lunch&nbsp;
          <Required />
        </Label>
        <AdvancedEditor value={lunch} onChange={(v) => setLunch(v)} placeholder="Enter what lunch will be..." className={lunchHasError ? 'hasError' : ''} height={150} />
      </FormGroup>
      <FormGroup>
        <Label for="title">
          Afternoon Snack&nbsp;
          <Required />
        </Label>
        <AdvancedEditor value={afternoonSnack} onChange={(v) => setAfternoonSnack(v)} placeholder="Enter what the afternoon snack will be..." className={afternoonSnackHasError ? 'hasError' : ''} height={150} />
      </FormGroup>
    </CardCollapse>
  );
}
