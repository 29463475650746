import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import AdvancedEditor from '../../../components/AdvancedEditor';
import { CanUser, CheckPermission } from '../../../components/CanUser';
import CardCollapse from '../../../components/CardCollapse';
import CardCustom from '../../../components/CardCustom';
import CardDeactivate from '../../../components/CardDeactivate';
import CardForm from '../../../components/CardForm';
import ListItemChild from '../../../components/ListItemChild';
import ListItemGuardian from '../../../components/ListItemGuardian';
import PageHeaderActions from '../../../components/PageHeaderActions';
import Spinner from '../../../components/Spinner';

import { UserContext } from '../../../context/userContext';

import AddChildFamilyForm from '../../../forms/AddChildFamilyForm';
import AddGuardianFamilyForm from '../../../forms/AddGuardianFamilyForm';
import EditFamilyForm from '../../../forms/EditFamilyForm';
import childService from '../../../services/childService';
import classroomService from '../../../services/classroomService';
import familyService from '../../../services/familyService';
import userService from '../../../services/userService';

export default function EditFamilyPage(props) {
  document.title = 'Edit Family | Today\'s Family Fridge';

  const { match } = props;

  const history = useHistory();
  const userState = useContext(UserContext);
  const didMount = useRef(false);

  const { id } = match.params;

  const [family, setFamily] = useState({});

  const [notes, setNotes] = useState('');
  const [adminNotes, setAdminNotes] = useState('');

  // All children
  const [children, setChildren] = useState([]);
  const [familyChildren, setFamilyChildren] = useState([]);

  // All guardians
  const [guardians, setGuardians] = useState([]);
  const [familyGuardians, setFamilyGuardians] = useState([]);

  // Children that aren't already part of this classroom
  const [availableChildren, setAvailableChildren] = useState([]);

  // Guardians that aren't already part of this classroom
  const [availableGuardians, setAvailableGuardians] = useState([]);

  // Classrooms available to the current user for assigning new children to.
  const [availableClassrooms, setAvailableClassrooms] = useState([]);

  const canEdit = CheckPermission(userState.user.role, 'families:edit');
  const canCreate = CheckPermission(userState.user.role, 'families:create');
  const canEditGuardians = CheckPermission(userState.user.role, 'families:edit-guardians');
  const canCreateGuardians = CheckPermission(userState.user.role, 'users:create-guardian');
  const canEditChildren = CheckPermission(userState.user.role, 'families:edit-children');
  const canCreateChildren = CheckPermission(userState.user.role, 'children:create');

  const [wasCreated, setWasCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNew, setIsNew] = useState(true);

  let canView = true;
  if (!CheckPermission(userState.user.role, 'families:visit') && typeof userState.user.role !== 'undefined') {
    canView = false;
    history.push('/error/404');
  }

  useEffect(() => {
    if (didMount.current) {
      if (typeof family.guardians !== 'undefined') {
        setFamilyGuardians(family.guardians);
      }

      if (typeof family.children !== 'undefined') {
        setFamilyChildren(family.children);
      }

      setNotes(family.notes || '');
      setAdminNotes(family.adminNotes || '');

      setIsNew(typeof family.familyId === 'undefined');
      setIsLoading(false);
    }
  }, [family]);

  useEffect(() => {
    if (didMount.current) {
      setAvailableChildren(
        children.filter((c) => !familyChildren.find((fc) => fc.childId === c.childId)),
      );
    }
  }, [children, familyChildren]);

  useEffect(() => {
    if (didMount.current) {
      setAvailableGuardians(guardians.filter((g) => !familyGuardians.find((fg) => fg.id === g.id)));
    }
  }, [guardians, familyGuardians]);

  useEffect(() => {
    if (didMount.current) {
      const f = family;
      f.notes = notes;
      f.adminNotes = adminNotes;

      setFamily(f);
    }
  }, [notes, adminNotes]);

  const loadFamily = async () => {
    if (typeof id !== 'undefined') {
      if (canView) {
        await familyService
          .findFamily(id)
          .then((r) => setFamily(r.model))
          .finally(() => { didMount.current = true; });
      }
    } else {
      setIsLoading(false);
      setFamily({});
      setFamilyChildren([]);
      setFamilyGuardians([]);

      didMount.current = true;
    }
  };

  const loadChildren = async () => {
    await childService
      .all(false)
      .then((r) => {
        setChildren(r.model);

        if (typeof family.children === 'undefined' || family.children.length === 0) {
          setAvailableChildren(r.model);
        }
      });
  };

  const loadGuardians = async () => {
    await userService
      .getGuardians()
      .then((r) => {
        setGuardians(r.model);

        if (typeof family.guardians === 'undefined' || family.guardians.length === 0) {
          setAvailableGuardians(r.model);
        }
      });
  };

  const loadClassrooms = async () => {
    await classroomService
      .all(false)
      .then((r) => {
        setAvailableClassrooms(r.model.map((l) => ({ value: l.classroomId, label: l.name + ' (' + l.locationName + ')' })));
      });
  };

  useEffect(() => {
    if (canView) {
      loadChildren();
      loadGuardians();
      loadClassrooms();
    }
  }, []);

  useEffect(() => { loadFamily(); }, [id]);

  // If the family is currently active.
  const handleArchiveClick = async () => {
    if (family.status === 0) {
      await familyService
        .restoreFamily(id)
        .then(() => setFamily({ ...family, status: 1 }));
    } else {
      await familyService
        .deleteFamily(id)
        .then(() => history.push('/families'));
    }
  };

  // Add a child to the list of children and remove the same child
  // from the available list. Will not reload the entire family.
  const addChild = async (childIds, createdChild) => {
    const temp = [...familyChildren];

    if (typeof createdChild !== 'undefined') {
      temp.push(createdChild);
    } else {
      for (let i = 0; i < childIds.length; i += 1) {
        temp.push(availableChildren.find((c) => c.childId === childIds[i]));
      }
    }

    setFamilyChildren(temp);
  };

  // Remove a child from a family and add it to the available list.
  // Will not reload entire family.
  const removeChild = async (childId) => {
    const temp = [...familyChildren];
    temp.splice(temp.findIndex((c) => c.childId === childId), 1);

    setFamilyChildren(temp);
  };

  // Add a guardian to the list of guardians and remove the same guardian
  // from the available list. Will not reload the entire family.
  const addGuardians = async (guardianIds, createdGuardian) => {
    const temp = [...familyGuardians];

    if (typeof createdGuardian !== 'undefined') {
      temp.push(createdGuardian);
    } else {
      for (let i = 0; i < guardianIds.length; i += 1) {
        temp.push(availableGuardians.find((c) => c.id === guardianIds[i]));
      }
    }

    setFamilyGuardians(temp);
  };

  // Remove a caregiver from a classroom and add it to the available list.
  // Will not reload entire classroom.
  const removeGuardian = async (guardianId) => {
    const temp = [...familyGuardians];
    temp.splice(temp.findIndex((g) => g.id === guardianId), 1);

    setFamilyGuardians(temp);
  };

  return (
    <main className="main" id="top">
      <PageHeaderActions
        title={isNew ? 'Create Family' : 'Family'}
        subTitle={isNew ? '' : family.name}
        isSubmitting={isSubmitting}
        userRole={userState.user.role}
        requiredPermission="families:edit"
        formName="familyDetailForm"
        isNew={isNew}
        createAnotherVisible={wasCreated}
        createAnotherSlug="/families/edit"
      />

      {isLoading
        ? <Spinner />
        : (
          <Row>
            <Col lg="8" className="pr-lg-2 mb-3">
              <CardForm type="Family" canEdit={canEdit}>
                <EditFamilyForm
                  setWasCreated={(s) => setWasCreated(s)}
                  setIsSubmitting={(s) => setIsSubmitting(s)}
                  family={family}
                  familyChildren={familyChildren}
                  guardians={familyGuardians}
                  canEdit={canEdit}
                  canCreate={canCreate}
                />
              </CardForm>
              <CanUser
                role={userState.user.role}
                perform="families:view-notes"
                yes={() => (
                  <CardCollapse title="Notes" subtitle="These notes are visible to everyone" isShowing bodyClass="notes-body">
                    <AdvancedEditor value={notes} onChange={(v) => setNotes(v)} />
                  </CardCollapse>
                )}
              />

              <CanUser
                role={userState.user.role}
                perform="families:view-adminNotes"
                yes={() => (
                  <CardCollapse title="Today's Family Notes" subtitle="These notes are only visible to Today's Family administrators" bodyClass="notes-body">
                    <AdvancedEditor value={adminNotes} onChange={(v) => setAdminNotes(v)} />
                  </CardCollapse>
                )}
              />

            </Col>
            <Col lg="4" className="pl-lg-2 mb-3">
              <div className="sticky-top sticky-sidebar">
                <CardCustom title="Parent(s) &amp; Guardian(s)">
                  {canEditGuardians && (
                    <AddGuardianFamilyForm
                      canCreate={canCreateGuardians}
                      guardians={availableGuardians}
                      addGuardians={addGuardians}
                      addCreatedGuardian={(c) => addGuardians([], c)}
                    />
                  )}
                  {familyGuardians.map((g) => (
                    <ListItemGuardian
                      canEdit={canEditGuardians}
                      key={'lig_' + g.id}
                      guardian={g}
                      removeGuardian={removeGuardian}
                    />
                  ))}
                </CardCustom>
                <CardCustom title="Children">
                  {canEditChildren && (
                  <AddChildFamilyForm
                    canCreate={canCreateChildren}
                    familyChildren={availableChildren}
                    addChild={addChild}
                    classrooms={availableClassrooms}
                    addCreatedChild={(c) => addChild([], c)}
                  />
                  )}

                  {familyChildren.map((child) => (
                    <ListItemChild
                      canEdit={canEditChildren}
                      key={'lic_' + child.childId}
                      child={child}
                      removeChild={removeChild}
                    />
                  ))}
                </CardCustom>

                {!isNew && (
                <CanUser
                  role={userState.user.role}
                  perform="families:archive"
                  yes={() => (
                    <CardDeactivate
                      label="Family"
                      isArchived={family.status === 0}
                      handleArchiveClick={handleArchiveClick}
                    />
                  )}
                />
                )}
              </div>
            </Col>
          </Row>
        )}
    </main>
  );
}
