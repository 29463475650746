import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Nav, Navbar, NavItem, NavbarBrand, Collapse,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../context/userContext';

import Logo from '../../resources/images/tff-logo.png';
import './NavMenu.css';

import { CanUser } from '../../components/CanUser';

export default function NavMenu() {
  const userState = useContext(UserContext);

  return (
    <Navbar expand="xl" light className="navbar-glass navbar-vertical">
      <NavbarBrand href="/dashboard" className="text-left">
        <div className="d-flex align-items-center py-3">
          <img className="m-auto" src={Logo} alt="logo" width="200" />
        </div>
      </NavbarBrand>
      <Collapse navbar id="navbarVerticalCollapse">
        <Nav className="flex-column" navbar>
          <CanUser
            role={userState.user.role}
            perform="dashboard:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/dashboard"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="home" />
                    </span>
                    <span>&nbsp;Home</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="organizations:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/organizations"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="sitemap" />
                    </span>
                    <span>&nbsp;Organizations</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="locations:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/locations"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="building" />
                    </span>
                    <span>&nbsp;Locations</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="classrooms:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/classrooms"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="graduation-cap" />
                    </span>
                    <span>&nbsp;Classrooms</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="families:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/families"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="users" />
                    </span>
                    <span>&nbsp;Families</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="children:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/children"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="child" />
                    </span>
                    <span>&nbsp;Children</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="users:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/users"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="user" />
                    </span>
                    <span>&nbsp;Users</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="messages:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/conversations"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="comment-dots" />
                    </span>
                    <span>&nbsp; Messages</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="communications:read"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/communication"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                    <span>&nbsp;Communications</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />

          <CanUser
            role={userState.user.role}
            perform="communication:visit"
            yes={() => (
              <NavItem>
                <NavLink
                  to="/sendcommunication"
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon text-center">
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                    <span>&nbsp;Send Communication</span>
                  </div>
                </NavLink>
              </NavItem>
            )}
          />
        </Nav>
      </Collapse>
    </Navbar>
  );
}
