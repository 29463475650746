import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import Required from './Required';
import ExtendedModal from './ExtendedModal';

export default function CardCustom(props) {
  const {
    title,
    header,
    footer,
    children,
    showFooter = true,
    bodyClass = '',
    margin = 'mb-3',
    addButtonLink,
    addButtonPermission = false,
    showExport = false,
    exportForm = null,
    showRequired = false,
  } = props;

  return (
    <Card className={margin}>
      <CardHeader className="border-bottom">
        {header || (
          <Row className="align-items-center">
            <Col xs={addButtonLink ? '6' : '12'}>
              <h5 className="h5 m-0">
                {title}
                &nbsp;
                {showRequired && <Required />}
              </h5>
            </Col>

            {addButtonLink && addButtonPermission && (
              <Col xs="6" className="text-right">
                {showExport && exportForm && (
                  <ExtendedModal
                    buttonClassName="btn btn-falcon-primary text-600 btn-sm dropdown-toggle dropdown-caret-none mr-2 shadow-none"
                    buttonIcon="file-export"
                    buttonLabel="Export Attendance"
                    confirmButtonText="Export"
                    confirmButtonClass="success"
                    formName="exportForm"
                    iconOnly
                  >
                    {exportForm}
                  </ExtendedModal>
                )}

                <UncontrolledTooltip placement="left" target="addButton">
                  Add New
                </UncontrolledTooltip>
                <Link
                  to={addButtonLink}
                  className="btn btn-primary rounded-capsule btn-sm"
                  id="addButton"
                >
                  <FontAwesomeIcon icon="plus" transform="shrink-3" />
                </Link>
              </Col>
            )}
          </Row>
        )}
      </CardHeader>
      <CardBody className={bodyClass + ' bg-light'}>{children}</CardBody>
      {footer && showFooter && (
        <div className="card-footer py-0 border-top">{footer}</div>
      )}
    </Card>
  );
}
