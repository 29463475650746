import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Dashboard`;

async function loadDashboard() {
  return authenticatedRequest('GET', URL);
}

const dashboardService = { loadDashboard };

export default dashboardService;
