import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Menu`;

async function classroomMenus(classroomId) {
  return authenticatedRequest('GET', `${URL}/${classroomId}`);
}

async function findMenu(id) {
  return authenticatedRequest('GET', `${URL}/${id}`);
}

async function deleteMenu(id) {
  return authenticatedRequest('DELETE', `${URL}/${id}`);
}

async function updateMenu(menu) {
  return authenticatedRequest('PUT', `${URL}/${menu.menuId}`, JSON.stringify(menu));
}

async function addMenu(menu) {
  return authenticatedRequest('POST', `${URL}`, JSON.stringify(menu));
}

const menuService = {
  classroomMenus,
  findMenu,
  deleteMenu,
  updateMenu,
  addMenu,
};

export default menuService;
