import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Event`;

async function classroomEvents(classroomId) {
  return authenticatedRequest('GET', `${URL}/${classroomId}`);
}

async function findEvent(id) {
  return authenticatedRequest('GET', `${URL}/${id}`);
}

async function deleteEvent(id) {
  return authenticatedRequest('DELETE', `${URL}/${id}`);
}

async function updateEvent(event) {
  return authenticatedRequest('PUT', `${URL}/${event.eventId}`, JSON.stringify(event));
}

async function addEvent(event) {
  return authenticatedRequest('POST', `${URL}`, JSON.stringify(event));
}

const eventService = {
  classroomEvents,
  findEvent,
  deleteEvent,
  updateEvent,
  addEvent,
};

export default eventService;
