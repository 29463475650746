import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Organization`;

async function all(showArchived) {
  let endpoint = URL;

  if (showArchived) {
    endpoint += '/Archived';
  }

  return authenticatedRequest('GET', endpoint);
}

async function find(id) {
  return authenticatedRequest('GET', URL + '/' + id);
}

async function create(o) {
  return authenticatedRequest('POST', URL, JSON.stringify(o));
}

async function update(o) {
  return authenticatedRequest('PUT', URL + '/' + o.organizationId, JSON.stringify(o));
}

async function archive(id) {
  return authenticatedRequest('DELETE', URL + '/' + id);
}

async function restore(id) {
  return authenticatedRequest('POST', URL + '/Restore/' + id);
}

const organizationService = {
  all,
  find,
  create,
  update,
  archive,
  restore,
};

export default organizationService;
