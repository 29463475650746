import React, { useState } from 'react';
import userService from '../services/userService';

export const UserContext = React.createContext({
  user: {},
  setUser: () => { },
});

export const UserContextProvider = (props) => {
  const { children } = props;
  const token = sessionStorage.getItem('jwt');

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    user: {},

    // eslint-disable-next-line no-use-before-define
    setUser,
  });

  function setUser(user) {
    setState({ ...state, user });
  }

  const loadUser = async () => {
    await userService.getCurrentUser()
      .then(
        (response) => {
          setUser(response.model);
          setIsLoading(false);
        },
        () => { },
      );
  };

  if (token !== null && typeof state.user.id === 'undefined' && !isLoading) {
    setIsLoading(true);
    loadUser();
  }

  return (
    <UserContext.Provider value={state}>
      {children}
    </UserContext.Provider>
  );
};
