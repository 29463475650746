const userRules = {
  Guardian: {
    static: [
      'classrooms:visit',
      'classrooms:view-overview',
      'classrooms:view-notes',
      'classrooms:view-events',
      'classrooms:view-menus',
      'classrooms:view-photos',

      'children:visit',
      'children:edit',

      'families:visit',
      'families:edit',
      'families:view-notes',

      'users:visit',
      'users:profile-edit',

      'messages:visit',
      'messages:compose',
      'messages:sendToClassroom',

      'communications:read',
    ],
  },
  Caregiver: {
    static: [
      'classrooms:visit',
      'classrooms:view-overview',
      'classrooms:view-notes',
      'classrooms:edit-notes',
      'classrooms:view-events',
      'classrooms:edit-events',
      'classrooms:view-menus',
      'classrooms:edit-menus',
      'classrooms:view-photos',
      'classrooms:edit-photos',

      'children:visit',

      'families:visit',
      'families:view-notes',

      'users:visit',
      'users:profile-edit',

      'communications:read',

      'messages:visit',
      'messages:compose',
      'messages:sendToFamily',
    ],
  },
  'Site Admin': {
    static: [
      'locations:visit',
      'locations:edit',
      'locations:import-families',
      'locations:import-staff',

      'classrooms:export-attendance',
      'classrooms:view-attendance',
      'classrooms:visit',
      'classrooms:create',
      'classrooms:edit',
      'classrooms:archive',
      'classrooms:view-archive',
      'classrooms:view-overview',
      'classrooms:edit-overview',
      'classrooms:view-notes',
      'classrooms:edit-notes',
      'classrooms:view-events',
      'classrooms:edit-events',
      'classrooms:view-menus',
      'classrooms:edit-menus',
      'classrooms:view-photos',
      'classrooms:edit-photos',
      'classrooms:edit-caregivers',
      'classrooms:edit-children',

      'children:visit',
      'children:create',
      'children:view-archive',
      'children:edit',

      'families:visit',
      'families:create',
      'families:archive',
      'families:view-archive',
      'families:edit',
      'families:edit-guardians',
      'families:edit-children',
      'families:view-notes',

      'users:visit',
      'users:profile-edit',
      'users:create',
      'users:modify-permission',
      'users:create-caregiver',
      'users:create-guardian',
      'users:view-archive',
      'users:edit',
      'users:resendActivation',

      'communications:read',

      'messages:visit',
      'messages:compose',
      'messages:sendToFamily',
    ],
  },
  Admin: {
    static: [
      'dashboard:visit',
      'dashboard:download',

      'organizations:visit',
      'organizations:create',
      'organizations:edit',
      'organizations:archive',
      'organizations:view-archive',

      'locations:visit',
      'locations:create',
      'locations:archive',
      'locations:edit',
      'locations:edit-siteAdmins',
      'locations:view-archive',
      'locations:import-families',
      'locations:import-staff',

      'conversation:search',

      'classrooms:export-attendance',
      'classrooms:view-attendance',
      'classrooms:visit',
      'classrooms:create',
      'classrooms:edit',
      'classrooms:archive',
      'classrooms:view-archive',
      'classrooms:view-overview',
      'classrooms:edit-overview',
      'classrooms:view-notes',
      'classrooms:edit-notes',
      'classrooms:view-events',
      'classrooms:edit-events',
      'classrooms:view-menus',
      'classrooms:edit-menus',
      'classrooms:view-photos',
      'classrooms:edit-photos',
      'classrooms:edit-caregivers',
      'classrooms:edit-children',

      'children:visit',
      'children:create',
      'children:archive',
      'children:view-archive',
      'children:edit',

      'families:visit',
      'families:create',
      'families:archive',
      'families:view-archive',
      'families:edit',
      'families:edit-guardians',
      'families:edit-children',
      'families:view-notes',
      'families:view-adminNotes',

      'users:visit',
      'users:profile-edit',
      'users:create',
      'users:modify-permission',
      'users:create-admin',
      'users:create-siteAdmin',
      'users:create-caregiver',
      'users:create-guardian',
      'users:archive',
      'users:view-archive',
      'users:edit',
      'users:resendActivation',

      'communication:visit',
      'communications:read',

      'messages:visit',
      'messages:compose',
      'messages:sendToFamily',
    ],
  },
};

export function CanUser(props) {
  const check = (rules, role, action, data) => {
    const permissions = rules[role];

    if (!permissions) {
      return false;
    }

    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
      return true;
    }

    const dynamicPermissions = permissions.dynamic;
    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];

      if (!permissionCondition) {
        return false;
      }

      return permissionCondition(data);
    }

    return false;
  };

  const yesValue = typeof props.yes === 'function' ? props.yes() : null;
  const noValue = typeof props.no === 'function' ? props.no() : null;

  return check(userRules, props.role, props.perform, props.data) ? yesValue : noValue;
}

export function CheckPermission(role, action) {
  const permissions = userRules[role];

  if (!permissions) {
    return false;
  }

  const staticPermissions = permissions.static;
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  return false;
}
