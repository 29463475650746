import React, { useState } from 'react';
import Select from 'react-select';

import {
  FormGroup,
  Button,
  Form,
  Collapse,
  Label,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Yup from 'yup';

import Row from 'reactstrap/lib/Row';
import { Formik } from 'formik';
import useSelect from '../hooks/useSelect';
import childService from '../services/childService';
import Required from '../components/Required';
import FormModal from '../components/FormModal';
import RequiredField from '../components/RequiredField';
import RequiredSelect from '../components/RequiredSelect';
import RequiredCheck from '../components/RequiredCheck';

export default function AddChildFamilyForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    addChild,
    canCreate,
    addCreatedChild,
    familyChildren,
    classrooms,
  } = props;

  const options = familyChildren.map((c) => ({ value: c.childId, label: c.fullName + ' ' }));
  const pronounOptions = [
    { value: 'he-him', label: 'He/Him' },
    { value: 'she-her', label: 'She/Her' },
    { value: 'they-them', label: 'They/Them' },
  ];

  const initialValues = {
    identifier: '',
    firstName: '',
    lastName: '',
    pronouns: 'they-them',
    hasPhotoPermission: false,
    hasAllergies: false,
    locationId: {},
  };

  const { value: selectedChildren, setValue: setChildren, bind: bindChildren } = useSelect();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'First Name must not exceed 100 characters.')
      .required('The First Name field is Required.'),

    lastName: Yup.string()
      .max(100, 'Last Name must not exceed 100 characters.')
      .required('The Last Name field is Required.'),

    pronouns: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required('The Pronouns field is required.'),
    }),
  });

  const onFormSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    const obj = {
      identifier: values.identifier,
      firstName: values.firstName,
      lastName: values.lastName,
      pronouns: values.pronouns.value,
      hasAllergies: values.hasAllergies,
      hasPhotoPermission: values.hasPhotoPermission,
      classroomId: values.classroomId.value,
    };

    await childService
      .create(obj)
      .then((r) => {
        if (!r.hasError) {
        addCreatedChild(r.model);
        resetForm(initialValues);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ids = selectedChildren.map((c) => c.value);
    addChild(ids);

    // Clear the dropdown.
    setChildren([]);
  };

  return (
    <div>
      <a
        className="mb-3 d-block d-flex align-items-center collapsed addRow"
        href="#child-form"
        data-toggle="collapse"
        aria-expanded="false"
        aria-controls="child-form"
      >
        <span className="circle-dashed"><FontAwesomeIcon icon="plus" /></span>
        <h5 className="ml-3 mb-0 text-primary tf-truncate-text" style={{ maxWidth: 'calc(100% - 90px)' }}>Add Children</h5>
      </a>
      <Collapse id="child-form">
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-1">
            <div className="d-flex justify-content-between align-items-center">
              <Label className="mb-0">
                Choose Children&nbsp;
                <Required />
              </Label>
              {canCreate && (
                <FormModal
                  className="btn btn-link btn-sm pr-0"
                  buttonLabel="Add New"
                  isSubmitting={isSubmitting}
                  formName="userDetailForm"
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={onFormSubmit}
                  >
                    {(p) => (
                      <Form id="userDetailForm" onSubmit={p.handleSubmit}>
                        <Row><RequiredField formKey="identifier" labelName="Child Identifier" inputPlaceholder="Laserfiche ID" isRequired={false} /></Row>
                        {classrooms && (
                          <Row>
                            <RequiredSelect labelName="Classroom" name="classroomId" options={classrooms} />
                          </Row>
                        )}
                        <hr />
                        <Row><RequiredField formKey="firstName" labelName="First Name" inputPlaceholder="First Name" /></Row>
                        <Row><RequiredField formKey="lastName" labelName="Last Name" inputPlaceholder="Last Name" /></Row>
                        <Row><RequiredSelect labelName="Pronouns" name="pronouns" options={pronounOptions} /></Row>
                        <Row>
                          <RequiredCheck
                            id="hasAllergies"
                            name="hasAllergies"
                            label={'Does ' + p.values.firstName + ' have any allergies?'}
                          />
                        </Row>
                        <Row>
                          <RequiredCheck
                            id="hasPhotoPermission"
                            name="hasPhotoPermission"
                            label={'Check this box to give permission for pictures of ' + p.values.firstName + ' to be posted to the classroom.'}
                          />
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </FormModal>
              )}
            </div>
            <Select options={options} {...bindChildren} className="w-100" size="sm" closeMenuOnSelect={false} isMulti />
          </FormGroup>
          <Button className="btn-falcon-primary mr-2" size="sm" type="submit">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            Add to Family
          </Button>
        </Form>
        <hr className="border-dashed border-bottom-0" />
      </Collapse>
    </div>
  );
}
