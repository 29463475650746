import React, { useState } from 'react';
import CardHeaderless from '../../../../../components/CardHeaderless';
import AddEventForm from '../../../../../forms/AddEventForm';
import EditEventForm from '../../../../../forms/EditEventForm';

export default function ClassroomEvents(props) {
  const {
    canEdit,
    classroomId,
    addEvent,
    removeEvent,
    events,
  } = props;

  const [classroomEvents] = useState(events);

  return (
    <div>
      <CardHeaderless className="py-3"><h4 className="mb-0">Events</h4></CardHeaderless>
      {canEdit && <AddEventForm classroomId={classroomId} addEvent={addEvent} />}

      {classroomEvents.length > 0
        ? events.map((event) => (
          <EditEventForm
            event={event}
            key={event.eventId}
            canEdit={canEdit}
            removeEvent={removeEvent}
          />
        ))
        : (
          <CardHeaderless>
            <h5 className="text-center text-muted">No events have been added yet! Please check back soon.</h5>
          </CardHeaderless>
        )}
    </div>
  );
}
