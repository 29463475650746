import React from 'react';
import { Card, CardBody } from 'reactstrap';

export default function CardHeaderless(props) {
  const { className, children } = props;

  return (
    <Card className="my-3">
      <CardBody className={className}>
        {children}
      </CardBody>
    </Card>
  );
}
