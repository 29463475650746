import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UserContext } from '../context/userContext';
import { CheckPermission } from '../components/CanUser';
import organizationService from '../services/organizationService';
import RequiredField from '../components/RequiredField';

export default function EditOrganizationForm(props) {
  const history = useHistory();
  const { organization, setSubmitting } = props;

  const initialValues = { name: organization.name || '' };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, 'Name must not exceed 100 characters.')
      .required('The Name field is Required.'),
  });

  const userState = useContext(UserContext);
  const isDisabled = !CheckPermission(userState.user.role, 'organizations:edit', '');
  const onSubmit = async (values) => {
    setSubmitting(true);

    const newOrganization = { name: values.name };

    if (typeof organization.organizationId === 'undefined') {
      await organizationService
        .create(newOrganization)
        .then((r) => history.replace('/organizations/edit/' + r.model.organizationId))
        .finally(() => setSubmitting(false));
    } else {
      newOrganization.organizationId = organization.organizationId;

      await organizationService
        .update(newOrganization)
        .then(() => history.replace('/organizations/'))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(p) => (
        <Form id="organizationDetailsForm" onSubmit={p.handleSubmit}>
          <Row>
            <RequiredField formKey="name" labelName="Name" inputPlaceholder="Organization Name" isDisabled={isDisabled} />
          </Row>
        </Form>
      )}
    </Formik>
  );
}
