import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckPermission } from '../../../components/CanUser';
import CardHeaderless from '../../../components/CardHeaderless';
import ExtendedTable from '../../../components/ExtendedTable';
import PageHeader from '../../../components/PageHeader';
import Spinner from '../../../components/Spinner';

import { UserContext } from '../../../context/userContext';
import familyService from '../../../services/familyService';

export default function FamilyPage() {
  document.title = 'Families | Today\'s Family Fridge';

  const history = useHistory();
  const userState = useContext(UserContext);

  const [pageTitle, setPageTitle] = useState('Families');

  const [families, setFamilies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  const tableClickEvent = (e, r) => { history.push(`/families/edit/${r.familyId}`); };

  const tableColumns = [
    {
      text: 'Family',
      dataField: 'name',
      sort: true,
    },
    {
      text: 'Children',
      dataField: 'childCount',
      headerStyle: { width: '200px' },
      sort: true,
    },
    {
      text: 'Parent/Guardian(s)',
      dataField: 'guardianCount',
      headerStyle: { width: '200px' },
      sort: true,
    },
    {
      text: 'Classrooms',
      dataField: 'classroomCount',
      headerStyle: { width: '200px' },
      sort: true,
    },
  ];

  if (!CheckPermission(userState.user.role, 'families:visit') && typeof userState.user.role !== 'undefined') {
    history.push('/error/404');
  }

  const loadFamilies = async (showArchived) => {
    setPageTitle(showArchived ? 'Archived Families' : 'Families');

    await familyService
      .getFamilies(showArchived)
      .then((response) => {
        setFamilies(response.model);
      })
      .then(() => { setIsLoading(false); });
  };

  const handleArchived = () => {
    loadFamilies(!isShowingArchived);
    setIsShowingArchived(!isShowingArchived);
  };

  useEffect(() => {
    setIsLoading(true);
    loadFamilies(false);
  }, []);

  return (
    <main className="main" id="top">
      <PageHeader
        title={pageTitle}
        userRole={userState.user.role}
        addPermission="families:create"
        newButtonText="a Family"
        newButtonUrl="/families/edit"
        isShowingArchived={isShowingArchived}
      />

      {isLoading
        ? <Spinner />
        : (
          <CardHeaderless>
            <ExtendedTable
              isShowingArchived={isShowingArchived}
              archiveClicked={handleArchived}
              archivePermission="families:view-archive"
              userRole={userState.user.role}
              columns={tableColumns}
              data={families}
              tableClickEvent={tableClickEvent}
              keyField="familyId"
              defaultSort="name"
            />
          </CardHeaderless>
        )}
    </main>
  );
}
