import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import CardHeaderless from '../../components/CardHeaderless';
import VerifyPasswordForm from '../../forms/VerifyPasswordForm';

// Images
import Logo from '../../resources/images/tff-logo.png';

export default function VerifyPage(props) {
  document.title = 'Verify Password | Today\'s Family Fridge';

  const { location } = props;
  const params = queryString.parse(location.search);

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6 text-center">
          <Col xs="12" md="9">
            <img className="mb-4" src={Logo} alt="" width="300" />
            <CardHeaderless className="p-4 p-sm-5">
              <h4>
                Set your Password
                <br />
                <small className="text-muted fs--1">This page is only active for 24 hours after you receive your welcome email.</small>
              </h4>

              <Row>
                <Col xs={{ size: 12, order: 2 }} xl={{ size: 6, order: 1 }} className="text-left my-4">
                  <h6>Your password must meet the following requirements:</h6>
                  <ul>
                    <li>Must contain at least 6 characters.</li>
                    <li>Must contain at least one uppercase letter.</li>
                    <li>Must contain at least one lowercase letter.</li>
                    <li>Must contain at least one digit.</li>
                    <li>Must contain at least one special character (~!@#$%^&*())</li>
                  </ul>
                </Col>
                <Col xs={{ size: 12, order: 1 }} xl={{ size: 6, order: 2 }} className="my-4">
                  <VerifyPasswordForm email={params.e} token={params.t} />
                </Col>
              </Row>
              <div className="text-center">
                <Link className="fs--1 text-600" to="/login">
                  Back to Login
                  <span className="d-inline-block ml-1">&rarr;</span>
                </Link>
              </div>
            </CardHeaderless>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
