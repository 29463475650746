import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import CardHeaderless from '../../components/CardHeaderless';

// Images
import Shield from '../../resources/images/icons/shield.svg';
import Logo from '../../resources/images/tff-logo.png';

export default function LogoutPage() {
  document.title = 'Logout | Today\'s Family Fridge';

  useEffect(() => { sessionStorage.removeItem('jwt'); }, []);

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6 text-center">
          <Col sm="10" md="8" lg="6" xl="5">
            <img className="mb-4" src={Logo} alt="" width="300" />
            <CardHeaderless className="p-4 p-sm-5">
              <div className="text-center">
                <img className="d-block mx-auto mb-4" src={Shield} alt="shield" width="70" />
                <h4>See you again</h4>
                <p>You are now successfully signed out.</p>
                <div className="col-auto">
                  <Link className="btn btn-primary btn-sm mt-3" to="/login">Return to Log In</Link>
                </div>
              </div>
            </CardHeaderless>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
