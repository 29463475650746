import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Images
import Logo from '../../../resources/images/tff-logo.png';

export default function ErrorPage() {
  document.title = '500 | Today\'s Family Fridge';

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6 text-center">
          <Col sm="10" md="8" lg="6" xl="5">
            <a className="d-flex flex-center mb-4" href="/">
              <img className="mr-2" src={Logo} alt="" width="300" />
            </a>
            <Card>
              <CardBody className="p-4 p-sm-5">
                <div className="display-1 text-200 fs-error">500</div>
                <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Whoops, something went wrong!</p>
                <hr />
                <p>
                  Try refreshing the page, or going back and attempting the action again.
                  If this problem persists,
                  &nbsp;
                  <a href="mailto:info@exmaple.com">contact us</a>
                  .
                </p>
                <Link className="btn btn-primary btn-sm mt-3" to="/dashboard">
                  <FontAwesomeIcon icon="home" className="mr-2" />
                  Take me home
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
