import { useState } from 'react';

const useInput = (initialValue) => {
  const [value = initialValue || '', setValue] = useState();

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};

export default useInput;
