import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CanUser } from './CanUser';

export default function PageHeader(props) {
  const {
    title,
    newButtonDefault = 'Add',
    newButtonText = '',
    userRole,
    addPermission,
    newButtonUrl = '',
    subTitle = '',
    marginClass = 'mb-3',
    isShowingArchived = false,
  } = props;

  return (
    <Card className={(isShowingArchived ? 'card-gradient archived' : 'card-gradient') + ' ' + marginClass}>
      <CardBody className="d-flex flex-row align-items-center justify-content-between tf-site-header">
        <div>
          {subTitle.length === 0
            ? <h1 className="h3 mb-0 text-white text-truncate">{title}</h1>
            : (
              <h1 className="h3 mb-0 text-white text-truncate" style={{ lineHeight: '1.4rem' }}>
                {title}
                <br />
                <small className="fs-1">{subTitle}</small>
              </h1>
            )}
        </div>
        {newButtonText && newButtonText.length > 0 && !isShowingArchived
        && (
          <CanUser
            role={userRole}
            perform={addPermission}
            yes={() => (
              <span>
                <NavLink to={newButtonUrl} className="btn btn-falcon-primary btn-sm rounded-capsule px-2 py-1" type="button">
                  <FontAwesomeIcon icon="plus" transform="shrink-3" className="fa-align-left mr-1" />
                  {newButtonDefault}
                &nbsp;
                  <span className="d-none d-sm-inline-block">{newButtonText}</span>
                </NavLink>
              </span>
            )}
          />
        )}
      </CardBody>
    </Card>
  );
}
