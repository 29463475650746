import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Images
import Logo from '../../../resources/images/tff-logo.png';

export default function NotFoundPage() {
  document.title = '404 | Today\'s Family Fridge';

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6 text-center">
          <Col sm="10" md="8" lg="6" xl="5">
            <a className="d-flex flex-center mb-4" href="/">
              <img className="mr-2" src={Logo} alt="" width="300" />
            </a>
            <Card>
              <CardBody className="p-4 p-sm-5">
                <div className="display-1 text-200 fs-error">404</div>
                <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">The page you&#39;re looking for cannot be found.</p>
                <hr />
                <p>
                  Make sure the address is correct and that the page hasn&#39;t moved.
                  If you think this is a mistake,
                  &nbsp;
                  <a href="mailto:info@exmaple.com">&nbsp;contact us</a>
                  .
                </p>
                <Link className="btn btn-primary btn-sm mt-3" to="/">
                  <FontAwesomeIcon icon="home" className="mr-2" />
                  Take me home
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
