import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import ReactGA from 'react-ga';
import {
  Card, CardBody, Row, Col, Input
} from 'reactstrap';
import Moment from 'react-moment';
import ExtendedModal from '../../components/ExtendedModal';
import ClassroomToFamilyForm from '../../forms/ClassroomToFamilyForm';
import useInput from '../../hooks/useInput';
import { UserContext } from '../../context/userContext';
import conversationService from '../../services/conversationService';
import { CheckPermission } from '../../components/CanUser';
import FamilyToClassroomForm from '../../forms/FamilyToClassroomForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CanUser } from '../../components/CanUser';

/**
 * The React state is acting weird and not allowing us to set the conversation length so we have to use a global variable to keep track of the length. 
 * This variable is used to determine when the conversation length has changed and we should scroll to the end of the conversation view
 */
let conversationLength = 0;

export default function ConversationsPage() {
  document.title = "Compose Message | Today's Family Fridge";

  const userState = useContext(UserContext);
  const canSendToClassroom = CheckPermission(userState.user.role, 'messages:sendToClassroom');

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState();
  const [conversationMessages, setConversationMessages] = useState([]);

  const { value: newMessage, bind: bindNewMessage, setValue: setNewMessage } = useInput();

  const [intervalTimer, setIntervalTimer] = useState();

  const [selectedConversationId, setSelectedConversationId] = useState('');
  const [newMessagesLoaded, setNewMessagesLoaded] = useState(false); // When set to true, the page will scroll to the latest message in the conversation
  const [selectedClassroomId, setSelectedClassroomId] = useState();
  const [selectedFamilyId, setSelectedFamilyId] = useState();
  const [selectedChildId, setSelectedChildId] = useState();

  const [classroomHasError, setClassroomHasError] = useState(false);
  const [childHasError, setChildHasError] = useState(false);
  const [familiesHasError, setFamiliesHasError] = useState(false);

  /**
   * Scroll to the bottom of the converation whenever new messages have been loaded. 
   * This could be new messages to the selected conversation or a new conversation has been selected
   */
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
  
    useEffect(() => {
      if (newMessagesLoaded) {
        elementRef.current.scrollIntoView()
        setNewMessagesLoaded(false)
      }
    });
    return <div ref={elementRef} />;
  };

  function getConversationHistory(conversationId, forceUpdate) {
    conversationService
      .getConversationHistory(conversationId)
      .then((r) => {
        if (conversationMessages.length !== r.model.length || forceUpdate) {
          setConversationMessages(r.model);

          /**
           * If the conversation length has changed or the conversation is a new thread, indicate that new messages have been loaded to force a scroll to bottom
           * This should work using the if statement above with conversationMessages.length however something weird is going on with it always returning 0
           * so instead we are using a "global" variable to store the length
           */
          if (conversationLength != r.model.length || forceUpdate) {
            conversationLength = r.model.length
            setNewMessagesLoaded(true)
          }
          
        }
      });
  }

  function getConversations(searchTerm) {
    conversationService
      .getAll(searchTerm)
      .then((r) => {
        setConversations(r.model);
      });
  }

  function searchTermUpdated(e) {
    clearInterval(intervalTimer);

    var searchTerm = e.target.value
    getConversations(searchTerm);
    
    let x = setInterval(() => {
      getConversations(searchTerm);
    }, 5000);
    setIntervalTimer(x);
  }

  useEffect(() => {
    ReactGA.pageview('conversations');
    getConversations();

    let x = setInterval(() => {
      getConversations();
    }, 5000);
    setIntervalTimer(x);

    return () => {
      let id = window.setTimeout(() => {}, 0);
      while (id) {
        window.clearTimeout(id);
        id -= 1;
      }

      setIntervalTimer(null);
    };
  }, []);

  const conversationClicked = (conversationId) => {
    clearInterval(intervalTimer);
    setSelectedConversationId(conversationId);
    setIntervalTimer(setInterval(() => {
      getConversationHistory(conversationId, false);
    }, 5000));

    conversationService
      .getConversation(conversationId)
      .then((r) => {
        setSelectedConversation(r.model);
      });

    getConversationHistory(conversationId, true);
  };

  const confirmClicked = () => {
    setClassroomHasError(!selectedClassroomId);
    setFamiliesHasError(!selectedFamilyId);
    setChildHasError(!selectedChildId);

    if (selectedClassroomId && selectedFamilyId && selectedChildId) {
      conversationService
        .create(selectedClassroomId, selectedFamilyId, selectedChildId)
        .then((r) => {
          conversationClicked(r.model.conversationId);
        });
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    conversationService
      .sendMessage(selectedConversationId, newMessage)
      .then(() => {
        getConversationHistory(selectedConversationId, true);
        setNewMessage('');
      });
  };

  return (
    <UserContext.Consumer>
      {() => (
        <main className="main d-flex flex-column h-100" id="top">
          <Card className="card-gradient mb-3">
            <CardBody className="d-flex flex-row align-items-center justify-content-between tf-site-header">
              <h1 className="h3 mb-0 text-white d-flex">Messages</h1>
              <CanUser
                role={userState.user.role}
                perform="conversation:search"
                yes={() => (
                  <Input type="text" className="conversation-search" placeholder="Search" onChange={searchTermUpdated}/>
                )}
              />

              
              <ExtendedModal
                buttonLabel="New Conversation"
                buttonIcon="plus"
                buttonClassName="btn-falcon-primary rounded-pill ml-3 btn-sm"
                confirmButtonText="Start Conversation"
                hasError={
                      !selectedClassroomId
                      || !selectedFamilyId
                      || !selectedChildId
                    }
                onConfirm={confirmClicked}
              >
                {canSendToClassroom ? (
                  <FamilyToClassroomForm
                    setSelectedClassroomId={setSelectedClassroomId}
                    setSelectedFamilyId={setSelectedFamilyId}
                    setSelectedChildId={setSelectedChildId}
                    classroomError={classroomHasError}
                    childError={childHasError}
                    familiesError={familiesHasError}
                  />
                ) : (
                  <ClassroomToFamilyForm
                    setSelectedClassroomId={setSelectedClassroomId}
                    setSelectedFamilyId={setSelectedFamilyId}
                    setSelectedChildId={setSelectedChildId}
                    classroomError={classroomHasError}
                    childError={childHasError}
                    familiesError={familiesHasError}
                  />
                )}
              </ExtendedModal>
            </CardBody>
          </Card>

          <div className="card card-chat">
            <div className="card-body d-flex p-0 h-100">
              <div className="chat-sidebar" style={{left: '0px'}}>
                <div
                  className="contacts-list bg-white"
                  style={{ overflowY: 'scroll' }}
                >
                  <div
                    className="nav nav-tabs border-0 flex-column"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {conversations.length > 0
                    && conversations.map((c) => (
                      <div
                        key={c.conversationId}
                        onClick={() => conversationClicked(c.conversationId)}
                        onKeyUp={() => conversationClicked(c.conversationId)}
                        role="tab"
                        tabIndex="0"
                        className={'media chat-contact hover-actions-trigger w-100 ' + (selectedConversationId === c.conversationId ? 'active' : '')}
                      >
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src={c.child.photo}
                            alt={c.child.fullName}
                          />
                        </div>
                        <div className="media-body chat-contact-body ml-2 d-md-none d-lg-block">
                          <div className="d-flex justify-content-between">
                            <h6 className="mb-0 chat-contact-title">
                              {canSendToClassroom ? c.classroom.name : c.family.name}
                            </h6>
                          </div>
                          <div className="min-w-0">
                            <div className="chat-contact-content pr-3">
                              {canSendToClassroom ? c.child.fullName : c.child.fullName + ' (' + c.classroom.name + ')'}
                            </div>
                          </div>
                          <span className="message-time fs--2">
                            {c.lastMessageReceived && (
                              <Moment fromNow utc local>
                                {c.lastMessageReceived.sentOn}
                              </Moment>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="card-chat-content fs--1 position-relative">
                {selectedConversation
                  && (
                    <>
                      <div className="card-chat-pane">
                        <div className="chat-content-header">
                          <div className="row justify-content-between align-items-center">
                            <div className="col-12 d-flex align-items-center">
                              <button type="button" className="pl-0 pr-3 btn btn-link text-700 contacts-list-show">
                                <FontAwesomeIcon icon="chevron-left" />
                              </button>
                              <div className="min-w-0">
                                <h5 className="mb-0 text-truncate fs-0">
                                  {selectedConversation.family.name}
                                  &nbsp;&mdash;&nbsp;
                                  {selectedConversation.child.fullName}
                                </h5>
                                <div className="fs--2 text-400">
                                  {selectedConversation.participants}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="chat-content-body">
                          <div
                            style={{
                              overflowY: 'scroll',
                              height: '100%',
                              scrollbarWidth: 'none',
                            }}
                          >
                            {conversationMessages.length > 0 && conversationMessages.map((c) => (
                              <div key={c.conversationMessageId}>
                                <div className="media p-3">
                                  {c.sentByCurrentUser ? (
                                    <div className="media-body d-flex justify-content-end">
                                      <div className="w-100 w-xxl-75">
                                        <div className="text-400 fs--2 mb-1 text-right">
                                          <span className="font-weight-semi-bold mr-2">
                                            You
                                          </span>
                                        </div>
                                        <div className="hover-actions-trigger d-flex align-items-center justify-content-end">
                                          <div className="bg-primary text-white p-2 rounded-soft chat-message">
                                            {c.message}
                                          </div>
                                        </div>
                                        <div className="text-400 fs--2 text-right">
                                          <Moment format="MMM DD, YYYY hh:mm A" utc local>
                                            {c.sentOn}
                                          </Moment>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="avatar avatar-l mr-2 mt-2">
                                        <img
                                          className="rounded-circle"
                                          src={c.photo}
                                          alt={c.fromUserFullName}
                                        />
                                      </div>
                                      <div className="media-body">
                                        <div className="w-xxl-75">
                                          <div className="text-400 fs--2 mb-1">
                                            <span className="font-weight-semi-bold mr-2">
                                              {c.fromUserFullName}
                                            </span>
                                          </div>
                                          <div className="chat-message bg-200 p-2 rounded-soft">
                                            {c.message}
                                          </div>
                                          <div className="text-400 fs--2">
                                            <Moment format="MMM DD, YYYY hh:mm A" utc local>
                                              {c.sentOn}
                                            </Moment>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                            <AlwaysScrollToBottom />
                          </div>
                        </div>
                      </div>
                      <form className="chat-editor-area py-2 d-flex align-items-center" onSubmit={handleSendMessage}>
                        <input
                          {...bindNewMessage}
                          type="text"
                          placeholder="Type your message..."
                          className="border-0 outline-none shadow-none w-100 mr-6 px-2"
                        />
                        <button type="submit" className="btn btn-sm btn-send outline-none py-2">
                          Send
                        </button>
                      </form>
                    </>
                  )}
              </div>
            </div>
          </div>
        </main>
      )}
    </UserContext.Consumer>
  );
}
