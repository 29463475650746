import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Form } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';
import childService from '../services/childService';
import uploadService from '../services/uploadService';
import Avatar from '../components/Avatar';
import RequiredField from '../components/RequiredField';
import RequiredSelect from '../components/RequiredSelect';
import RequiredCheck from '../components/RequiredCheck';

export default function EditChildForm(props) {
  const history = useHistory();
  const {
    child, isNew, setSubmitting, setWasCreated, canEdit, canCreate,
  } = props;

  const pronounOptions = [
    { value: 'he-him', label: 'He/Him' },
    { value: 'she-her', label: 'She/Her' },
    { value: 'they-them', label: 'They/Them' },
  ];

  const initialValues = {
    firstName: child.firstName || '',
    lastName: child.lastName || '',
    pronouns: pronounOptions.filter((p) => p.value === child.pronouns)[0],
    identifier: child.identifier || '',
    hasPhotoPermission: child.hasPhotoPermission || false,
    hasAllergies: child.hasAllergies || false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'First Name must not exceed 100 characters.')
      .required('The First Name field is Required.'),

    lastName: Yup.string()
      .max(100, 'Last Name must not exceed 100 characters.')
      .required('The Last Name field is Required.'),

    pronouns: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required('The Pronouns field is required.'),
    }),

    identifier: Yup.string()
      .max(50, 'Child Identifier must not exceed 50 characters.'),
  });

  const [photo = child.photo || '', setPhoto] = useState();

  const onSubmit = async (values) => {
    setSubmitting(true);

    const obj = {
      firstName: values.firstName,
      lastName: values.lastName,
      pronouns: values.pronouns.value,
      identifier: values.identifier,
      hasAllergies: values.hasAllergies,
      hasPhotoPermission: values.hasPhotoPermission,
      photo,
      notes: child.notes,
    };

    if (typeof child.childId === 'undefined') {
      if (canCreate) {
        await childService
          .create(obj)
          .then(async (r) => {
            if (typeof props.family !== 'undefined') {
              history.goBack();
            } else {
              setWasCreated(true);
              history.push('/children/edit/' + r.model.childId);
            }
          })
          .finally(() => setSubmitting(false));
      }
    } else if (canEdit) {
      obj.childId = child.childId;

      await childService
        .update(obj)
        .then(async () => {

        })
        .finally(() => setSubmitting(false));
    }
  };

  const changePhoto = async (file, p) => {
    if (file) {
      await uploadService
        .uploadPhoto(file)
        .then((response) => { setPhoto(response.model.path); });
    } else {
      setPhoto(p.src);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      enableReinitialize
    >

      {(p) => (
        <Form id="editChildForm" onSubmit={p.handleSubmit}>
          {!isNew && <Avatar photo={`${photo}`} changeProfilePhoto={changePhoto} />}
          <Row>
            <RequiredField colSize="6" labelName="First Name" formKey="firstName" inputPlaceholder="First Name" isDisabled={!canEdit} />
            <RequiredField colSize="6" labelName="Last Name" formKey="lastName" inputPlaceholder="Last Name" isDisabled={!canEdit} />
          </Row>
          <Row>
            <RequiredSelect colSize="6" labelName="Pronouns" name="pronouns" options={pronounOptions} isDisabled={!canEdit} />
            <RequiredField colSize="6" labelName="Child Identifier" formKey="identifier" inputPlaceholder="Laserfiche ID" isDisabled={!canEdit} isRequired={false} />
          </Row>
          <Row>
            <RequiredCheck
              isDisabled={!canEdit}
              id="hasPhotoPermission"
              name="hasPhotoPermission"
              label={'Check this box to give permission for pictures of ' + p.values.firstName + ' to be posted to the classroom.'}
            />
          </Row>
          <Row>
            <RequiredCheck
              isDisabled={!canEdit}
              id="hasAllergies"
              name="hasAllergies"
              label={'Does ' + p.values.firstName + ' have any allergies?'}
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
}
