import React from 'react';
import Moment from 'react-moment';

export default function SingleMenu(props) {
  const { menu } = props;

  return (
    <div className="media btn-reveal-trigger mb-3">
      <div className="calendar">
        <span className="calendar-month">
          <Moment format="MMM" utc local>
            {menu.date}
          </Moment>
        </span>
        <span className="calendar-day">
          <Moment format="DD" utc local>
            {menu.date}
          </Moment>
        </span>
      </div>
      <div className="media-body position-relative pl-3">
        <h6 className="fs-0 mb-0">
          <Moment format="MMMM DD, YYYY" utc local>
            {menu.date}
          </Moment>
        </h6>
        <p className="mb-0 fs--1 mb-3">
          Created by
          {menu.createdBy}
        </p>

        <h6 className="fs-0 mb-0">Morning Snack</h6>
        <span dangerouslySetInnerHTML={{ __html: menu.morningSnack }} />
        <h6 className="mt-1 fs-0 mb-0">Lunch</h6>
        <span dangerouslySetInnerHTML={{ __html: menu.lunch }} />
        <h6 className="mt-1 fs-0 mb-0">Afternoon Snack</h6>
        <span dangerouslySetInnerHTML={{ __html: menu.afternoonSnack }} />
      </div>
    </div>
  );
}
