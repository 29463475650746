import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

export default function SpinnerButton(props) {
  const { isLoading, children, ...rest } = props;
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 400);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [isLoading, showLoader]);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0, margin: 'auto' });
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1, margin: 'auto' });

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...rest}
      disabled={showLoader}
      ref={ref}
      style={showLoader ? { width: width + 'px', height: height + 'px' } : {}}
    >
      {showLoader ? (
        <animated.div style={fadeOutProps}>
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>{children}</animated.div>
      )}
    </button>
  );
}
