import React from 'react';
import { Container } from 'reactstrap';

export default function Spinner() {
  return (
    <Container className="text-center mt-5">
      <div className="spinner-grow text-primary spinner-grow-lg" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Container>
  );
}
