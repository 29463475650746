import { authenticatedRequest, authenticatedRequestBasic } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Attendance`;

async function checkInAsync(classroomId, childId) {
  return authenticatedRequest('POST', URL + '/in/' + classroomId + '/' + childId);
}

async function checkOutAsync(classroomId, childId) {
  return authenticatedRequest('POST', URL + '/out/' + classroomId + '/' + childId);
}

async function getForClassroomAsync(classroomId) {
  return authenticatedRequest('GET', URL + '/classroom/' + classroomId);
}

async function getCheckedInForClassroomAsync(classroomId) {
  return authenticatedRequest('GET', URL + '/in/' + classroomId);
}

async function getForChildAsync(childId) {
  return authenticatedRequest('GET', URL + '/child/' + childId);
}

async function getForClassroomByDateAsync(classroomId, unixDate) {
  return authenticatedRequest('GET', URL + '/classroom/' + classroomId + '/' + unixDate);
}

async function exportAsync(obj) {
  return authenticatedRequestBasic('POST', URL + '/export', JSON.stringify(obj));
}

const attendanceService = {
  checkInAsync,
  checkOutAsync,
  getForClassroomAsync,
  getCheckedInForClassroomAsync,
  getForChildAsync,
  getForClassroomByDateAsync,
  exportAsync,
};

export default attendanceService;
