import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

export default function ListItemChildLarge(props) {
  const { child } = props;

  return (
    <Col className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
      <div className="p-3 h-100">
        <Link to={'/children/edit/' + child.childId}>
          <img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={child.photo} alt={child.fullName} width="100" />
        </Link>
        <h6 className="mb-1"><Link to={'/children/edit/' + child.childId}>{child.fullName}</Link></h6>
        <p className="fs--2 mb-1">
          <Link to={'/families/edit/' + child.childId} className="text-700">{child.familyName}</Link>
          <br />
          <Link to={'/locations/edit/' + child.childId} className="text-700">{child.locationName}</Link>
        </p>
      </div>
    </Col>
  );
}
