/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import ReactGA from 'react-ga';
import Moment from 'react-moment';
import { UserContext } from "../../context/userContext";
import communicationService from '../../services/communicationService';

export default function NotificationsPage() {
  document.title = "Communications | Today's Family Fridge";

  const [communications, setCommunications] = useState([]);

  function getCommunications() {
    communicationService
      .getCommunications()
      .then((r) => {
        setCommunications(r.model);
      });
  }

  useEffect(() => {
    ReactGA.pageview('communications');
    getCommunications();
  }, []);

  async function communicationRead(messageId) {
    const communication = communications.filter((c) => c.messageId === messageId)[0];
    if (communication.read === 1) return;

    setCommunications(communications.map((comm) => {
      if (comm.messageId === messageId) {
        // eslint-disable-next-line no-param-reassign
        comm.read = 1;
      }

      return comm;
    }));

    await communicationService
      .readCommunication(messageId)
      .then(() => {});
  }

  return (
    <UserContext.Consumer>
      {() => (
        <main className="main d-flex flex-column h-100" id="top">
          <Card className="card-gradient mb-3">
            <CardBody className="d-flex flex-row align-items-center justify-content-between tf-site-header">
              <h1 className="h3 mb-0 text-white d-flex">Communications</h1>
            </CardBody>
          </Card>

          <div>
            {communications.length > 0
                    && communications.map((c) => (
                      <div
                        className="media p-3"
                        key={c.messageId}
                        onMouseOver={() => communicationRead(c.messageId)}
                        onFocus={() => communicationRead(c.messageId)}
                        role="tab"
                        tabIndex="0"
                      >
                        <div className="avatar avatar-l mr-2 mt-2">
                          <img
                            className="rounded-circle"
                            src={c.fromUser.photo}
                            alt={c.fromUser.fullName}
                          />
                        </div>
                        <div className="media-body">
                          <div className="w-xxl-100">
                            <div className="text-400 fs--2 mb-1">
                              <span className="font-weight-semi-bold mr-2">
                                {c.fromUser.fullName}
                              </span>
                              <span className="text-danger">
                                {c.read === 0 ? 'NEW' : ''}
                              </span>
                            </div>
                            <div className="chat-message bg-200 p-2 rounded-soft" dangerouslySetInnerHTML={{ __html: c.content }} />
                            <div className="text-400 fs--2">
                              <Moment format="MMM DD, YYYY hh:mm A" utc local>
                                {c.sentOn}
                              </Moment>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
          </div>
        </main>
      )}
    </UserContext.Consumer>
  );
}
