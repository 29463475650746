import React from 'react';
import Moment from 'react-moment';

export default function SingleNote(props) {
  const { note } = props;

  return (
    <div className="media btn-reveal-trigger mb-3">
      <div className="media-body position-relative">
        <h6 className="fs-0 mb-0">{note.title}</h6>
        <p className="mb-0 fs--1">
          Posted by
          &nbsp;
          {note.createdBy}
        </p>
        <p className="text-700 mb-2 fs--1">
          <Moment format="YYYY/MM/DD hh:mm A" utc local>
            {note.createdOn}
          </Moment>
        </p>
        <span className="text-800" dangerouslySetInnerHTML={{ __html: note.content }} />
      </div>
    </div>
  );
}
