import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Required from './Required';

export default function CardForm(props) {
  const {
    type, canEdit, children, cardClassName = 'mb-3',
  } = props;

  return (
    <Card className={cardClassName}>
      <CardHeader className="border-bottom" style={{ lineHeight: '0.6rem' }}>
        <h1 className="h4 m-0">
          {type}
          &nbsp;
          Details
        </h1>
        {canEdit && (
        <span className="text-muted" style={{ fontSize: '14px' }}>
          Required fields are marked with an asterisk (
          <Required />
          ).
        </span>
        )}
      </CardHeader>
      <CardBody className="bg-light">
        {children}
      </CardBody>
    </Card>
  );
}
