import React, { useState } from 'react';
import {
  Form, FormGroup, Label, Input,
} from 'reactstrap';

import { toast } from 'react-toastify';

import noteService from '../services/noteService';
import CardCollapse from '../components/CardCollapse';
import Required from '../components/Required';
import AdvancedEditor from '../components/AdvancedEditor';
import useInput from '../hooks/useInput';

export default function AddNoteForm(props) {
  const { classroomId, addNote } = props;
  const { value: title, setValue: setTitle, bind: bindTitle } = useInput();

  const [titleHasError, setTitleHasError] = useState(false);
  const [contentHasError, setContentHasError] = useState(false);
  const [content, setContent] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();

    setTitleHasError(!title);
    setContentHasError(!content);

    if (title && content) {
      await noteService
        .addNote(
          {
            classroomId: parseInt(classroomId),
            title,
            content,
          },
        )
        .then((r) => {
          setTitle('');
          setContent('');
          addNote(r.model);
        });
    } else {
      if (!title) {
        toast.error('Please enter a title for your new note.');
      }

      if (!content) {
        toast.error('Please enter some content for your new note.');
      }
    }
  };

  const addFooter = (
    <div className="py-2 text-right">
      <button className="btn btn-primary btn-sm px-4 px-sm-5" type="submit">Post</button>
    </div>
  );

  return (
    <Form id="addNoteFrom" onSubmit={onSubmit}>
      <CardCollapse title="Add a new Note" footer={addFooter} showPlusButton>
        <FormGroup>
          <Label for="title">
            Title&nbsp;
            <Required />
          </Label>
          <Input name="title" type="text" {...bindTitle} invalid={titleHasError} />
        </FormGroup>
        <FormGroup>
          <Label for="title">
            Content&nbsp;
            <Required />
          </Label>
          <AdvancedEditor value={content} onChange={(v) => setContent(v)} placeholder="Enter the content of your note here..." className={contentHasError ? 'hasError' : ''} />
        </FormGroup>
      </CardCollapse>
    </Form>
  );
}
