import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ExtendedModal from './ExtendedModal';

export default function CardDeactivate(props) {
  const { label, handleArchiveClick, isArchived = false } = props;

  const modalTitle = (isArchived ? 'Restore' : 'Archive') + ' ' + label;
  const buttonClass = isArchived ? 'success' : 'danger';
  const largeButtonClass = (isArchived ? 'btn-falcon-success' : 'btn-falcon-danger') + ' w-100 rounded-pill';
  const confirmButtonText = isArchived ? 'Restore' : 'Archive';

  return (
    <Card className="mb-3">
      <CardHeader className="border-bottom"><h5 className="m-0">{isArchived ? 'Archived ' + label : 'Danger Zone'}</h5></CardHeader>
      <CardBody className="bg-light">
        {isArchived
          ? (
            <p>
              This
              {label}
              &nbsp;
              has been archived. You can restore it from here.
            </p>
          )
          : (
            <p>
              Once you archive this
              {' '}
              {label}
              , it will no longer be visible.
              {' '}
              <b>An Admin will need to restore access.</b>
            </p>
          )}

        <ExtendedModal
          buttonLabel={modalTitle}
          buttonIcon=""
          buttonClass={buttonClass}
          buttonClassName={largeButtonClass}
          confirmButtonText={confirmButtonText}
          confirmButtonClass={buttonClass}
          onConfirm={handleArchiveClick}
        >
          {isArchived
            ? (
              <p>
                Are you sure you wish to restore this
                {label}
                ?
              </p>
            )
            : (
              <div>
                <p>
                  Are you sure you wish to archive this
                  {' '}
                  {label}
                  ? You will not be able to undo this action.
                </p>
                <p>
                  <b>All associated data will be lost.</b>
                </p>
              </div>
            )}
        </ExtendedModal>
      </CardBody>
    </Card>
  );
}
