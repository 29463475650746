import React, { useState } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AdvancedEditor from "../components/AdvancedEditor";
import CardCustom from "../components/CardCustom";
import Required from "../components/Required";
import useInput from "../hooks/useInput";
import noteService from "../services/noteService";

export default function EditNoteForm(props) {
  const { note, canEdit, removeNote } = props;

  const [isEditing, setIsEditing] = useState(false);
  const { value: title, bind: bindTitle } = useInput(note.title);
  const [content, setContent] = useState(note.content);

  const [titleHasError, setTitleHasError] = useState(false);
  const [contentHasError, setContentHasError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setTitleHasError(!title);
    setContentHasError(!content);

    if (title && content) {
      note.title = title;
      note.content = content;

      await noteService.updateNote(note).then((r) => {
        if (!r.hasError) {
          setIsEditing(false);
        }
      });
    } else {
      if (!title) {
        toast.error("Please enter a title for your new note.");
      }

      if (!content) {
        toast.error("Please enter some content for your new note.");
      }
    }
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const deleteNote = async () => {
    const result = await noteService.deleteNote(note.noteId);
    if (!result.hasError) {
      // schedule removal of note for next event loop, doesn't seem to always fix the modal-open issue
      window.setTimeout(() => removeNote(note.noteId), 1);
    } else {
      toast.error("An error occurred while deleting the note.");
    }
    // Remove the modal-open class, in case the modal was removed before cleaning it up
    document.body.classList.remove("modal-open");
  };

  const header = (
    <div>
      {!isEditing && <h6 className="fs-0 mb-0">{title}</h6>}
      <p className="mb-0 fs--1">Created by {note.createdBy}</p>
      <p className="text-700 mb-0 fs--1">
        <Moment format="YYYY/MM/DD hh:mm A" utc local>
          {note.createdOn}
        </Moment>
      </p>
    </div>
  );

  const footer = (
    <div className="py-2 text-right">
      {isEditing ? (
        <div>
          <Button color="primary" size="sm" className="px-4 px-sm-3 mr-2" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
          <Button color="primary" size="sm" className="px-4 px-sm-3" type="submit">
            Save
          </Button>
        </div>
      ) : (
        <div>
          <Button color="danger" size="sm" className="px-4 px-sm-3 mr-2" onClick={() => setDeleteConfirmation(true)}>
            Delete
          </Button>
          <Button
            color="primary"
            size="sm"
            className="px-4 px-sm-3"
            onClick={(e) => {
              // if we don't preventDefault, it can submit the form once the form is opened. This only seems to happen if the delete button is added right before here.
              e.preventDefault();
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        </div>
      )}

      <Modal isOpen={deleteConfirmation} toggle={() => setDeleteConfirmation(false)} size="md">
        <ModalHeader toggle={() => setDeleteConfirmation(false)}> Note Deletion</ModalHeader>
        <ModalBody className="bg-light"> Are you sure you want to delete this note? </ModalBody>
        <ModalFooter className="py-2">
          <Button color="secondary" size="sm" className="mr-2" onClick={() => setDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => {
              // There's a race condition that seems to cause the modal to be removed before it can remove the modal-open class from the body.
              setDeleteConfirmation(false);
              // Schedule for next event loop, doesn't seem to matter anyways.
              window.setTimeout(deleteNote, 0);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  return (
    <Form onSubmit={onSubmit}>
      <CardCustom header={header} footer={footer} showFooter={canEdit}>
        {!isEditing ? (
          <div className="media btn-reveal-trigger">
            <div className="media-body position-relative">
              <span className="text-800" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        ) : (
          <div>
            <FormGroup>
              <Label for="title">
                Title&nbsp;
                <Required />
              </Label>
              <input className={"form-control fs-0 " + (titleHasError ? "hasError" : "")} {...bindTitle} />
            </FormGroup>
            <FormGroup>
              <Label for="title">
                Content&nbsp;
                <Required />
              </Label>
              <AdvancedEditor
                value={content}
                onChange={(v) => setContent(v)}
                placeholder="Enter the content of your note here..."
                className={contentHasError ? "hasError" : ""}
              />
            </FormGroup>
          </div>
        )}
      </CardCustom>
    </Form>
  );
}
