import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../../context/userContext';
import { CheckPermission } from '../../../components/CanUser';

import childService from '../../../services/childService';
import PageHeader from '../../../components/PageHeader';
import Spinner from '../../../components/Spinner';
import CardHeaderless from '../../../components/CardHeaderless';
import ExtendedTable from '../../../components/ExtendedTable';

export default function ChildrenPage() {
  document.title = 'Children | Today\'s Family Fridge';

  const history = useHistory();
  const userState = useContext(UserContext);
  const [pageTitle, setPageTitle] = useState('Children');

  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  const tableClickEvent = (_e, r) => { history.push('/children/edit/' + r.childId); };

  function childIconFormatter(cell, row) {
    return (
      <div className="media d-flex align-items-center">
        <div className="avatar avatar-xl mr-2">
          <img className="rounded-circle" src={row.photo} alt="" />
        </div>
        <div className="media-body">
          <h5 className="mb-0 fs--1">{row.fullName}</h5>
        </div>
      </div>
    );
  }

  const tableColumns = [
    {
      text: 'Name',
      dataField: 'fullName',
      formatter: childIconFormatter,
      sort: true,
    },
    { text: 'Family', dataField: 'familyName', sort: true },
    {
      text: 'Classroom(s)',
      dataField: 'classroomNames',
      headerStyle: { width: '250px' },
      sort: true,
    },
    {
      text: 'Location(s)',
      dataField: 'locationName',
      headerStyle: { width: '250px' },
      sort: true,
    },
  ];

  if (!CheckPermission(userState.user.role, 'children:visit') && typeof userState.user.role !== 'undefined') {
    history.push('/error/404');
  }

  const loadChildren = async () => {
    setPageTitle(isShowingArchived ? 'Archived Children' : 'Children');

    await childService
      .availableChildren(isShowingArchived)
      .then((r) => setChildren(r.model))
      .then(() => setIsLoading(false));
  };

  useEffect(() => { loadChildren(); }, [isShowingArchived]);

  return (
    <main className="main" id="top">
      <PageHeader
        title={pageTitle}
        userRole={userState.user.role}
        isShowingArchived={isShowingArchived}
      />

      {isLoading
        ? <Spinner />
        : (
          <CardHeaderless>
            <ExtendedTable
              isShowingArchived={isShowingArchived}
              archiveClicked={() => setIsShowingArchived(!isShowingArchived)}
              archivePermission="children:view-archive"
              userRole={userState.user.role}
              columns={tableColumns}
              data={children}
              tableClickEvent={tableClickEvent}
              keyField="childId"
              defaultSort="fullName"
            />
          </CardHeaderless>
        )}
    </main>
  );
}
