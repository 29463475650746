import { useState } from 'react';

const useCheck = (initialValue) => {
  const [checked = initialValue || false, setChecked] = useState();

  return {
    checked,
    setChecked,
    bind: {
      checked,
      onChange: (event) => {
        setChecked(event.target.checked);
      },
    },
  };
};

export default useCheck;
