/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  FormGroup, Label, Row, Col, CustomInput,
} from 'reactstrap';

import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css';

import gender1 from '../resources/images/presets/male.jpg';
import gender2 from '../resources/images/presets/female.jpg';
import gender3 from '../resources/images/presets/other.jpg';

import ExtendedModal from './ExtendedModal';

const genderList = [gender1, gender2, gender3];

export default function Avatar(props) {
  const { photo, changeProfilePhoto } = props;

  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [previewImage, setPreviewImage] = useState();

  const fileChanged = (e) => {
    const reader = new FileReader();
    const f = e.target.files[0];

    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };

    reader.readAsDataURL(f);

    setFile(f);
  };

  const setProfileImage = () => {
    changeProfilePhoto(file, image);
  };

  return (
    <div className="avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle">
      <div className="h-100 w-100 rounded-circle overflow-hidden position-relative">
        <img src={photo} alt="" width="200" />
        <ExtendedModal
          buttonLabel="Upload"
          buttonIcon="camera"
          modalSize="lg"
          buttonSizeClass="sm profile-upload-button"
          onConfirm={() => { setProfileImage(); }}
        >
          <FormGroup>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="photoUpload">Upload a Photo</Label>
                  <CustomInput
                    type="file"
                    id="photoUpload"
                    name="photoFile"
                    onChange={fileChanged}
                  />
                </FormGroup>
                {previewImage && <img src={previewImage} className="w-100" alt="preview" />}
              </Col>
              <Col lg="6">
                <Label for="fileBrowser">OR Pick a Preset</Label>
                <ImagePicker
                  images={genderList.map((img, i) => ({ src: img, value: i }))}
                  onPick={(img) => { setImage(img); }}
                />
              </Col>
            </Row>
          </FormGroup>
        </ExtendedModal>
      </div>
    </div>
  );
}
