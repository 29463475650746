import React from 'react';
import InputMask from 'react-input-mask';
import {
  Label, FormGroup, Input, Col,
} from 'reactstrap';
import DatePicker from 'react-flatpickr';

import { Field, useFormikContext } from 'formik';
import Required from './Required';

export default function RequiredField(props) {
  const {
    type = 'text',
    labelName,
    inputPlaceholder,
    formKey,
    showLabel = true,
    colSize = '12',
    inputMask = '',
    rows = '3',
    isRequired = true,
    isDisabled = false,
    isDate = false,
  } = props;

  const { setFieldValue, setFieldTouched } = useFormikContext();

  function formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  return (
    <Col lg={colSize}>
      <FormGroup>
        <Field type={type} placeholder={inputPlaceholder} name={formKey} disabled>
          {({ field, meta }) => (
            <div>
              {showLabel && (
                <Label>
                    {labelName}
                    &nbsp;
                    {isRequired && !isDisabled && <Required />}
                </Label>
              )}

              {inputMask.length > 0 && !isDate && (
                <InputMask
                  disabled={isDisabled}
                  {...field}
                  mask={inputMask}
                  placeholder={inputPlaceholder}
                  type={type}
                  className={meta.error && meta.touched ? 'form-control is-invalid' : 'form-control'}
                />
              )}

              {inputMask.length === 0 && !isDate && (
                <Input
                  disabled={isDisabled}
                  rows={rows}
                  type={type}
                  placeholder={inputPlaceholder}
                  className={meta.error && meta.touched ? 'is-invalid' : ''}
                  {...field}
                />
              )}

              {isDate && (
                <DatePicker
                  {...field}
                  className={meta.error && meta.touched ? 'form-control is-invalid' : 'form-control'}
                  onChange={(d) => setFieldValue(formKey, formatDate(d))}
                  onClose={() => setFieldTouched(formKey)}
                  placeholder={inputPlaceholder}
                />
              )}

              {meta.touched && meta.error && (
                <div className="invalid-feedback text-left">{meta.error}</div>
              )}
            </div>
          )}
        </Field>
      </FormGroup>
    </Col>
  );
}
