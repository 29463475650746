import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

export default function ListItemClassroomLarge(props) {
  const { classroom } = props;

  return (
    <Col className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
      <div className="p-3 h-100 text-center">
        <Link to={'/classrooms/edit/' + classroom.classroomId}>
          <div className="avatar avatar-55xl">
            <img className="rounded-circle" src={classroom.photo} alt={classroom.name} />
          </div>
        </Link>
        <h6 className="mb-1"><Link to={'/classrooms/edit/' + classroom.classroomId}>{classroom.name}</Link></h6>
        <p className="fs--2 mb-1 text-700">
          {classroom.numberOfCaregivers}
          &nbsp;
          {classroom.numberOfCaregivers === 1 ? 'caregiver' : 'caregivers'}
          <br />
          {classroom.numberOfChildren}
          &nbsp;
          {classroom.numberOfChildren === 1 ? 'child' : 'children'}
            &nbsp;•&nbsp;
          {classroom.numberOfParents}
          &nbsp;
          {classroom.numberOfParents === 1 ? 'parent' : 'parents'}
        </p>
      </div>
    </Col>
  );
}
