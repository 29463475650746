import { authenticatedRequest } from '../helpers/authenticatedRequest';

const URL = `${process.env.REACT_APP_API_URL}/Conversation`;

async function create(classroomId, familyId, childId) {
  return authenticatedRequest(
    'POST',
    URL,
    JSON.stringify({
      ClassroomId: classroomId,
      FamilyId: familyId,
      ChildId: childId,
    }),
  );
}

async function getAll(searchTerm) {
  if (searchTerm) {
    return authenticatedRequest('GET', URL + `?searchTerm=${searchTerm}`);
  }
  return authenticatedRequest('GET', URL);
}

async function getConversation(id) {
  return authenticatedRequest('GET', URL + '/' + id);
}

async function sendMessage(id, message) {
  return authenticatedRequest(
    'POST',
    URL + '/' + id,
    JSON.stringify({
      ConversationId: id,
      Message: message,
    }),
  );
}

async function getConversationHistory(id) {
  return authenticatedRequest('GET', URL + '/History/' + id);
}

const conversationService = {
  create,
  getAll,
  getConversation,
  sendMessage,
  getConversationHistory,
};

export default conversationService;
