import React from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';

export default function ListItemSmall(props) {
  const {
    linkTo, imgSrc, title, children,
  } = props;

  return (
    <Media className="align-items-center mb-2">
      <div className="avatar avatar-3xl">
        {imgSrc
          ? <img className="rounded-circle" src={imgSrc} alt={title} />
          : <div className="rounded-circle badge-soft-secondary avatar-name"><span className="fs-2">{title[0].toUpperCase()}</span></div>}
      </div>
      <div className="media-body ml-2">
        <h6 className="mb-0">
          <Link to={linkTo} className="fs-0">{title}</Link>
          <br />
          {children}
        </h6>
      </div>
    </Media>
  );
}
