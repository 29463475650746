import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container, Row, Col, Alert,
} from 'reactstrap';
import CardHeaderless from '../../components/CardHeaderless';

import { UserContext } from '../../context/userContext';
import LoginForm from '../../forms/LoginForm';

// Images
import Logo from '../../resources/images/tff-logo.png';

export default function LoginPage(props) {
  document.title = 'Login | Today\'s Family Fridge';

  const history = useHistory();

  const userContext = React.useContext(UserContext);
  const [error, setError] = useState('');

  useEffect(() => { sessionStorage.removeItem('jwt'); });

  const handleAuthorized = (user) => {
    sessionStorage.setItem('jwt', JSON.stringify(user.token));

    const { from } = props.location.state || { from: { pathname: '/dashboard' } };
    history.push(from);
    userContext.setUser({});
  };

  return (
    <main className="main" id="top">
      <Container>
        <Row className="flex-center min-vh-100 py-6">
          <Col sm="10" md="8" lg="6" xl="5">
            <a className="d-flex flex-center mb-4" href="/">
              <img className="mr-2" src={Logo} alt="" width="300" />
            </a>
            {error && (
              <Alert color="danger">
                <h5 className="alert-heading font-weight-semi-bold">Log in Failed</h5>
                {error}
              </Alert>
            )}
            <CardHeaderless className="p-4 p-sm-5">
              <Row className="text-left justify-content-between align-items-center mb-2">
                <div className="col-auto">
                  <h4 className="mb-0">Log in</h4>
                </div>
              </Row>
              <LoginForm setError={setError} onAuthorized={handleAuthorized} />
            </CardHeaderless>
          </Col>
        </Row>
      </Container>
    </main>
  );
}
