import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

export default function ListItemFamilyLarge(props) {
  const { family } = props;

  return (
    <Col className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
      <div className="p-3 h-100 text-center">
        <Link to={'/families/edit/' + family.familyId}>
          <div className="avatar avatar-55xl">
            <div className="rounded-circle badge-soft-secondary avatar-name"><span className="fs-2">{family.name[0].toUpperCase()}</span></div>
          </div>
        </Link>
        <h6 className="mb-1"><Link to={'/families/edit/' + family.familyId}>{family.name}</Link></h6>
        <p className="fs--2 mb-1 text-700">
          {family.childCount}
          {family.childCount === 1 ? 'child' : 'children'}
        </p>
      </div>
    </Col>
  );
}
